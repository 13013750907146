import Vue from "vue";
import VueRouter from "vue-router";
import ICS from "vue-ics";
import VueGtag from 'vue-gtag'
import store from "./store";
import vuetify from "./vuetify";

/* eslint-disable no-unused-vars */

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(ICS);

import axios from "axios";

import application from "./application.vue";
import notFound from "./component/notFound";
import unauthorized from "./component/unauthorized";
import navigation from "./component/navigation";

import index from "./component/index";

import video from "./component/video";
import essential from "./component/essential";

import brand from "./component/brand";
import brandDetail from "./component/brandDetail";
import brandProduct from "./component/brandProduct";

import product from "./component/product";
import productDetail from "./component/productDetail";

import offer from "./component/offer";
import offerDetail from "./component/offerDetail";

import event from "./component/event";

import news from "./component/news";
import newsDetail from "./component/newsDetail";

import ambassador from "./component/ambassador";
import ambassadorDetail from "./component/ambassadorDetail";

import notification from "./component/notification";
import notificationDetail from "./component/notificationDetail";

import search from "./component/search";
import signin from "./component/signin";
import profile from "./component/profile";
import favourite from "./component/favourite";
import cart from "./component/cart";
import contact from "./component/contact";
import help from "./component/help";

const router = new VueRouter({
	routes: [
		// signin
		{ path: "/signin", component: signin, props: true },
		{ path: "/signin/:text", component: signin, props: true },
		// common
		{
			path: "",
			component: navigation,
			props: true,
			meta: {
				requiresAuth: true
			},
			children: [
				{ path: "/", component: index, props: true },

				{ path: "/essential", component: essential, props: true, meta: { breadcrumb: [{ name: "Bar Essentials & POS System" }] } },
				{ path: "/video", component: video, props: true, meta: { breadcrumb: [{ name: "Videos" }] } },

				{ path: "/profile", component: profile, props: true, meta: { breadcrumb: [{ name: "Your Profile" }] } },
				{ path: "/favourite", component: favourite, props: true, meta: { breadcrumb: [{ name: "Favourites" }] } },
				{ path: "/cart", component: cart, props: true, meta: { breadcrumb: [{ name: "Cart" }] } },
				{ path: "/contact", component: contact, props: true, meta: { breadcrumb: [{ name: "Contact" }] } },
				{ path: "/help", component: help, props: true, meta: { breadcrumb: [{ name: "Help" }] } },

				{ path: "/search", component: search, props: true, meta: { breadcrumb: [{ name: "Search" }] } },
				{ path: "/search/:keyword", component: search, props: true, meta: { breadcrumb: [{ name: "Search Details" }] } },

				{ path: "/ambassador", component: ambassador, props: true, meta: { breadcrumb: [{ name: "Brand Ambassadors" }] } },
				{ path: "/ambassador/detail/:id", component: ambassadorDetail, props: true, meta: { breadcrumb: [{ name: "Brand Ambassadors", link: "/ambassador" }, { name: "Details" }] } },

				{ path: "/offer", component: offer, props: true, meta: { breadcrumb: [{ name: "Member Offers" }] } },
				{ path: "/offer/detail/:id", component: offerDetail, props: true, meta: { breadcrumb: [{ name: "Member Offers", link: "/offer" }, { name: "Details" }] } },

				{ path: "/news", component: news, props: true, meta: { breadcrumb: [{ name: "News" }] } },
				{ path: "/news/detail/:id", component: newsDetail, props: true, meta: { breadcrumb: [{ name: "News", link: "/news" }, { name: "Details" }] } }, // this needs to be above /:type/detail/:id

				{ path: "/notification", component: notification, props: true, meta: { breadcrumb: [{ name: "Notifications" }] } },
				{ path: "/notification/detail/:id", component: notificationDetail, props: true, meta: { breadcrumb: [{ name: "Notifications", link: "/notification" }, { name: "Notification Details" }] } },

				{ path: "/brand", component: brand, props: true, meta: { breadcrumb: [{ name: "Brands" }] } },
				{ path: "/brand/detail/:id", component: brandDetail, props: true, meta: { breadcrumb: [{ name: "Brands", link: "/brand" }, { name: "Details" }] } },
				{ path: "/brand/:brandId/product", component: brandProduct, props: true, meta: { breadcrumb: [{ name: "Brands", link: "/brand" }, { name: "Products" }] } },

				{ path: "/event", component: event, props: true, meta: { breadcrumb: [{ name: "Industry Events" }] } },

				{ path: "/:type/list", component: product, props: true },
				{ path: "/:type/detail/:id", component: productDetail, props: true }
			]
		},
		{ path: "/unauthorized", component: unauthorized, props: true },
		{ path: "/notfound", component: notFound, props: true },
		{ path: "*", redirect: "/notfound" }
	]
});

router.beforeEach((to, from, next) => {
	// authenticated, if go to login page then go to dashboard
	if(store.getters.isAuthenticated && to.matched.some(record => record.path.includes("/signin"))) {
        next({
			path: '/',
			params: { nextUrl: to.fullPath }
		})
    }
	// check if authenticated on the page that requires auth, if not, redirect to login page
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.isAuthenticated) {
            next({
                path: '/signin',
                params: { nextUrl: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        next() 
    }
});


var gtagId = 'G-HTRS46YHKF'
var sentryKey = "https://a6c8c2618b234085b13b67cbe95cb6ce@sentry.io/1866396";
var sendHitTask = false;
if (window.location.origin.includes("alm.pontoonx.io") || window.location.origin.includes("agora.pontoonx.io")) {
	sentryKey = "https://2c2099825be74022b3c11e3b1e979979@sentry.io/1866395";
	sendHitTask = true;
	gtagId = 'G-15PVVFBEHJ'
}

Vue.use(VueGtag, {
	config: { id: gtagId }
  })

axios.defaults.baseURL = "";

if (window.location.origin.includes("localhost")) {
	axios.defaults.withCredentials = true;
	axios.defaults.baseURL = "http://localhost:8900/agora";
}

Vue.prototype.$window = window;

var monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

Vue.filter("formatDate", function(value) {
	if (value != null) {
		return value.date.day + " " + monthList[value.date.month - 1] + " " + value.date.year;
	} else {
		return "";
	}
});

new Vue({
	router,
	store,
	vuetify,
	render: display => display(application),
	data: function() {
		return {
			loader: { count: 0, stroke: 7, diameter: 50, value: false },
			monthList: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
		};
	},
	created: function() {
		axios.interceptors.request.use(
			request => {
				this.load();
				return request;
			},
			error => {
				this.unload();

				let notification = { title: "Unknown error", content: "" };
				if (error.response) {
					if (error.response.status == 400) {
						notification.title = "Bad request";
					} else if (error.response.status == 500) {
						notification.title = "Server error";
					} else if (error.response.status == 401) {
						notification.title = "Unauthorized";
					} else if (error.response.status == 403) {
						notification.title = "Forbidden";
					} else if (error.response.status == 404) {
						notification.title = "Not found";
					} else {
						notification.title = "Notification (" + error.response.status + ")";
					}
					notification.content = error.response.data;
				}
				store.commit("showNotification", notification);

				return Promise.reject(error);
			}
		);

		axios.interceptors.response.use(
			response => {
				this.unload();
				return response;
			},
			error => {
				this.unload();

				let notification = { title: "Unknown error", content: "" };
				if (error.response) {
					if (error.response.status == 400) {
						notification.title = "Bad request";
					} else if (error.response.status == 500) {
						notification.title = "Server error";
					} else if (error.response.status == 401) {
						notification.title = "Unauthorized";
					} else if (error.response.status == 403) {
						notification.title = "Forbidden";
					} else if (error.response.status == 404) {
						notification.title = "Not found";
					} else {
						notification.title = "Notification (" + error.response.status + ")";
					}
					notification.content = error.response.data;
				}
				store.commit("showNotification", notification);

				return Promise.reject(error);
			}
		);
		store.commit("getTotalCart");
		store.commit("getTotalUnseenMessages");
	},
	methods: {
		load() {
			this.loader.count++;
			this.loader.value = true;
		},
		unload() {
			this.loader.count--;
			if (this.loader.count < 0) {
				this.loader.count = 0;
			}
			this.loader.value = this.loader.count > 0;
		},
		logSuccess: function(o) {
			// console.log('>> Success: ' + JSON.stringify(o.data));
		},
		logError: function(o) {
			// console.log('>> Error: ' + JSON.stringify(o.response.data));
			// Sentry.captureMessage(JSON.stringify(o.response.data));
		},
		clone(object) {
			return JSON.parse(JSON.stringify(object));
		},
		ellipsify(text, limit) {
			if (text && text.length > limit) {
				return text.substring(0, limit) + "...";
			}
			return text;
		},
		textify(text, before, after) {
			if (text && text.trim().length > 0) {
				return (before ? before : "") + text + (after ? after : "");
			}
			return "";
		},
		enum(text) {
			if (text) {
				return text.replace(/_/g, " ");
			}
			return text;
		},
		downloadAsset(name) {
			location.href = axios.defaults.baseURL + "/asset/" + name + "?download&time=" + new Date();
		},
		isImage(text) {
			if (text) {
				let q = text.toLowerCase();
				return q.endsWith(".jpg") || q.endsWith(".jpeg") || q.endsWith(".png") || q.endsWith(".gif");
			}
			return false;
		},
		isVideo(text) {
			if (text) {
				let q = text.toLowerCase();
				return q.endsWith(".mp4");
			}
			return false;
		},
		isBlank(object) {
			// check object/string has children
			for (var propName in object) {
				return false;
			}
			// no children and object is numeric/boolean
			if (typeof (object) === 'number' || typeof (object) === 'boolean') {
				return false;
			}
			// blank object: [], {}, "", null, undefined
			return true;
		},
		asset(text, resolution) {
			if (text) {
				if (text.toLowerCase().startsWith("http")) {
					return text;
				} else if (resolution != null) {
					return axios.defaults.baseURL + "/asset/" + resolution + "/" + text;
				} else {
					return axios.defaults.baseURL + "/asset/" + text;
				}
			}
			return null;
		},
		formatDateTime(instant) {
			if (instant != null) {
				return instant.getDate() + " " + this.monthList[instant.getMonth()] + " " + instant.getFullYear() + " " + (instant.getHours() < 10 ? "0" : "") + instant.getHours() + ":" + (instant.getMinutes() < 10 ? "0" : "") + instant.getMinutes();
			} else {
				return "";
			}
		},
		typeOf(text) {
			if (text == "newrelease") {
				return "NewRelease";
			} else if (text == "event") {
				return "Event";
			} else if (text == "brandeducation") {
				return "BrandEducation";
			} else if (text == "cocktailmaking") {
				return "CocktailMaking";
			} else if (text == "pointofsale") {
				return "PointOfSale";
			} else if (text == "trainingpartner") {
				return "TrainingPartner";
			} else if (text == "signage") {
				return "Signage";
			} else if (text == "baressential") {
				return "BarEssential";
			} else if (text == "menusolution") {
				return "MenuSolution";
			} else if (text == "tasting") {
				return "Tasting";
			}
		},
		breadcrumb(text, isDetail) {
			let breadcrumbList = [];
			if (text == "newrelease") {
				breadcrumbList = [{ name: "New Releases", link: isDetail ? "/newrelease/list" : "" }];
			} else if (text == "brandeducation") {
				breadcrumbList = [
					{ name: "Videos", link: "/video" },
					{ name: "Brand Education", link: isDetail ? "/brandeducation/list" : "" }
				];
			} else if (text == "cocktailmaking") {
				breadcrumbList = [
					{ name: "Videos", link: "/video" },
					{ name: "Cocktail Making", link: isDetail ? "/cocktailmaking/list" : "" }
				];
			} else if (text == "pointofsale") {
				breadcrumbList = [
					{ name: "Videos", link: "/video" },
					{ name: "Point Of Sale", link: isDetail ? "/pointofsale/list" : "" }
				];
			} else if (text == "trainingpartner") {
				breadcrumbList = [
					{ name: "Videos", link: "/video" },
					{ name: "Training Partners", link: isDetail ? "/trainingpartner/list" : "" }
				];
			} else if (text == "signage") {
				breadcrumbList = [
					{ name: "Bar Essentials & POS System", link: "/essential" },
					{ name: "Static POS", link: isDetail ? "/signage/list" : "" }
				];
			} else if (text == "baressential") {
				breadcrumbList = [
					{ name: "Bar Essentials & POS System", link: "/essential" },
					{ name: "Bar Essentials", link: isDetail ? "/baressential/list" : "" }
				];
			} else if (text == "menusolution") {
				breadcrumbList = [
					{ name: "Bar Essentials & POS System", link: "/essential" },
					{ name: "Menu Systems", link: isDetail ? "/menusolution/list" : "" }
				];
			} else if (text == "tasting") {
				breadcrumbList = [
					{ name: "Bar Essentials & POS System", link: "/essential" },
					{ name: "Tasting Notes", link: isDetail ? "/tasting/list" : "" }
				];
			} else if (text == "event") {
				breadcrumbList = [{ name: "Industry Events", link: isDetail ? "/event" : "" }];
			} else if (text == "ambassador") {
				breadcrumbList = [{ name: "Brand Ambassadors", link: isDetail ? "/ambassador" : "" }];
			} else if (text == "product") {
				breadcrumbList = [{ name: "Brands", link: isDetail ? "/brand" : "" }, { name: "Products" }];
			}
			return breadcrumbList;
		},
		nameOf(text) {
			if (text == "Training Partner") {
				return "Training Partners";
			} else if (text == "New Release") {
				return "New Releases";
			} else if (text == "Signage") {
				return "Static POS";
			} else if (text == "Bar Essential") {
				return "Bar Essentials";
			} else if (text == "Menu Solution") {
				return "Menu Systems";
			} else if (text == "Tasting") {
				return "Tasting Notes";
			}
			return text;
		},
		background() {
			return axios.defaults.baseURL + '/resource/asset/icon/background.jpg';
		}
	}
}).$mount("#application");
