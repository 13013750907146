<template>
	<div>
		<agora-breadcrumb></agora-breadcrumb>

		<v-container>
			<h1 class="text-h5 text-sm-h3 my-0" style="font-family: JosefinSans !important;">MEMBER OFFERS</h1>
			<br />
			<!-- MOBILE -->
			<v-container ma-0 pa-0 fluid class="hidden-md-and-up">
				<v-row no-gutters>
					<v-col class="wrap" style="position: relative;" cols="12" sm="6" v-for="(offer, index) in offers" :key="offer.id" fill-height>
						<v-img :height="heightOfferCard" :src="$root.asset(offer.imageName, '')"  class="image" style='width: 100%; will-change: transform; image-rendering: -webkit-optimize-contrast;' />
						<div @mouseenter="showText(index)" @mouseleave="hideText(index)">
							<div class="image-description ml-4" :class="offer.name && offer.name.length > 25 ? 'mt-n10':'mt-n2'" style="position: absolute; width: 85%; height: 100%;" :style="{top: 0,}">
								<v-container fluid ma-0 pa-0 fill-height>
									<v-row no-gutters>
										<v-col class="d-flex align-end">
											<div class="pt-12 mt-12">
												<div :class="offer.name && offer.name.length > 25 ? 'text-h6 text-sm-h4 mb-2 mx-4 pb-2' : 'text-h5 text-sm-h3 mb-2 mx-4 pb-2'" style="color: white; border-bottom: 3px solid white" v-html="offer.name && offer.name.length > 50 ? offer.name.slice(0, 50) + '...' : offer.name"></div>
												<div class="text-body-1 text-sm-h6 text-sm-body-1 text-md-h6 ml-4" style="font-weight: normal; color: white; letter-spacing: 0px; line-spacing: 33">{{ offer.summary && offer.summary.length > 60 ? offer.summary.slice(0, 60) + '...' : offer.summary  }}</div>
												<div class="pt-2"></div>
												<v-btn @click="navigateToDetail(offer.id)" plain color="white" class="orange-hover text-body-2 text-sm-body1 text-md-body-1 text-capitalize mx-4" style="border: 1px solid white; border-radius:0px!important;" >
													Find out more
												</v-btn>
											</div>
										</v-col>
									</v-row>
								</v-container>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-container>
			<!-- DESKTOP -->
			<v-container ma-0 pa-0 fluid class="hidden-sm-and-down">
				<v-row no-gutters>
					<v-col class="wrap" style="position: relative;" cols="4" v-for="(offer, index) in offers" :key="offer.id" fill-height @click="navigateToDetail(offer.id)" >
						<v-img :height="heightOfferCard" :src="$root.asset(offer.imageName, '')"  class="image" style='width: 100%; will-change: transform; image-rendering: -webkit-optimize-contrast;' />
						<div @mouseenter="showText(index)" @mouseleave="hideText(index)">
							<div class="image-description ml-4" :class="offer.name && offer.name.length > 25 ? 'mt-n6':''" style="position: absolute; height: 100%;" :style="{top: 0, width: offer.name && offer.name.length > 30 ? '85%' : '80%'}">
								<v-container fluid ma-0 pa-0 fill-height>
									<v-row no-gutters>
										<v-col class="d-flex align-end">
											<div class="pt-12 mt-12">
												<div :class="offer.name && offer.name.length > 25 ? 'text-md-h4 mb-2 mx-4 pb-2' : 'text-md-h3 mb-2 mx-4 pb-2'" style="color: white; border-bottom: 3px solid white" v-html="offer.name && offer.name.length > 60 ? offer.name.slice(0, 60) + '...' : offer.name"></div>
												<div class="text-caption text-sm-body-1 text-md-h6 ml-4" style="font-family: Source Sans Pro !important; font-weight: normal; color: white; letter-spacing: 0px; line-spacing: 33">{{ offer.summary && offer.summary.length > 60 ? offer.summary.slice(0, 60) + '...' : offer.summary  }}</div>
												<div class="pt-2"></div>
												<v-btn @click="navigateToDetail(offer.id)" plain color="white" class="orange-hover text-body-2 text-sm-body1 text-md-body-1 text-capitalize mx-4" style="border: 1px solid white; border-radius:0px!important;" >
													Find out more
												</v-btn>
											</div>
										</v-col>
									</v-row>
								</v-container>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-container>
	</div>
</template>

<script>
import axios from "axios";
import agoraBreadcrumb from "./agoraBreadcrumb.vue";
export default {
	components: {
		agoraBreadcrumb
	},
	data: function() {
		return {
			baseLocation: axios.defaults.baseURL,
			offers: [],
			isHovered: [],
		};
	},
	computed: {
		heightOfferCard() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '350'
			} else if (width > 700) {
				return '300'
			} else {
				return '250'
			}
		}
	},
	mounted: function() {
		this.list();
	},
	methods: {
		navigateToDetail(offerId) {
			const path = "/offer/detail/" + offerId;

			if (this.$route.path !== path) {
				this.$router.push(path);
			}
		},
		list() {
			if (!this.$store.getters.isAuthenticated) {
				this.$router.push("/signin/member offers");
			} else {
				axios.get("/system/public/product/list?action=load&type=Offer").then(response => {
					this.offers = response.data;
				});
			}
		},
		showText(index) {
			this.isHovered[index] = true;
		},
		hideText(index) {
			this.isHovered[index] = false;
		}
	}
};
</script>

<style scoped>
.wrap{
	overflow: hidden !important;
}

.image{
	max-height: 100%; 
	max-width: 100%;
	background-size: contain, cover; 
	will-change: transform; 
	image-rendering: -webkit-optimize-contrast; 
	position: relative;
	transition: all .3s;
}

.wrap:hover .image, .tile.hover .image {
    transform: scale(1.5);
}

.image::before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(194, 85, 26, 0.599);;
}

.wrap:hover .image:before,
.wrap:focus .image:before {
  display: block;
}

.image-description {
  display: none;
}

.wrap:hover div.image-description {
  display: block;
}

</style>