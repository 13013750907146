<template>
	<v-app>
		<v-main>
			<!-- DESKTOP -->
			<v-container fluid ma-0 pa-0 fill-height elevation="0" height="100%" :style="{backgroundImage: `url(${$root.background()})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}">
				<v-row class="d-flex justify-center align-center" style="z-index: 3">
					<v-col class="d-flex justify-center">
						<v-card :class="$vuetify.breakpoint.mdAndUp ? 'px-4' : 'px-1'" :max-width="$vuetify.breakpoint.mdAndUp ? '400px': '350px'" color="#F2F2F2" style="border-radius: 15px;">
							<v-card-title class="d-flex flex-column justify-center align-center px-0 py-0">
								<img :src="baseLocation + '/resource/asset/icon/agora.svg'" width="135" class="pt-8" />
								<div v-if="!$store.state.userSelection.show" class="d-flex col-12 justify-center align-center" :style="{ fontWeight:'900', color:'black', fontSize: titleFontSize}">Welcome to Agora, the On Premise Marketplace.</div>
								<div v-if="$store.state.userSelection.show" style="font-size:19px;color:black;">Select Users</div>
								<div class="pb-4"></div>
							</v-card-title>

							<v-card-text class="pt-0" style="text-align:center;color:#3C3C3C !important;">
								<v-form v-if="!$store.state.userSelection.show" ref="form" @submit.prevent="login">
									<v-text-field v-model="email" class="custom-label mt-n1 pb-8" hide-details="true" height="40" label="ALM Customer Number | Email | Username" small background-color="white" outlined color="black"></v-text-field>
									<v-text-field v-model="password" class="custom-label mt-n1" hide-details="true" height="40" :type="passwordFieldType" label="Password" small background-color="white" outlined color="black">
										<template #append>
											<v-icon right @click="switchVisibility" >
												{{passwordFieldType === 'password' ? 'mdi-eye' : 'mdi-eye-off'}}
											</v-icon>
										</template>
									</v-text-field>
									<v-checkbox v-model="rememberMe" class="ma-0 pl-2 pt-2" hide-details="true">
										<template v-slot:label>
											<span style="font-size:14px;">Stay signed in</span>
										</template>
									</v-checkbox>
									<div class="d-flex flex-column justify-center align-center py-5">
										Forgotten your password? <br v-if="$vuetify.breakpoint.smAndDown" />Please contact the ALM On Premise Team 
										<a href="mailto:almonpremise@almliquor.com.au" style="color:#3C3C3C" target="_blank">almonpremise@almliquor.com.au</a>
									</div>
									<div class="d-flex flex-column justify-center pb-4">
										By signing in you agree to ALM Agora's
										<div>
											<a href="https://www.metcash.com/legal/privacy-policy/" style="color:#3C3C3C" target="_blank">Privacy Policy</a> &
											<a href="https://www.metcash.com/legal/terms-conditions/" style="color:#3C3C3C" target="_blank">Terms and conditions</a>
										</div>
									</div>
									
									<v-btn type="submit" color="#F16013" width="100%" style="font-size:22px;" class="white--text">Login</v-btn>
									<p style="font-size:14px;margin-top:15px;">
										Not yet a Member?
										<a href="https://metcash.applyeasy.com.au/credit" style="color:#3C3C3C" target="_blank">OPEN ACCOUNT</a>
									</p>
									<p style="font-size:12px;line-height:1;">Notice - Some venues may not be able to participate in everything ALM Agora has to offer, due to your buying group company policies. If unsure please consult with your wholesalers rep</p>
								</v-form>

								<v-form v-if="$store.state.userSelection.show" ref="form" @submit.prevent="relogin">
									<v-radio-group
										v-model="personId"
										style="margin-top: -5px"
									>
										<v-radio
											v-for="(person, idxPerson) in $store.state.userSelection.personList"
											:key="idxPerson"
											:value="person.id"
											:label="person.name"
										>
										</v-radio>
									</v-radio-group>
									<v-btn type="submit" color="#F16013" width="100%" style="font-size:22px;" class="white--text">Login</v-btn>
								</v-form>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
			<router-view></router-view>

			<v-dialog v-model="$store.state.notification.show" max-width="500" persistent>
				<v-card style="padding: 20px">
					<div style="display: flex; justify-content: space-between">
						<h2 style="margin-bottom: 10px">{{ $store.state.notification.title }}</h2>
						<v-btn icon color="secondary" @click="closeNotification">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
					<template v-if="$store.state.notification.content && $store.state.notification.content.type == 'NOTIFICATION'">
						<div style="color: #c42126; font-size: 14px" :key="item" v-for="item in $store.state.notification.content.fieldErrorList">{{ item }}</div>
						<div style="color: #ed5025; font-size: 14px" :key="item" v-for="item in $store.state.notification.content.errorList">{{ item }}</div>
						<div style="color: #e26026; font-size: 14px" :key="item" v-for="item in $store.state.notification.content.noticeList">{{ item }}</div>
					</template>
					<template v-else>
						<div style="color: #e26026; font-size: 14px">{{ $store.state.notification.content }}</div>
					</template>
					<div style="text-align: center; margin-top: 20px">
						<v-btn color="primary" @click="closeNotification">Close</v-btn>
					</div>
				</v-card>
			</v-dialog>
				
			<img v-show="$root.loader.value" src="loader.svg" class="loader" />
		</v-main>
	</v-app>
</template>

<style>
.custom-label > .v-input__control > .v-input__slot > .v-text-field__slot > .v-label--active {
	color: #9A9B9F !important;
	top: 0;
}
</style>

<script>
import axios from "axios";

export default {
	props: ["text"],
	data() {
		return {
			baseLocation: axios.defaults.baseURL,
			email: null,
			password: null,
			passwordFieldType: "password",
			rememberMe: false,
			personId: null
		};
	},
	mounted() {
		this.doRememberMe();
	},
	computed: {
		titleFontSize: function () {
			var width = this.$vuetify.breakpoint.width
			console.log(width)
			if (width >= 390) {
				return '17px'
			} else {
				return '15px'
			}
		}
	},
	methods: {
		doRememberMe() {
			axios.get("/system/authentication/remember/me").then(response => {
				if (response.data && response.data.length > 0) {
					this.email = response.data;
					this.rememberMe = true;
				}
			});
		},
		login() {
			this.$store
				.dispatch("login", {
					email: this.email,
					password: this.password,
					rememberMe: this.rememberMe
				})
				.then(() => {
					this.$router.go(-1);
				});
		},
		relogin() {
			this.$store
				.dispatch("relogin", {
					personId: this.personId,
					secretId: this.$store.state.userSelection.autenticationCode.secretId,
					secretKey: this.$store.state.userSelection.autenticationCode.secretKey
				})
				.then(() => {
					this.$router.go(-1);
				});
		},
		switchVisibility() {
			this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
		},
		closeNotification() {
			if (this.$store.state.notification.content.noticeList != null && this.$store.state.notification.content.noticeList[0] === "Your session has expired, please sign out and sign in again") {
				this.$store.commit("logout");
				this.$store.commit("hideNotification");
				this.$router.push("/signin");
			} else {
				this.$store.commit("hideNotification");
			}
		}
	}
};
</script>