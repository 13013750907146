<template>
	<v-card :to="link" width="100%" flat>
		<!-- MOBILE -->
		<div class="hidden-md-and-up">
			<v-img :src="image" eager :height="tileHeightMobile" class="rounded-xl"></v-img>
			<v-container fluid ma-0 pa-0 fill-height style="position: absolute; top: 0;" >
				<v-row class="fill-height">
					<v-col class="rounded-l" cols="4" sm="5" offset="1" style="background-color: rgba(176, 67, 28, 0.8);">
						<div class="d-flex fill-height align-center">
							<div class="text-left font-weight-bold" :class="fontSize" style="color: white; font-family: D-DINCondensed-Bold; line-height: 1.2; border-bottom: 3px solid white" v-html="title"></div>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<!-- DESKTOP -->
		<div class="hidden-sm-and-down wrap" @mouseenter="showDescription" @mouseleave="hideDescription">
			<v-img :src="image" class="image"></v-img>
			<div style="position: absolute; left: 5px; width: 90%; height: 100%;" :style="{top: isHovered ? '20%':'40%', }">
				<div class="text-h4 mt-8 mb-2 mx-4 pb-2" style="color: white; border-bottom: 3px solid white" v-html="title"></div>
				<div v-if="isHovered" class="text-h6 ml-4 image-description" style="font-family: Source Sans Pro !important; font-weight: normal; color: white; letter-spacing: 0px; line-spacing: 33">{{ description }}</div>
				<br />
				<v-btn @click="$router.push(link)" plain color="white" class="orange-hover text-body-2 text-sm-body1 text-md-body-1 text-capitalize mx-4" style="font-family: Source Sans Pro !important; border: 1px solid white; border-radius:0px!important;" >
					Find out more
				</v-btn>
			</div>
		</div>
	</v-card>
</template>

<script>
export default {
	props: ["image", "title", "description", "link", "size"],
	data() {
		return {
			isHovered: false
		};
	},
	computed: {
		tileHeightMobile () {
			var width = this.$vuetify.breakpoint.width
			if (width > 700) {
				return '275'
			} else {
				return '135'
			}
		},
		fontSize() {
			if (this.title.length > 25) {
				return "text-body-1 text-sm-h3"
			} else {
				return "text-h6 text-sm-h3"
			}
		}
	},
	methods: {
		showDescription() {
			this.isHovered = true;
		},
		hideDescription() {
			this.isHovered = false;
		}
	},
};
</script>

<style scoped>
.wrap{
	height: 380px;
	overflow: hidden !important;
}

.image{
	height: 100%; 
	width: 100%;
	background-size: contain, cover !important; 
	will-change: transform; 
	image-rendering: -webkit-optimize-contrast; 
	position: relative;
	transition: all .3s;
}

.wrap:hover .image, .tile.hover .image {
    transform: scale(1.5);
}

.image::before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(194, 85, 26, 0.599);;
}

.wrap:hover .image:before,
.wrap:focus .image:before {
  display: block;
}

.image-description {
	display: none;
}

.wrap:hover div.image-description {
  display: block;
}

</style>