<template>
	<div>
		<agora-breadcrumb :title="entity.name"></agora-breadcrumb>
		<v-container>
			<v-row>
				<v-col class="d-flex align-start" cols="12" md="4">
					<v-img :src="baseLocation + '/asset/' + entity.imageName" contain eager :height="auto"></v-img>
				</v-col>
				<v-col cols="12" md="8">
					<div class="text-h5 text-sm-h4 mb-n2" style="font-family: JosefinSans !important;">{{ entity.name }}</div>
					<v-col cols="11" class="ml-md-0 pt-0 mb-4" style="border-bottom: 3px solid black; border-padding: 10px;"></v-col>
					<div class="text-h6 text-sm-h5" style="font-family: Source Sans Pro !important;" v-if="entity.summary">{{ entity.summary }}</div>
					<div v-html="entity.description" style="font-family: Source Sans Pro !important; font-size:20px;"></div>
					<br />
					<v-chip v-for="item in entity.brandList" :key="item.id" background-color="#F2F2F2" text-color="#B1B1B1" class="text-body-2" style="font-family: Source Sans Pro !important;">{{ item.name }}</v-chip>
					<br />
					<v-chip v-for="item in entity.categoryList" :key="item.id" background-color="#F2F2F2" text-color="#B1B1B1" class="text-body-2 my-3" style="font-family: Source Sans Pro !important;">{{ item.name }}</v-chip>
				</v-col>
			</v-row>
			<div class="mb-6 mt-6">
				<div>
					<v-icon>mdi-instagram</v-icon>
					{{ entity.instagramAccount }}
				</div>
				<v-row>
					<v-col cols="6" md="4" v-for="img in entity.instagramDataList" :key="img.id">
						<v-img :class="$vuetify.breakpoint.mdAndUp ? 'rounded-xl':'rounded-lg'" style="object-fit: fill; width: 350px;" :height="heightInstagramImage" :src="baseLocation + '/asset/' + entity.dataMap.instagramImages + '/' + img"></v-img>
					</v-col>
				</v-row>
			</div>
		</v-container>
	</div>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
import agoraBreadcrumb from "./agoraBreadcrumb";

export default {
	props: ["id"],
	components: {
		agoraBreadcrumb
	},
	data() {
		return {
			baseLocation: axios.defaults.baseURL,
			entity: {
				brandList: [],
				categoryList: [],
				dataMap: {}
			}
		};
	},
	computed: {
		heightImage() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '626'
			} else if (width > 700) {
				return '426'
			} else {
				return '226'
			}
		},
		heightInstagramImage() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '350'
			} else if (width > 700) {
				return '298'
			} else {
				return '148'
			}
		}
	},
	mounted: function() {
		this.find();
	},
	methods: {
		find() {
			axios
				.get("/system/public/ambassador?id=" + this.id)
				.then(response => {
					this.entity = response.data;
				})
				.catch(() => {});
		}
	}
};
</script>