<template>
	<div>
		<agora-breadcrumb></agora-breadcrumb>

		<v-container>
			<h1 class="text-h5 text-sm-h3 my-0" style="font-family: JosefinSans !important;">VIDEO CONTENT AND TRAINING</h1>
			<br />
			<div class="text-body-1 text-sm-h6 mb-4" style="font-family: Source Sans Pro !important;">View all your video content here, from mixing cocktails, digital advertising you can display in your venues to connecting with hospitality training providers all in the one spot.</div>
			<br />
			<v-row>
				<v-col cols="12" md="6">
					<agora-lead-tile
						:image="$root.asset(venueImage.imageNamesMap.brandEducation, null)"
						title="BRAND EDUCATION"
						description="Learn about brands and their products through educational videos that you can download here in your own time."
						link="/brandeducation/list"
					></agora-lead-tile>	
				</v-col>
				<v-col cols="12" md="6">
					<agora-lead-tile
						:image="$root.asset(venueImage.imageNamesMap.cocktailMaking, null)"
						title="COCKTAIL MAKING"
						description="Want to train your staff in cocktail making? Download videos here from brands showing you how to make your favourite cocktails."
						link="/cocktailmaking/list"
					></agora-lead-tile>
				</v-col>
				<v-col cols="12" md="6">	
					<agora-lead-tile
						:image="$root.asset(venueImage.imageNamesMap.pointOfSale, null)"
						title="POINT OF SALE"
						description="Did you need any branded digital video content to display on your screens or your webpage? Download it all here."
						link="/pointofsale/list"
					></agora-lead-tile>
				</v-col>
				<v-col cols="12" md="6">
					<agora-lead-tile
						:image="$root.asset(venueImage.imageNamesMap.trainingPartners, null)"
						title="TRAINING PARTNERS"
						description="Connecting venues and their staff to hospitality training providers to educate and enhance their skills and enabling them to provide quality service for customers."
						link="/trainingpartner/list"
					></agora-lead-tile>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import axios from "axios";
import agoraBreadcrumb from "./agoraBreadcrumb.vue";
import agoraLeadTile from "./agoraLeadTile";

export default {
	components: {
		agoraLeadTile,
		agoraBreadcrumb
	},
	data() {
		return {
			baseLocation: axios.defaults.baseURL,
			venueImage: { imageNamesMap:{ heroImage:"" }}
		};
	},
	mounted: function() {
		this.findVenueImage();
	},
	methods: {
		findVenueImage() {
			let vm = this;
			axios.get("/system/public/venueImage?page=Video")
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.venueImage = response.data;
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>