<template>
	<div>
		<agora-breadcrumb :title="entity.name"></agora-breadcrumb>
		<v-container>
			<br />
			<v-row>
				<v-col cols="12" md="6" style="margin: -10px -10px 10px -10px;">
					<media :name="entity.imageName" />
				</v-col>
				<v-col cols="12" md="6">
					<div class="text-h5 text-sm-h4 mb-n2" style="font-family: JosefinSans !important;">{{ entity.name }}</div>
					<v-col cols="11" class="ml-md-0 pt-0 mb-4" style="border-bottom: 3px solid black; border-padding: 10px;"></v-col>
					<div style="font-family: Source Sans Pro !important;">Supplier: {{entity.dataMap.companyName}}</div>
					<div v-html="entity.description" style="font-family: Source Sans Pro !important; font-size:20px;"></div>
					<br />
					<v-chip background-color="#F2F2F2" text-color="#B1B1B1" style="font-family: Source Sans Pro !important; margin-right: 5px;" v-for="item in categoryList" :key="item.id">{{ item.name }}</v-chip>
					<br />
					<div style="margin-top: 15px;">
						<v-btn class="orange-hover text-body-1 text-sm-h6 text-capitalize" outlined style="letter-spacing: 1px; color: black; padding: 20px 30px; margin: 0 10px 10px 0; float: left; border-radius: 0;" @click="$root.downloadAsset(entity.imageName)">Download</v-btn>
						<v-btn class="orange-hover text-body-1 text-sm-h6 text-capitalize" outlined style="letter-spacing: 1px; color: black; padding: 20px 30px; margin: 0 10px 10px 0; float: left; border-radius: 0;" @click="$router.push('/brand/' + entity.id + '/product')">See All Assets</v-btn>
						<div style="clear: both;"></div>
					</div>

					<div v-for="product in presenterList" :key="product.id">
						<v-card
							class="orange-hover text-body-1 text-sm-h6 d-flex px-8 py-1 mb-3 justify-center text-center"
							outlined
							style="font-size:20px; letter-spacing: 1px; color:black; border-radius: 0; border: 1px solid black;"
							@click="$root.downloadAsset(product.imageName)"
							>{{product.name}}-Download Trade Presenter Main Asset
						</v-card>
						
						<div v-for="(item,index) in product.imageDataList" :key="index">
							<v-card
								class="orange-hover text-body-1 text-sm-h6 d-flex px-8 py-1 mb-3 justify-center text-center"
								outlined
								style="font-size: 20px; letter-spacing: 1px; color:black; border-radius: 0; border: 1px solid black;"
								@click="$root.downloadAsset(item)"
								>{{product.name}}-Download Trade Presenter Secondary Asset {{index+1}}
							</v-card>
						</div>
					</div>

					<div v-for="product in advertisingList" :key="product.id">
						<v-card
							class="orange-hover text-body-1 text-sm-h6 d-flex px-8 py-1 mb-3 justify-center text-center"
							outlined
							style="font-size: 20px; letter-spacing: 1px; color:black; border-radius: 0; border: 1px solid black;"
							@click="$root.downloadAsset(product.imageName)"
							>{{product.name}}-Download Advertising Main Asset
						</v-card>
						<div v-for="(item,index) in product.imageDataList" :key="index">
							<v-card
								class="orange-hover text-body-1 text-sm-h6 d-flex px-8 py-1 mb-3 justify-center text-center"
								outlined
								style="font-size: 20px; letter-spacing: 1px; color:black; border-radius: 0; border: 1px solid black;"
								@click="$root.downloadAsset(item)"
								>{{product.name}}-Download Trade Presenter Secondary Asset {{index+1}}
							</v-card>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>

		<div v-if="entity.imageDataList && entity.imageDataList.length > 0">
			<div v-for="item in entity.imageDataList" :key="item">
				<div class="elevation-10" style="margin-bottom: 10px; position:relative;">
					<media :name="item" />
					<v-btn class="orange-hover" style="color:black; font-size: 20px; letter-spacing: 1px; padding: 20px 30px; position: absolute; right: 15px; bottom: 15px; border: 1px solid black; border-radius: 0px!important;" @click="$root.downloadAsset(item)">Download</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
import media from "./media.vue";
import agoraBreadcrumb from "./agoraBreadcrumb.vue";

export default {
	props: ["id"],
	components: {
		media,
		agoraBreadcrumb
	},
	data() {
		return {
			entity: {
				dataMap: {}
			},
			categoryList: [],
			// tastingNoteList: [],
			presenterList: [],
			advertisingList: []
		};
	},
	mounted: function() {
		this.find();
		this.list();
	},
	methods: {
		find() {
			axios.get("/system/public/brand?id=" + this.id).then(response => {
				this.entity = response.data;
				this.listCategory();
			});
		},
		list() {
			axios
				.get("/system/public/brand/product/list?brandId=" + this.id)
				.then(response => {
					let productList = response.data;
					// this.tastingNoteList = productList.filter(entity => entity.type == 'Tasting');
					this.presenterList = productList.filter(entity => entity.type == "Presenter");
					this.advertisingList = productList.filter(entity => entity.type == "Advertising");
				})
				.catch(() => {});
		},
		listCategory() {
			axios.get("/system/public/brand/category/list?brandId=" + this.id).then(response => {
				this.categoryList = response.data;
			});
		}
	}
};
</script>