<template>
	<div>
		<agora-breadcrumb :title="product.name"></agora-breadcrumb>
		<v-container class="pb-8">
			<v-row>
				<v-col>
					<div>
						<span class="text-h5 text-sm-h3 my-0 text-uppercase" style="font-family: JosefinSans !important;">{{ product.name }}</span>
						<p v-if="product.productType" class="text-body-1 text-sm-h6 mb-6 mb-md-12" style="font-family: Source Sans Pro !important;">{{ product.productType }} by {{product.dataMap.companyName}}</p>
						<h1 class="text-h6 text-sm-h4" style="font-family: Source Sans Pro !important; margin-bottom:unset;">Conversation</h1>
						<p v-if="entity.id" class="text-body-1 text-sm-h7" style="font-family: Source Sans Pro !important;">between {{entity.dataMap.customerCompanyName}} and {{entity.dataMap.supplierCompanyName}}</p>
					</div>
				</v-col>
			</v-row>
			<v-timeline :dense="$vuetify.breakpoint.smAndDown">
				<v-timeline-item v-for="entity in entityList" :key="entity.id" :color="entity.dataMap.us ? 'agora_primary' : 'agora_secondary'" small :left="!entity.dataMap.us" :right="entity.dataMap.us">
					<span slot="opposite" class="text-body-1 text-sm-h7" style="font-family: Source Sans Pro !important;">{{ entity.dataMap.creatorName }}</span>
					<v-card class="elevation-2">
						<v-card-title class="text-body-1 text-sm-h7" style="font-family: Source Sans Pro !important;">{{ entity.content }}</v-card-title>
						<v-card-text class="d-flex justify-space-between align-center">
							<span class="text-caption text-caption" style="font-family: Source Sans Pro !important;">{{ entity.dataMap.createdText }}</span>
							<v-btn v-if="entity.dataMap.creatorName == 'You'" @click="selectedId = entity.id; dialogDelete.show = true;" icon>
								<v-icon color="error">mdi-delete</v-icon>
							</v-btn>
						</v-card-text>
					</v-card>
				</v-timeline-item>
			</v-timeline>
			<v-textarea v-model="entityNewInterest.content" style="font-family: Source Sans Pro !important;" label="Comment" hint="e.g. Do you have them in another color? Please contact me at this phone number? Can you send to this address?" persistent-hint outlined></v-textarea>
			<div class="d-flex justify-end">
				<v-btn @click="addInterestMessage" class="px-8 orange-hover" color="black" style="border-radius: 0; border: 1px solid black;" outlined >Reply</v-btn>
			</div>
		</v-container>

		<v-dialog v-model="dialogDelete.show" width="400">
			<v-card color="black" elevation="0" style="padding-bottom: 20px">
				<v-card-title class="justify-center">
					<h4 style="font-size:18px; color:white; font-family: Source Sans Pro !important;">{{ dialogDelete.title }}</h4>
				</v-card-title>
				<v-card-text style="font-size:14px; font-family: Source Sans Pro !important; text-align: center; color:#6f7175 !important">{{ dialogDelete.content }}</v-card-text>
				<v-card-actions>
					<v-btn outlined class="orange-hover" color="#6f7175" width="100%" style="color:white; border-radius: 0; font-family: Source Sans Pro !important; font-size:20px" @click="removeInterestMessage">OK</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import axios from "axios";
import agoraBreadcrumb from "./agoraBreadcrumb";

export default {
	components: {
		agoraBreadcrumb
	},
	props: ["id"],
	data() {
		return {
			entity: { dataMap: {} },
			entityList: [],
			product: { dataMap: {} },
			selectedId: null,
			dialogDelete: {
				show: false,
				title: "Delete Message",
				content: "Are you sure you want to delete this Message?"
			},
			entityNewInterest: {
				content: "",
				interestId: this.$route.params.id
			}
		};
	},
	mounted() {
		this.findInterest();
		this.list();
	},
	methods: {
		findInterest() {
			axios
				.post("/system/interest", {
					id: this.id
				})
				.then(response => {
					this.entity = response.data;
					this.findProduct();
				});
		},
		findProduct() {
			axios.get("/system/product?action=load&id=" + this.entity.productId).then(response => {
				this.product = response.data;
				this.product.productType = this.product.type.replace(/([A-Z])/g, " $1").trim();
			});
		},
		list() {
			let vm = this;
			axios
				.post("/system/interest/message/list", {
					interestId: vm.$route.params.id
				})
				.then(response => {
					vm.entityList = response.data;
					vm.$emit("seen");
				});
		},
		addInterestMessage() {
			let vm = this;
			axios
				.post("/system/interest/message/add", {
					entity: vm.entityNewInterest
				})
				.then(() => {
					vm.entityNewInterest.content = null;
					vm.list();
				});
		},
		removeInterestMessage() {
			let vm = this;
			vm.dialogDelete.show = false;
			axios
				.post("/system/interest/message/delete", {
					id: vm.selectedId
				})
				.then(() => {
					vm.list();
				});
		}
	}
};
</script>