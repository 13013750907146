<template>
	<div>
		<agora-breadcrumb></agora-breadcrumb>
		<v-container>
			<h1 class="text-h5 text-sm-h3 my-0 text-uppercase" style="font-family: JosefinSans !important;">YOUR FAVOURITES</h1>
		</v-container>

		<br />
		<v-container v-if="$store.getters.isAuthenticated">
			<div class="mx-0 mx-md-12">
				<v-row>
					<v-col cols="6" md="3" style="position: relative;" v-for="product in displayList" :key="product.id">
						<v-card tile outlined style="cursor: pointer" :height="heightCard" @click="navigateToDetail(product.id)">
							<v-card-text class="fill-height d-flex justify-center align-center">
								<div class="col ma-0 pa-0">
									<v-img :src="$root.asset(product.imageName, 'medium')" contain :height="heightImage"></v-img>
								</div>
							</v-card-text>
						</v-card>
						<v-card :height="heightTextCard" color="#F2F2F2" flat @click="navigateToDetail(product.id)" >
							<v-card-text>
								<h2 class="text-body-1 text-sm-h7 text-md-h7 font-weight-black" style="margin-bottom: 5px; border-bottom: 2px solid black; font-family: JosefinSans !important;">{{ $root.ellipsify(product.name, productNameLimit) }}</h2>
								<p class="text-h8 text-sm-h8 text-md-h8" style="font-family: Source Sans Pro !important;" v-html="$root.ellipsify(product.description, descriptionLimit)"></p>
							</v-card-text>
						</v-card>
						<div style="position: absolute; top: 15px; right: 10px; z-index: 999;">
							<v-btn
								class="text-body-1 text-sm-h6"
								plain
								v-ripple="false"
								background-color="transparent"
								style="min-width: 0;"
								@click="removeFavourite(product)"
							>
								<v-icon right dark color="#F16013" size="25">mdi-heart</v-icon>
							</v-btn>
						</div>
					</v-col>
				</v-row>
				<div v-if="loaded && displayList.length == 0">Empty list</div>
			</div>
		</v-container>

		<v-container v-if="pageList.length > 0">
			<v-row>
				<v-col>
					<v-pagination v-model="pagination.page" :length="pageList.length" :total-visible="$vuetify.breakpoint.smAndUp ? 7:5" class="plain" color="agora_secondary" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right" :previous="pagePrevious" :next="pageNext" @input="pageSet(pagination.page)"></v-pagination>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style scoped>
.brand-card {
	margin-bottom: 30px;
	margin-right: 20px;
	width: 150px;
	border: 2px solid #d8d8da;
}
</style>

<script>
import axios from "axios";
import agoraBreadcrumb from "./agoraBreadcrumb.vue";
export default {
	components: {
		agoraBreadcrumb
	},
	data: function() {
		return {
			baseLocation: axios.defaults.baseURL,
			selected: [],
			itemSort: ["A-Z", "Z-A", "None"],
			itemResult: ["10", "20", "30", "40", "50"],
			favouriteList: [],
			totalSlide: 1,
			displayList: [],
			pagination: { page: 1, pageSize: 12, pageLast: 1, direction: null },
			pageList: [],
			loaded: false
		};
	},
	computed: {
		heightCard() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '320'
			} else if (width > 700) {
				return '275'
			} else {
				return '230'
			}
		},
		heightImage() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '220'
			} else if (width > 700) {
				return '175'
			} else {
				return '140'
			}
		},
		heightButton() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '38'
			} else if (width > 700) {
				return '34'
			} else {
				return '31'
			}
		},
		heightTextCard() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '100'
			} else if (width >= 700) {
				return '100'
			} else {
				return '100'
			}
		},
		productNameLimit() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '18'
			} else if (width >= 700) {
				return '28'
			} else {
				return '8'
			}
		},
		descriptionLimit() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '50'
			} else if (width >= 700) {
				return '100'
			} else {
				return '25'
			}
		}
	},
	mounted: function() {
		this.list();
	},
	methods: {
		list() {
			if (!this.$store.getters.isAuthenticated) {
				this.$router.push("/signin/favourites");
			} else {
				axios
					.get("/system/favourite/product/list")
					.then(response => {
						this.favouriteList = response.data;
						this.displayList = this.favouriteList;
						this.pageSet(1);
						this.loaded = true;
					})
					.catch(() => {});
			}
		},
		removeFavourite(item) {
			axios
				.post("/system/favourite/remove", {
					productId: item.id
				})
				.then(() => {
					this.list();
				});
		},
		navigateToDetail(productId) {
			const path = "/product/detail/" + productId;

			if (this.$route.path !== path) {
				this.$router.push(path);
			}
		},
		pagePrevious() {
			this.pageSet(this.pagination.page - 1);
		},
		pageNext() {
			this.pageSet(this.pagination.page + 1);
		},
		pageSet(page) {
			this.pagination.pageLast = Math.ceil(this.favouriteList.length / this.pagination.pageSize);

			this.pageList.length = 0;
			for (let i = 1; i <= this.pagination.pageLast; i++) {
				this.pageList.push(i);
			}

			if (this.pageList.length == 0 || (page >= 1 && page <= this.pagination.pageLast)) {
				this.pagination.page = page;
				this.paginate();
			}
		},
		paginate() {
			let start = (this.pagination.page - 1) * this.pagination.pageSize;
			let end = start + this.pagination.pageSize;
			this.displayList = this.favouriteList.slice(start, end);
		}
	}
};
</script>


