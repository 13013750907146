<template>
	<div>
		<agora-breadcrumb></agora-breadcrumb>

		<v-container>
			<h1 class="text-h5 text-sm-h3 my-0 text-uppercase" style="font-family: JosefinSans !important;">YOUR PROFILE</h1>
		</v-container>

		<v-container>
			<v-row>
				<v-col>
					<!-- DESKTOP -->
					<v-row class="mb-4 hidden-sm-and-down" >
						<v-col :class="justifyHeaderButton">
							<div class="d-flex fluid col-12 mb-0 pb-0 pl-0" style="border-bottom: 1px solid #9E968D;">
								<div class="d-flex fluid col-6 ma-0 pa-0">
									<v-btn @click="page = 1; filterKey = 'All';" class="text-caption px-2 px-sm-6 text-sm-body-1" :small="smallButton" outlined color="#939393" :style="{fontFamily: $vuetify.breakpoint.mdAndUp ? 'JosefinSans !important':'', borderRadius: 0, border: 0, borderBottom: page == 1 ? '2px solid #F16013':'0px'}">Company Details</v-btn>
									<v-btn @click="page = 2" class="text-caption px-3 px-sm-6 text-sm-body-1" :small="smallButton" outlined color="#939393" :style="{fontFamily: $vuetify.breakpoint.mdAndUp ? 'JosefinSans !important':'', borderRadius: 0, border: 0, borderBottom: page == 2 ? '2px solid #F16013':'0px'}">Staff</v-btn>
								</div>
								<div v-if="page == 1" class="d-flex fluid col-6 justify-end ma-0 pa-0" style="position: relative;">
									<v-btn @click="dialogResetPasswordMe = true" class="px-sm-4 mr-sm-4 text-caption text-sm-body-2 orange-hover text-capitalize" outlined :small="smallButton" style="border-radius: 0px; position: absolute; bottom: 5px; right: 260px; font-family: Source Sans Pro !important; " >Reset Password</v-btn>
									<v-btn @click="logout()" class="px-sm-4 text-caption text-sm-body-2 orange-hover text-capitalize" outlined :small="smallButton" width="120" style="border-radius: 0px; position: absolute; bottom: 5px; right: 135px; font-family: Source Sans Pro !important; ">Logout</v-btn>
									<v-btn @click="edit" class="px-sm-4 text-caption text-sm-body-2 text-capitalize" outlined :small="smallButton" width="120" style="border-radius: 0px; position: absolute; bottom: 5px; font-family: Source Sans Pro !important; background-color: #F16013; color: white;">Save</v-btn>
								</div>
								<div v-if="page == 2" class="d-flex fluid col-6 justify-end ma-0 pa-0" style="position: relative;">
									<v-btn @click="dialogAdd = true" class="px-sm-4 mr-sm-4 text-caption text-sm-body-2 orange-hover text-capitalize" outlined :small="smallButton" style="border-radius: 0px; position: absolute; bottom: 5px; right: -20px; font-family: Source Sans Pro !important; " >Add New</v-btn>
								</div>
							</div>
						</v-col>
					</v-row>
					<!-- MOBILE -->
					<v-row class="mb-4 mt-n6 hidden-md-and-up justify-center">
						<div class="d-flex fluid col-11 mb-0 pb-0 px-0" style="border-bottom: 1px solid #9E968D;">
							<v-col cols="6" class="ma-0 pa-0">
								<v-btn @click="page = 1; filterKey = 'All';" class="text-body-1 text-sm-body-1" block height="40" :small="smallButton" outlined color="#939393" :style="{fontFamily: $vuetify.breakpoint.mdAndUp ? 'JosefinSans !important':'', borderRadius: 0, border: 0, borderBottom: page == 1 ? '2px solid #F16013':'0px'}">Company Details</v-btn>
							</v-col>
							<v-col cols="6" class="ma-0 pa-0">
								<v-btn @click="page = 2" class="text-body-1 text-sm-body-1" block height="40" :small="smallButton" outlined color="#939393" :style="{fontFamily: $vuetify.breakpoint.mdAndUp ? 'JosefinSans !important':'', borderRadius: 0, border: 0, borderBottom: page == 2 ? '2px solid #F16013':'0px'}">Staff</v-btn>
							</v-col>
						</div>
					</v-row>
					<div v-if="page == 1" class="mb-4 mt-n2 hidden-md-and-up">
						<v-row>
							<v-col>
								<v-btn @click="dialogResetPasswordMe = true" class="px-sm-4 mr-sm-4 text-caption text-sm-body-2 orange-hover text-capitalize" block outlined :small="smallButton" style="border-radius: 0px; font-family: Source Sans Pro !important; " >Reset Password</v-btn>
							</v-col>
							<v-col>
								<v-btn @click="logout()" class="px-sm-4 text-caption text-sm-body-2 orange-hover text-capitalize" block outlined :small="smallButton" style="border-radius: 0px; font-family: Source Sans Pro !important; ">Logout</v-btn>
							</v-col>
						</v-row>
					</div>
					<v-row v-if="page == 2" class="mb-4 mt-n2 hidden-md-and-up">
						<v-col>
							<v-btn @click="dialogAdd = true" class="px-sm-4 mr-sm-4 text-caption text-sm-body-2 orange-hover text-capitalize" block outlined :small="smallButton" style="border-radius: 0px; font-family: Source Sans Pro !important; " >Add New</v-btn>
						</v-col>
					</v-row>
					<!---->
					
					<v-row>
						<v-col>
							<template v-if="page == 1">
								<v-form>
									<v-row class="d-flex justify-space-between">
										<v-col cols="12" sm="12" md="6" class="d-flex align-center">
											<v-row>
												<v-col>
													<v-row>
														<v-col cols="8" sm="12" class="d-flex justify-start align-center pb-0">
															<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">ALM Customer Number</span>
														</v-col>
														<!-- MOBILE -->
														<v-col cols="4" class="hidden-md-and-up pb-0">
															<div class="d-flex justify-end">
																<v-btn @click="edit" class="px-sm-4 text-caption text-sm-body-2 text-capitalize" block outlined :small="smallButton" style="border-radius: 0px; font-family: Source Sans Pro !important; background-color: #F16013; color: white;">Save</v-btn>
															</div>
														</v-col>
													</v-row>
												</v-col>
												<v-col cols="12" class="d-flex justify-left align-center pt-1">
													<v-text-field v-model="company.accountId" outlined class="text-input-grey" hide-details="true" solo dense width="100%" small background-color="white" disabled flat style="font-family: Source Sans Pro !important;border-radius: 0;"></v-text-field>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
									<v-row class="mt-0">
										<v-col class="col-12 col-sm-6 pb-0 pb-sm-3">
											<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">Name</span>
											<v-text-field v-model="person.name" outlined class="pb-4 text-input-grey" hide-details="true" solo dense width="100%" small background-color="white" flat style="font-family: Source Sans Pro !important;border-radius: 0;"></v-text-field>
											<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">Position</span>
											<v-select v-model="person.jobRole" :items="jobRoleList" item-text="text" item-value="value" outlined class="pb-4 text-select-grey" hide-details="true" solo dense width="100%" small background-color="white" flat style="font-family: Source Sans Pro !important;border-radius: 0"></v-select>
											<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">Email</span>
											<v-text-field v-model="person.email" outlined class="pb-4 text-input-grey" hide-details="true" solo dense width="100%" small background-color="white" flat style="font-family: Source Sans Pro !important;border-radius: 0"></v-text-field>
										</v-col>
										<v-col class="col-12 col-sm-6 pt-0 pt-sm-3">
											<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">Outlet Name</span>
											<v-text-field v-model="company.name" outlined class="pb-4 text-input-grey" hide-details="true" solo dense width="100%" small background-color="white" flat style="font-family: Source Sans Pro !important;border-radius: 0"></v-text-field>
											<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">Address for supplier to send asset</span>
											<v-textarea v-model="company.address" id="position" auto-grow  outlined class="pb-4 text-input-area-grey" height="120" hide-details="true" solo dense width="100%" small background-color="white" flat style="font-family: Source Sans Pro !important;border-radius: 0"></v-textarea>
										</v-col>
									</v-row>
								</v-form>
							</template>
							<template v-if="page == 2">
								<v-form>
									<!-- DESKTOP -->
									<template>
										<v-row>
											<v-col cols="12" v-for="(item, index) in staffList" :key="index">
												<v-card style="font-family: Source Sans Pro !important;">
													<!-- DESKTOP -->
													<div class="hidden-sm-and-down">
														<v-card-text class="d-flex justify-space-between align-center pr-0">
															<v-col cols="3" class="font-weight-black">{{ item.name }}</v-col>
															<v-col cols="3">{{ item.email }}</v-col>
															<v-col cols="2">
																<v-row no-gutters class="d-flex justify-end align-center">
																	<v-col>
																		<v-btn @click="switchStatus(item)" class="px-sm-4 text-caption text-sm-body-2 orange-hover text-capitalize" block outlined :small="smallButton" style="border-radius: 0px; font-family: Source Sans Pro !important; ">
																			Make {{ item.active ? "Inactive" : "Active" }}
																		</v-btn>
																	</v-col>
																</v-row>
															</v-col>
															<v-col>
																<v-row class="d-flex justify-end">
																	<v-col>
																		<v-btn @click="showEditPasswordStaff(item)" class="px-sm-4 text-caption text-sm-body-2 orange-hover text-capitalize" block outlined :small="smallButton" style="border-radius: 0px; font-family: Source Sans Pro !important; ">
																			Update Password
																		</v-btn>
																	</v-col>
																	<v-col>
																		<v-btn @click="showDeleteStaff(item)" class="px-sm-4 text-caption text-sm-body-2 orange-hover text-capitalize" block outlined :small="smallButton" style="border-radius: 0px; font-family: Source Sans Pro !important; ">
																			Delete
																		</v-btn>
																	</v-col>
																</v-row>
															</v-col>
														</v-card-text>
													</div>

													<!-- MOBILE -->
													<div class="hidden-md-and-up">
														<v-card-text class="d-flex justify-space-between">
															<v-row no-gutters class="d-flex justify-space-between">
																<v-col cols="6">{{ item.name }}</v-col>
																<v-col cols="5">{{ item.email }}</v-col>
															</v-row>
														</v-card-text>
														<v-card-text class="mt-n3 d-flex justify-space-between">
															<v-row no-gutters class="mt-n1 d-flex justify-space-between">
																<v-col cols="6" align-self="center">
																	<span>{{ item.status }}</span>
																</v-col>
																<v-col cols="5" align-self="center" >
																	<v-btn @click="switchStatus(item)" class="px-sm-4 text-caption text-sm-body-2 orange-hover text-capitalize" block outlined :small="smallButton" style="border-radius: 0px; font-family: Source Sans Pro !important; ">
																		Make {{ item.active ? "Inactive" : "Active" }}
																	</v-btn>
																</v-col>
															</v-row>
														</v-card-text>
														<v-card-text class="mt-n2 d-flex justify-space-between">
															<v-row no-gutters class="mt-n2 d-flex justify-space-between">
																<v-col cols="6">
																	<v-btn @click="showEditPasswordStaff(item)" class="px-sm-4 text-caption text-sm-body-2 orange-hover text-capitalize" block outlined :small="smallButton" style="border-radius: 0px; font-family: Source Sans Pro !important; ">
																		Update Password
																	</v-btn>
																</v-col>
																<v-col cols="5">
																	<v-btn @click="showDeleteStaff(item)" class="px-sm-4 text-caption text-sm-body-2 orange-hover text-capitalize" block outlined :small="smallButton" style="border-radius: 0px; font-family: Source Sans Pro !important; ">
																		Delete
																	</v-btn>
																</v-col>
															</v-row>
														</v-card-text>
													</div>
												</v-card>
											</v-col>
										</v-row>
									</template>
								</v-form>
							</template>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>

		<!-- Add staff -->
		<v-dialog v-model="dialogAdd" max-width="350px">
			<v-card class="rounded-lg" style="overflow: hidden; background: #F2F2F2 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000029; opacity: 1;">
				<v-card-title class="mt-4">
					<v-row>
						<v-col class="d-flex justify-center align-center">
							<div>
								<h1 class="text-body-1 text-sm-h6 font-weight-black" style="font-family: Source Sans Pro !important;">Add New Staff Member</h1>
							</div>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text class="mt-4">
					<v-form>
						<v-row>
							<v-col class="col-12">
								<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">Name</span>
								<v-text-field v-model="staff.name" outlined class="pb-4 text-input-grey" hide-details="true" solo dense width="100%" small background-color="white" flat style="font-family: Source Sans Pro !important;border-radius: 0"></v-text-field>
								<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">Email</span>
								<v-text-field v-model="staff.email" outlined class="pb-4 text-input-grey" hide-details="true" solo dense width="100%" small background-color="white" flat style="font-family: Source Sans Pro !important;border-radius: 0"></v-text-field>
								<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">Position</span>
								<v-select v-model="staff.jobRole" :items="jobRoleList" item-text="text" item-value="value" outlined class="pb-4 text-input-grey" hide-details="true" solo dense width="100%" small background-color="white" flat style="font-family: Source Sans Pro !important;border-radius: 0"></v-select>
								<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">Password</span>
								<v-text-field v-model="staff.password" outlined class="text-input-grey" hide-details="true" solo dense width="100%" small background-color="white" flat style="font-family: Source Sans Pro !important;border-radius: 0"></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-row class="mb-1">
						<v-col class="d-flex justify-center align-center">
							<div>
								<v-btn :small="smallButton" outlined class="mr-4" @click="dialogAdd = false" style="border-radius: 0;">Cancel</v-btn>
								<v-btn :small="smallButton" outlined color="black" class="orange-hover" @click="addStaff" style="border-radius: 0;">Add</v-btn>
							</div>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Reset password staff -->
		<v-dialog v-model="dialogResetPasswordStaff" max-width="500">
			<v-card class="rounded-lg" style="overflow: hidden; background: #F2F2F2 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000029; opacity: 1;">
				<v-card-title class="mt-4">
					<v-row>
						<v-col class="d-flex justify-center align-center">
							<div>
								<h1 class="text-body-1 text-sm-h6 font-weight-black" style="font-family: Source Sans Pro !important;">Update Password</h1>
							</div>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text class="mt-4">
					<v-form>
						<v-row>
							<v-col class="col-12">
								<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">New Password</span>
								<v-text-field v-model="newStaffPassword" outlined class="pb-4 text-input-grey" hide-details="true" solo dense width="100%" small background-color="white" flat style="font-family: Source Sans Pro !important;border-radius: 0"></v-text-field>
								<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">Confirm New Password</span>
								<v-text-field v-model="confirmNewStaffPassword" outlined class="text-input-grey" hide-details="true" solo dense width="100%" small background-color="white" flat style="font-family: Source Sans Pro !important;border-radius: 0"></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-row class="mb-1">
						<v-col class="d-flex justify-center align-center">
							<div>
								<v-btn :small="smallButton" outlined class="mr-4" @click="newStaffPassword = ''; confirmNewStaffPassword = ''; dialogResetPasswordStaff = false;" style="border-radius: 0;">Cancel</v-btn>
								<v-btn :small="smallButton" outlined color="black" class="orange-hover" @click="editPasswordStaff" style="border-radius: 0;">Save</v-btn>
							</div>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Delete staff -->
		<v-dialog v-model="dialogDelete" max-width="300px">
			<v-card class="rounded-lg" style="overflow: hidden; background: #F2F2F2 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000029; opacity: 1;">
				<v-card-text class="mt-6">
					<v-row>
						<v-col class="col-12 justify-center align-center">
							<div class="text-body-1 text-sm-body-1 font-weight-black" style="font-family: Source Sans Pro !important;">
								<center>
									Are you sure you want to delete {{selectedDeleteStaff}}? 
								</center>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-row class="mb-1">
						<v-col class="d-flex justify-center align-center">
							<div>
								<v-btn :small="smallButton" outlined class="mr-4" @click="dialogDelete = false;" style="border-radius: 0;">Cancel</v-btn>
								<v-btn :small="smallButton" outlined color="black" class="orange-hover" @click="deleteStaff" style="border-radius: 0;">Delete</v-btn>
							</div>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Reset password company -->
		<v-dialog v-model="dialogResetPasswordMe" max-width="400px" max-height="420px" hide-overlay content-class="elevation-0">
			<v-card class="rounded-lg" style="overflow: hidden; background: #F2F2F2 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000029; opacity: 1;">
				<v-card-title class="mt-4">
					<v-row>
						<v-col class="d-flex justify-center align-center">
							<div>
								<h1 class="text-body-1 text-sm-h6 font-weight-black" style="font-family: Source Sans Pro !important;">Reset Password</h1>
							</div>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text class="mt-4">
					<v-form>
						<v-row>
							<v-col class="col-12">
								<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">Current Password</span>
								<v-text-field v-model="currentPassword" outlined class="pb-4 text-input-grey" hide-details="true" solo dense width="100%" small background-color="white" flat style="font-family: Source Sans Pro !important;border-radius: 0"></v-text-field>
								<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">New Password</span>
								<v-text-field v-model="newPassword" outlined class="pb-4 text-input-grey" hide-details="true" solo dense width="100%" small background-color="white" flat style="font-family: Source Sans Pro !important;border-radius: 0"></v-text-field>
								<span class="text-body-1 text-sm-body-1" style="font-family: Source Sans Pro !important; color:#9a9b9f">Confirm New Password</span>
								<v-text-field v-model="confirmPassword" outlined class="text-input-grey" hide-details="true" solo dense width="100%" small background-color="white" flat style="font-family: Source Sans Pro !important;border-radius: 0"></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-row class="mb-1">
						<v-col class="d-flex justify-center align-center">
							<div>
								<v-btn :small="smallButton" outlined class="mr-4" @click="currentPassword = ''; newPassword = ''; confirmPassword = ''; dialogResetPasswordMe = false;" style="border-radius: 0;">Cancel</v-btn>
								<v-btn :small="smallButton" outlined color="black" class="orange-hover" @click="changePasswordMe" style="border-radius: 0;">Save</v-btn>
							</div>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<style>
.no-background {
	background-color:rgba(255, 255, 255, 0) !important;
}
.v-data-table { 
  overflow-x: auto !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.text-input-grey .v-text-field__slot input {
   color: #9A9B9F !important;
}
.text-input-area-grey .v-text-field__slot textarea {
   color: #9A9B9F !important;
}
.text-select-grey .v-select__selections {
	color: #9A9B9F !important;
}
.v-list-item__title {
	font-family: Source Sans Pro !important; 
	color:#9a9b9f
}
</style>

<script>
import axios from "axios";
import agoraBreadcrumb from "./agoraBreadcrumb.vue";
export default {
	components: {
		agoraBreadcrumb
	},
	data() {
		return {
			currentPassword: "",
			newPassword: "",
			confirmPassword: "",
			newStaffPassword: "",
			confirmNewStaffPassword: "",
			person: {},
			company: {},
			jobRoleList: [],
			page: 1,
			staffList: [],
			staff: {},
			idUpdatePassword: null,
			idDelete: null,
			dialogResetPasswordStaff: false,
			dialogAdd: false,
			dialogDelete: false,
			dialogResetPasswordMe: false,
			selectedDeleteStaff: ""
		};
	},
	computed: {
		justifyHeaderButton() {
			return this.$vuetify.breakpoint.smAndUp ? 'd-flex justify-left':'d-flex justify-space-between'
		},
		smallButton() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return false
			} else if (width > 700) {
				return false
			} else {
				return true
			}
		}
	},
	mounted: function() {
		this.findCompany();
		this.findPerson();
		this.listJobRole();
		this.listStaff();
	},
	methods: {
		logout() {
			this.$store
				.dispatch("logout")
				.then(() => {
					this.$router.push("/signin");
				})
				.catch(() => {});
		},
		changePasswordMe() {
			axios
				.post("/system/selfservice/changepassword", {
					currentPassword: this.currentPassword,
					newPassword: this.newPassword,
					confirmPassword: this.confirmPassword
				})
				.then(() => {
					this.dialogResetPasswordMe = false; 
					this.currentPassword = this.newPassword = this.confirmPassword = "";
					this.$store.commit("showNotification", { title: "Notification", content: "Password successfully changed" });
				})
				.catch(() => {});
		},
		listJobRole() {
			axios
				.get("/system/enumeration/jobrole/list")
				.then(response => {
					this.jobRoleList = response.data;
				})
				.catch(() => {});
		},
		listStaff() {
			axios
				.get("/system/person/list")
				.then(response => {
					this.staffList = response.data;
					for (let staff of this.staffList) {
						staff.active = staff.status == "Active";
					}
				})
				.catch(() => {});
		},
		findCompany() {
			axios
				.get("/system/company/profile")
				.then(response => {
					this.company = response.data;
				})
				.catch(() => {});
		},
		findPerson() {
			axios
				.get("/system/person/me")
				.then(response => {
					this.person = response.data;
				})
				.catch(() => {});
		},
		edit() {
			// don't want to create yet another API, so we're calling to save company first followed by save person, ideally should be 1 call
			axios
				.post("/system/company/profile/edit", {
					entity: this.company
				})
				.then(() => {
					axios
						.post("/system/person/edit", {
							entity: this.person
						})
						.then(() => {
							this.$store.commit("showNotification", { title: "Notification", content: "Saved" });
						})
						.catch(() => {});
				})
				.catch(() => {});
		},
		showAddStaff() {
			this.staff = {};
			this.dialogAdd = true;
		},
		addStaff() {
			axios
				.post("/system/person/add", { entity: this.staff })
				.then(() => {
					this.dialogAdd = false;
					this.listStaff();
				})
				.catch(() => {});
		},
		showDeleteStaff(item) {
			this.idDelete = item.id;
			this.dialogDelete = true;
			this.selectedDeleteStaff = item.name;
		},
		deleteStaff() {
			axios
				.post("/system/person/delete", { id: this.idDelete })
				.then(() => {
					this.dialogDelete = false;
					this.listStaff();
				})
				.catch(() => {});
		},
		showEditPasswordStaff(item) {
			this.idUpdatePassword = item.id;
			this.newStaffPassword = this.confirmNewStaffPassword = "";
			this.dialogResetPasswordStaff = true;
		},
		editPasswordStaff() {
			axios
				.post("/system/person/password/edit", {
					id: this.idUpdatePassword,
					newPassword: this.newStaffPassword,
					confirmPassword: this.confirmNewStaffPassword
				})
				.then(() => {
					this.dialogResetPasswordStaff = false;
				})
				.catch(() => {});
		},
		switchStatus(item) {
			axios
				.post("/system/person/status/switch", { id: item.id })
				.then(() => {
					this.listStaff();
				})
				.catch(() => {});
		}
	}
};
</script>