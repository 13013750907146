<template>
	<div>
		<agora-breadcrumb></agora-breadcrumb>

		<v-container>
			<h1 class="text-h5 text-sm-h3 my-0" style="font-family: JosefinSans !important;">NOTIFICATIONS</h1>
		</v-container>

		<!-- Search -->
		<v-container class="mt-0">
			<div class="d-flex justify-space-between flex-wrap mx-0">
				<div class="d-flex col col-12 justify-start align-center" style="height: 41px; width: 300px; border-bottom: 1px solid black;">
					<v-icon style="cursor: pointer;">mdi-magnify</v-icon>
					<input v-model="keyword" @blur="filter" @keyup.enter="filter" class="custom-placeholder-color-black" type="text" placeholder="Search" style="font-family: Source Sans Pro !important; width: 98%; font-size: 16px; padding: 0 10px;" />
				</div>
			</div>
		</v-container>
		
		<!-- DESKTOP -->
		<v-container class="hidden-sm-and-down">
			<v-row>
				<!-- Header -->
				<v-col cols="12">
					<v-card outlined color="transparent">
						<v-row style="font-family: Source Sans Pro !important;">
							<v-col cols="1"></v-col>
							<v-col cols="1">ID</v-col>
							<v-col cols="2">Received</v-col>
							<v-col cols="2">Supplier</v-col>
							<v-col cols="3">Product</v-col>
							<v-col cols="3"></v-col>
						</v-row>
					</v-card>
				</v-col>
				
				<!-- Rows -->
				<v-col
					v-for="(item, index) in filterList"
					:key="index"
					cols="12"
					class="mb-3"
				>
					<v-card height="88" 
							:class="item[0].dataMap && item[0].dataMap.countUnseen > 0 ? 'font-weight-black' : ''" 
							style="font-family: Source Sans Pro !important;">
						<v-row class="fill-height">
							<v-col cols="1" class="text-center" align-self="center">
								<div :style="{'color': item[0].dataMap && item[0].dataMap.countUnseen > 0 ? '#F47A20' : 'black'}">
									<v-icon
									v-if="item[0].dataMap && item[0].dataMap.countUnseen > 0"
									color="agora_secondary"
									small
									>
									mdi-email-outline
									</v-icon>
									<v-icon v-else color="rgba(0, 0, 0, 0.87)" small>
									mdi-email-open-outline
									</v-icon>
								</div>
							</v-col>
							<v-col cols="1" align-self="center">
								<div>{{ item[0].id }}</div>
							</v-col>
							<v-col cols="2" align-self="center">
								<div>{{ item[0].dataMap.stringDate }}</div>
							</v-col>
							<v-col cols="2" align-self="center">
								<div>{{ item[1].name }}</div>
							</v-col>
							<v-col cols="3" align-self="center">
								<div>{{ item[2].name }}</div>
							</v-col>
							<v-col cols="3" class="text-right pr-8" align-self="center">
								<v-btn
									class="orange-hover text-body-1 text-sm-body-1 px-12 py-0 text-capitalize"
									outlined
									background-color="transparent"
									style="color:black; border-radius: 0; border: 1px solid black;"
									@click="navigateToDetail(item[0].id)"
								>
									View
								</v-btn>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
		
		<!-- MOBILE -->
		<v-container class="hidden-md-and-up">
			<template v-for="(item, index) in filterList">
				<v-card
					:key="index"
					class="custom-card mb-3"
					@click="navigateToDetail(item[0].id)"
					style="font-family: Source Sans Pro !important;"
				>
					<v-card-text>
						<v-row no-gutters>
							<v-col>
								<div
									:class="item[0].dataMap && item[0].dataMap.countUnseen > 0 ? 'font-weight-black' : ''" 
								>
									{{ item[0].id }}
								</div>
							</v-col>
							<v-col class="d-flex justify-end">
								<div
									:class="{'font-weight-black': item[0].dataMap && item[0].dataMap.countUnseen > 0}"
								>
									{{ item[0].dataMap.stringDate }}
								</div>
							<div :style="{ color: item[0].dataMap && item[0].dataMap.countUnseen > 0 ? '#F47A20' : 'black' }">
								<v-icon
									v-if="item[0].dataMap && item[0].dataMap.countUnseen > 0"
									color="agora_secondary"
									small
									style="margin-bottom: 3px; margin-left: 1px;"
								>
									mdi-email-outline
								</v-icon>
								<v-icon v-else color="rgba(0, 0, 0, 0.87)" style="margin-bottom: 3px; margin-left: 1px;" small>
									mdi-email-open-outline
								</v-icon>
							</div>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col>
								<div :class="item[0].dataMap && item[0].dataMap.countUnseen > 0 ? 'font-weight-black' : ''">{{ item[2].name }}</div>
							</v-col>
							<v-col class="d-flex justify-end" :class="item[0].dataMap && item[0].dataMap.countUnseen > 0 ? 'font-weight-black' : ''"  >
								<div >{{ item[1].name }}</div>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</template>
		</v-container>
	</div>
</template>

<script>
import axios from "axios";
import agoraBreadcrumb from "./agoraBreadcrumb.vue";
export default {
	components: {
		agoraBreadcrumb
	},
	data() {
		return {
			baseLocation: axios.defaults.baseURL,
			entityList: [],
			filterList: [],
			keyword: "",
		};
	},
	mounted() {
		this.notificationList();
		this.$emit("seen");
	},
	methods: {
		notificationList() {
			axios.get("/system/interest/list?type=Customer").then(response => {
				this.entityList = response.data;
				this.filterList = response.data;
			});
		},
		navigateToDetail(id) {
			const path = "/notification/detail/" + id;

			if (this.$route.path !== path) {
				this.$router.push(path);
			}
		},
		filter() {
			if (this.keyword && this.keyword.trim().length > 0) {
				let transitList = [];
				for (let item of this.entityList) {
					if (
						(item[0].id && item[0].id.toString().toLowerCase().includes(this.keyword.toLowerCase())) ||
						(item[1].name && item[1].name.toLowerCase().includes(this.keyword.toLowerCase())) ||
						(item[2].name && item[2].name.toLowerCase().includes(this.keyword.toLowerCase())) ||
						(item[0].dataMap.stringDate && item[0].dataMap.stringDate.toLowerCase().includes(this.keyword.toLowerCase()))
					) {
						transitList.push(item);
					}
				}
				this.filterList = transitList; // Assign the filtered list directly
			} else {
				// If keyword is empty, show all notifications
				this.filterList = this.entityList.slice(); // Create a shallow copy
			}
		}

	}
};
</script>