<template>
	<div style="background-color:#f4f4f5;">
		<agora-breadcrumb :title="entity.name"></agora-breadcrumb>
		<v-container>
			<v-row class="mb-4">
				<v-col cols="12" class="d-flex align-center py-0 py-md-6">
					<v-img :src="$root.asset(entity.imageName, '')"></v-img>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<h1 class="text-h5 text-sm-h4 my-0" style="font-family: JosefinSans !important;">{{ entity.name }}</h1>
					<v-col cols="4" class="ml-md-0 mt-n4 mb-4" style="border-bottom: 3px solid black; border-padding: 10px;"></v-col>
					<div class="text-body-1 text-sm-h6 mb-4" style="font-family: Source Sans Pro !important;" v-html="entity.description"></div>
					<br />
				</v-col>
			</v-row>
			<div v-if="entity && entity.dataMap && !$root.isBlank(entity.dataMap.link)" class="text-left" style="margin-bottom: 10px; position: relative">
				<v-btn 
					class="orange-hover text-body-1 text-sm-h6"
					outlined
					background-color="transparent"
					style="font-size:20px; padding: 20px 30px; margin:0 10px 10px 0; letter-spacing: 1px; color:black; border-radius: 0; border: 1px solid black;"
					target="_blank"
					:href="entity.dataMap.link"
					@click="openLinkInNewTab(entity.dataMap.link)"
				>Link</v-btn>
			</div>
			<br />
			<div v-if="entity.imageDataList && entity.imageDataList.length > 0">
				<div v-for="item in entity.imageDataList" :key="item">
					<div class="elevation-10 text-right" style="margin-bottom: 10px; position: relative">
						<media :name="item" />
						<v-btn 
							class="orange-hover text-body-1 text-sm-h6"
							outlined
							background-color="transparent"
							style="font-size:20px; padding: 20px 30px; margin:0 10px 10px 0; letter-spacing: 1px; color:black; border-radius: 0; border: 1px solid black;"
							@click="$root.downloadAsset(item)"
						>Download</v-btn>
					</div>
				</div>
			</div>
		</v-container>
	</div>
</template>

<script>
import axios from "axios";
import media from "./media.vue";
import agoraBreadcrumb from "./agoraBreadcrumb";

export default {
	props: ["id"],
	components: {
		media,
		agoraBreadcrumb
	},
	data: function() {
		return {
			entity: {}
		};
	},
	mounted: function() {
		this.find();
	},
	methods: {
		find() {
			axios
				.get("/system/public/product?id=" + this.id)
				.then(response => {
					this.entity = response.data;
				})
				.catch(() => {});
		},
		openLinkInNewTab(link) {
			if (link) {
				// Check if the link has a protocol (e.g., 'http' or 'https')
				if (!/^https?:\/\//i.test(link)) {
					// If not, add 'https://' as the default protocol
					link = 'https://' + link;
				}

				// Open the link in a new tab
				window.open(link, '_blank');
			}
		},
	}
};
</script>
