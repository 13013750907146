<template>
	<div>
		<agora-breadcrumb :title="entity.name"></agora-breadcrumb>
		<v-container>
			<v-row class="mt-3 mb-3 mb-md-12">
				<v-col cols="12" md="6" class="d-flex">
					<v-card height="100%" width="100%" color="transparent" elevation="0" outlined>
						<v-img style="text-align: center;" eager contain :src="$root.asset(entity.imageName, '')"></v-img>
					</v-card>
				</v-col>
				<v-col cols="12" md="5" class="d-flex ml-md-12">
					<div>
						<v-row class="d-flex">
							<v-col cols="12" class="pt-md-4 pl-md-0 pb-md-0 mx-md-0 px-md-0 pb-0 ">
								<span class="text-h4 text-sm-h3" style="font-family: JosefinSans !important; font-size:50px; letter-spacing:2px">{{ entity.name }}</span>
							</v-col>
							<v-col cols="12" class="d-flex pl-md-0 pb-0">
								<span class="text-h6 text-sm-h5" style="font-family: JosefinSans !important; letter-spacing:0px">{{ entity.summary }}</span>
							</v-col>
							<v-col cols="11" class="ml-3 ml-md-0 pt-0" style="border-bottom: 3px solid black; border-padding: 10px;"></v-col>
							<v-col cols="12" class="d-flex pl-md-0">
								<span style="font-size:19px;">{{ entity.description }}</span>
							</v-col>
							<v-col cols="4" v-for="variant in entity.variantDataList" :key="variant.code">
								<v-checkbox @change="changeSelectedVariant()" v-model="selectedVariant" :label="variant.name" :value="variant.code" color="#F16013" style="margin-top:-10px;"></v-checkbox>
							</v-col>
							<v-col cols="12" class="pl-md-0">
								<v-btn @click="addToCart()" plain @mouseover="isHoverCart = true;" @mouseleave="isHoverCart = false;" :color="isHoverCart ? 'white':'black'" width="150" :right="true" class="orange-hover text-body-2 text-sm-body1 text-md-body-1 text-capitalize" style="border: 1px solid black; border-radius: 0px!important;">
									Add to cart
								</v-btn>
							</v-col>
						</v-row>
					</div>
				</v-col>
			</v-row>
			<v-snackbar v-model="snackbar">
				{{ snackbarContent }}
				<template v-slot:action="{ attrs }">
					<v-btn color="red" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
				</template>
			</v-snackbar>
		</v-container>
	</div>
</template>

<script>
import axios from "axios";
import agoraBreadcrumb from "./agoraBreadcrumb.vue";
export default {
	props: ["id"],
	components: {
		agoraBreadcrumb
	},
	data: function() {
		return {
			baseLocation: axios.defaults.baseURL,
			entity: {},
			selectedVariant: [],
			entityCart: {},
			snackbar: false,
			snackbarContent: null,
			company: {},
			isHoverCart: false,
		};
	},
	mounted() {
		this.find();
		if (this.$store.getters.isAuthenticated) {
			this.findMeCompany();
			this.metricViewAdd();
		}
	},
	methods: {
		find() {
			if (!this.$store.getters.isAuthenticated) {
				this.$router.push("/signin/offer details");
			} else {
				this.findCart();
				axios
					.get("/system/product?action=load&id=" + this.id)
					.then(response => {
						this.entity = response.data;

						this.entity.productType = //
							this.entity.type == "NewRelease"
								? "New Release" //
								: this.entity.type == "Event"
								? "Event" //
								: this.entity.type == "Offer"
								? "Offer" //
								: this.entity.type;
					})
					.catch(() => {});
			}
		},
		findCart() {
			axios
				.post("/system/cart/person/product/find", {
					productId: this.id
				})
				.then(response => {
					this.entityCart = response.data;
					if (this.entityCart) {
						this.selectedVariant = this.entityCart.variantList;
					} else {
						this.entityCart = {};
					}
				});
		},
		addToCart() {
			(this.entityCart.productId = this.entity.id), (this.entityCart.variantList = this.selectedVariant);
			axios
				.post("/system/cart/add", {
					entity: this.entityCart
				})
				.then(response => {
					this.snackbar = true;
					this.snackbarContent = "Item Added";
					this.$store.commit("getTotalCart");
					this.findCart();
					if (response.data["productId"]) {
						this.sendAddToCartAnalytic();
					}
				});
		},
		changeSelectedVariant() {
			if (this.entityCart && this.entityCart.id) {
				axios
					.post("/system/cart/edit/variant", {
						productId: this.id,
						variant: this.selectedVariant
					})
					.then(() => {
						this.$store.commit("getTotalCart");
						this.findCart();
					});
			}
		},
		sendAddToCartAnalytic() {
			this.$root.sendHitTask = true;
			try {
				let userId = this.$root.isBlank(this.$store.state.principal["id"]) == false ? this.$store.state.principal["id"] : "";
				let userName = this.$root.isBlank(this.$store.state.principal["name"]) == false ? this.$store.state.principal["name"] : "";
				let email = this.$root.isBlank(this.$store.state.principal["email"]) == false ? this.$store.state.principal["email"] : "";
				let accountId = this.$root.isBlank(this.company["accountId"]) == false ? this.company["accountId"] : "";
				let companyId = this.$root.isBlank(this.$store.state.principal['companyId']) == false ? this.$store.state.principal['companyId'] : "";
				let companyName = this.$root.isBlank(this.$store.state.principal["companyName"]) == false ? this.$store.state.principal["companyName"] : "";
				let companyType = this.$root.isBlank(this.$store.state.principal["companyType"]) == false ? this.$store.state.principal["companyType"] : "";

				let productId = this.$root.isBlank(this.id) == false ? this.id : "";
				let productName = this.$root.isBlank(this.entity.name) == false ? this.entity.name : "";
				let productType = this.$root.isBlank(this.entity.productType) == false ? this.entity.productType : "";
				let variantCodeList = this.$root.isBlank(this.selectedVariant) == false ? this.selectedVariant : "";
				let variantNameList = "";
				for (let variant of this.entity.variantDataList) {
					if (variant && variantCodeList.toString().includes(variant.code.toString())) {
						if (variantNameList == "") {
							variantNameList = variant.name;
						} else {
							variantNameList = variantNameList + "; " + variant.name;
						}
					}
				}
				variantCodeList = variantCodeList.toString().replace(/,/g, "; ");

				let userInformation = 
					userId + "|" +
					userName + "|" +
					email + "|" +
					accountId + "|" +
					companyId + "|" +
					companyName + "|" +
					companyType

				let productInformation = 
					productId + "|" +
					productName + "|" +
					productType + "|" +
					variantCodeList + "|" +
					variantNameList

				if (this.$root.isBlank(window.location) == false) {
					this.$gtag.event('click_add_to_cart', {
						event_category: 'cart',
						action: 'click',
						event_label: window.location,
						dimension4: userInformation,
						dimension3: productInformation
					});
				}
			} catch(error) {
				console.log(error);
			}			
		},
		findMeCompany() {
			axios
				.get("/system/company/profile")
				.then(response => {
					this.company = response.data;
				})
				.catch(() => {});
		},
		metricViewAdd() {
			fetch('https://api.ipify.org?format=json')
			.then(response => response.json())
			.then(response => {
				axios.get("/system/metric/view/add?productId=" + this.id + "&ip=" + response.ip);
			});
		},
	}
};
</script>
