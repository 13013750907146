<template>
	<div>
		<agora-breadcrumb></agora-breadcrumb>
		<div class="d-flex align-center" :style="'height: '+heightContainer+'px;'">
			<div :style="'position: absolute; '+ ($vuetify.breakpoint.mdAndUp ? 'background-color: #7A5CA7; ':'') + 'width: 100%; height: '+heightPurpleContainer+'px;'"></div>
			<v-container>
				<v-row>
					<v-col class="d-flex justify-space-between align-center">
						<div class="d-flex flex-column" style="position: relative; max-width: 395px">
							<span class="text-uppercase text-h5 text-sm-h3 text-md-h2 font-weight-black agora-text-title" style="font-family: D-DINCondensed-Bold; line-height: 1; color: #f47a20">{{ title }}</span>
							<span class="text-uppercase agora-text-2" style="color: white">{{ subtitle }}</span>
							<span class="agora-text-3" style="color: white">{{ phoneNumber }}</span>
							<span class="agora-text-3" style="color: white">{{ email }}</span>
						</div>
						<v-card class="hidden-sm-and-down" :max-width="widthImageCard" color="rgba(0,0,0,0)" flat>
							<v-img eager :src="image"></v-img>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
import agoraBreadcrumb from "./agoraBreadcrumb";

export default {
	components: {
		agoraBreadcrumb
	},
	props: ["title", "subtitle", "phoneNumber", "email", "image"],
	computed: {
		widthImageCard() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '600'
			} else if (width > 700) {
				return '400'
			} else {
				return '220'
			}
		},
		heightPurpleContainer() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '220'
			} else if (width > 700) {
				return '140'
			} else {
				return '80'
			}
		},
		heightContainer() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '320'
			} else if (width > 700) {
				//return '220' //sm or xm with title+purple+img
				return '100' //only text
			} else {
				//return '140' //xs with title+purple+img
				return '50' //only text
			}
		}
	}
};
</script>