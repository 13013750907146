<template>
	<v-app>
		<v-main :style="{backgroundImage: `url(${$root.background()})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}">
			<!-- MOBILE -->
			<v-app-bar class="hidden-md-and-up" elevation="0" height="65" style="overflow: hidden">
				<v-container fluid ma-0 pa-0>
					<v-row class="justify-space-between align-center">
						<v-col cols="10" class="d-flex justify-start align-center">
							<img :src="baseLocation + '/resource/asset/icon/agora.svg'" class="mr-4 mt-2 ml-2" width="80" height="100" style="cursor: pointer" @click="$router.push('/'); clearSelectedMenu();" />
							<div style="font-size: 8px;" class="ma-0 pa-0 mt-2">Brought to you by</div>
							<img :src="baseLocation + '/resource/asset/icon/alm.png'" width="40" height="30" style="cursor: pointer" @click="$router.push('/'); clearSelectedMenu();" />
						</v-col>
						<v-col cols="2" class="d-flex justify-end align-center mt-1">
							<template v-if="$store.getters.isAuthenticated">
								<v-app-bar-nav-icon @click="showDrawer = true" large></v-app-bar-nav-icon>
							</template>
						</v-col>
					</v-row>
				</v-container>
			</v-app-bar>
			<div class="hidden-md-and-up border-divider mt-2"></div>
			<v-app-bar class="hidden-md-and-up" elevation="0" height="60" style="overflow: hidden">
				<v-container fluid ma-0 pa-0 mt-4> 
					<v-row>
						<v-col class="d-flex justify-space-between align-center">
							<div class="d-flex col justify-end align-center">
								<template v-if="$store.getters.isAuthenticated">
									<v-img v-if="isSearching == false" @click="isSearching = true" class="mb-1 d-flex col col-2 justify-start mr-4" width="24" height="24" style="cursor: pointer" contain :src="baseLocation + '/resource/asset/icon/search.svg'"></v-img>
									<div v-if="isSearching == true" class="d-flex col col-12 justify-start align-center mb-3" style="height: 41px; border-bottom: 1px solid black;">
										<v-icon style="cursor: pointer;" @click="isSearching = false">mdi-magnify</v-icon>
										<input v-model="keyword" @keyup.enter="search(keyword)" class="custom-placeholder-color-black" type="text" placeholder="Search" style="width: 98%; font-size: 16px; font-family: 'D-DIN'; padding: 0 10px;" />
									</div>

									<template v-if="isSearching == false" >
										<router-link to="/favourite">
											<v-img class="mr-5" style="cursor: pointer; margin-bottom: 2px;" width="25" contain :src="baseLocation + '/resource/asset/icon/favourites.svg'"></v-img>
										</router-link>
										<router-link to="/notification" class="mr-4">
											<v-badge :color="$store.state.totalUnseenMessages > 0 ? 'red' : 'transparent'" :content="$store.state.totalUnseenMessages">
												<v-img class="mt-1" style="cursor: pointer" width="26" contain :src="baseLocation + '/resource/asset/icon/messages.svg'"></v-img>
											</v-badge>
										</router-link>
										<router-link to="/cart">
											<v-badge :color="$store.state.totalCart > 0 ? 'red' : 'transparent'" :content="$store.state.totalCart">
												<v-img class="mt-1" style="cursor: pointer" width="27" contain :src="baseLocation + '/resource/asset/icon/cart.svg'"></v-img>
											</v-badge>
										</router-link>

										<router-link to="/contact">
											<v-img class="ml-12 mr-6 mb-1" style="cursor: pointer" width="26" contain :src="baseLocation + '/resource/asset/icon/contacts.svg'"></v-img>
										</router-link>
										<router-link to="/profile">
											<v-img class="mr-4 mb-1" style="cursor: pointer" width="26" contain :src="baseLocation + '/resource/asset/icon/profile.svg'"></v-img>
										</router-link>
									</template>

								</template>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</v-app-bar>
			<v-navigation-drawer class="hidden-md-and-up" v-model="showDrawer" fixed right temporary style="background-color: #F2F2F2; z-index: 1000">
				<v-container>
					<v-row class="justify-end">
						<v-col class="d-flex justify-end align-center my-1 agora-text-3">
							<v-btn color="#F47A20" icon @click="showDrawer = false">
								<v-icon large>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
					<v-row v-for="menu in menuDataList" :key="menu.name" class="justify-end">
						<v-col class="d-flex justify-space-between align-center agora-text-3">
							<v-btn @click="navigateTo(menu.linkTo)" class="hover-underline" tile text >
								<span class="menu-text">{{ menu.name }}</span>
							</v-btn>
							<v-menu v-if="menu.childList && menu.childList.length > 0" :close-on-content-click="false" left bottom offset-y full-width nudge-bottom="10" :nudge-width="180" rounded="0 b-lg" dark>
								<template v-slot:activator="{ on, attrs, value }">
									<v-icon v-bind="attrs" v-on="on" right dark>{{ value ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
								</template>
								<v-list color="#7A5CA7" dense>
									<div v-for="subMenu in menu.childList" :key="subMenu.name" >
										<v-list-item @click="navigateTo(subMenu.linkTo)" link>
											<v-list-item-content>
												<v-list-item-title style="color: white;" class="submenu-text">{{ subMenu.name }}</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</div>
								</v-list>
							</v-menu>
						</v-col>
					</v-row>
				</v-container>
			</v-navigation-drawer>

			<!-- DESKTOP -->
			<span class="hidden-sm-and-down"></span>
			<v-app-bar class="hidden-sm-and-down" elevation="0" height="auto">
				<v-container fluid ma-0 pa-0> 
					<v-container fluid ma-0 pa-0 >
						<v-row>
							<v-col class="d-flex justify-space-between align-center">
								<img :src="baseLocation + '/resource/asset/icon/agora.svg'" class="mt-4" width="180" height="50" style="cursor: pointer" @click="$router.push('/'); clearSelectedMenu();" />
								<div class="mt-4" style="font-size: 10px;">Brought to you by</div>
								<img :src="baseLocation + '/resource/asset/icon/alm.png'" class="mt-4" width="85" height="45" style="cursor: pointer" @click="$router.push('/'); clearSelectedMenu();" />
								<div class="d-flex col justify-end align-center mt-6 pr-0">
									<template v-if="$store.getters.isAuthenticated">
										<v-img v-if="isSearching == false" @click="isSearching = true" class="mb-1 d-flex col col-1 justify-end" width="24" height="24" style="cursor: pointer" contain :src="baseLocation + '/resource/asset/icon/search.svg'"></v-img>
										<div v-if="isSearching == true" class="d-flex col col-3 justify-start align-center mb-3 mr-6" style="height: 41px; border-bottom: 1px solid black;">
											<v-icon style="cursor: pointer;" @click="isSearching = false">mdi-magnify</v-icon>
											<input v-model="keyword" @keyup.enter="search(keyword)" class="custom-placeholder-color-black" type="text" placeholder="Search" style="width: 98%; font-size: 16px; font-family: 'D-DIN'; padding: 0 10px;" />
										</div>

										<router-link to="/favourite">
											<v-img class="mr-5" style="cursor: pointer; margin-bottom: 2px;" width="25" contain :src="baseLocation + '/resource/asset/icon/favourites.svg'"></v-img>
										</router-link>
										<router-link to="/notification" class="mr-4">
											<v-badge :color="$store.state.totalUnseenMessages > 0 ? 'red' : 'transparent'" :content="$store.state.totalUnseenMessages">
												<v-img class="mt-1" style="cursor: pointer" width="26" contain :src="baseLocation + '/resource/asset/icon/messages.svg'"></v-img>
											</v-badge>
										</router-link>
										<router-link to="/cart">
											<v-badge :color="$store.state.totalCart > 0 ? 'red' : 'transparent'" :content="$store.state.totalCart">
												<v-img class="mt-1" style="cursor: pointer" width="27" contain :src="baseLocation + '/resource/asset/icon/cart.svg'"></v-img>
											</v-badge>
										</router-link>

										<router-link to="/contact">
											<v-img class="ml-12 mr-6 mb-1" style="cursor: pointer" width="26" contain :src="baseLocation + '/resource/asset/icon/contacts.svg'"></v-img>
										</router-link>
										<router-link to="/profile">
											<v-img class="mr-4 mb-1" style="cursor: pointer" width="26" contain :src="baseLocation + '/resource/asset/icon/profile.svg'"></v-img>
										</router-link>
									</template>
								</div>
							</v-col>
						</v-row>
					</v-container>
					<div class="border-divider mt-2"></div>
					<v-container>
						<v-row class="align-center">
							<template v-for="(menu) in menuDataList">
								<v-col :key="menu.name" class="d-flex justify-center align-center">
									<v-btn @click="navigateTo(menu.linkTo); clearSelectedMenu(); menu.selected = true;" :class="{'border-selected-menu': menu.selected}" tile text plain>
										<span class="text-menu">{{ menu.name }}</span>
									</v-btn>
								</v-col>
							</template>
						</v-row>
					</v-container>
				</v-container>
			</v-app-bar>
			<router-view></router-view>

			<v-dialog v-model="$store.state.notification.show" max-width="500" persistent>
				<v-card style="padding: 20px">
					<div style="display: flex; justify-content: space-between">
						<h2 style="margin-bottom: 10px">{{ $store.state.notification.title }}</h2>
						<v-btn icon color="secondary" @click="closeNotification">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
					<template v-if="$store.state.notification.content && $store.state.notification.content.type == 'NOTIFICATION'">
						<div style="color: #c42126; font-size: 14px" :key="item" v-for="item in $store.state.notification.content.fieldErrorList">{{ item }}</div>
						<div style="color: #ed5025; font-size: 14px" :key="item" v-for="item in $store.state.notification.content.errorList">{{ item }}</div>
						<div style="color: #e26026; font-size: 14px" :key="item" v-for="item in $store.state.notification.content.noticeList">{{ item }}</div>
					</template>
					<template v-else>
						<div style="color: #e26026; font-size: 14px">{{ $store.state.notification.content }}</div>
					</template>
					<div style="text-align: center; margin-top: 20px">
						<v-btn color="primary" @click="closeNotification">Close</v-btn>
					</div>
				</v-card>
			</v-dialog>

			<img v-show="$root.loader.value" src="loader.svg" class="loader" />
			
			<!-- DESKTOP -->
			<v-footer>
				<v-container class="my-4">
					<v-row>
						<!-- MOBILE -->
						<v-col v-if="$vuetify.breakpoint.smAndUp" class="d-flex justify-start align-center mb-2">
							<img :src="baseLocation + '/resource/asset/icon/agora.svg'" width="200" height="80" style="cursor: pointer" @click="$router.push('/'); clearSelectedMenu();" />
							<div class="pr-6"></div>
							<div style="font-size: 12px;">Brought to you by</div>
							<img :src="baseLocation + '/resource/asset/icon/alm.png'" width="100" height="60" style="cursor: pointer" @click="$router.push('/'); clearSelectedMenu();" />
						</v-col>
						
						<v-col class="d-flex justify-end align-center mb-2">
							<v-row>
								<v-col cols="6" md="8">
									<!-- menu list -->
									<v-row class="align-center no-gutters" v-for="(menu) in menuDataList" :key="menu.name" >
										<v-col class="d-flex justify-end align-center">
											<v-btn @click="navigateTo(menu.linkTo)" class="hover-underline" tile text plain>
												<span class="menu-text">{{ menu.name }}</span>
											</v-btn>
										</v-col>
									</v-row>
								</v-col>
								<v-divider vertical style="border-width: 0.5px !important; border-color: black !important;"></v-divider>
								<v-col cols="4">
									<v-container fluid fill-height mx-2 pa-0>
										<v-row>
											<!-- MOBILE -->
											<v-col v-if="$vuetify.breakpoint.smAndDown" class="d-flex justify-start align-center">
												<img :src="baseLocation + '/resource/asset/icon/agora.svg'" class="pr-3" width="70" height="50" style="cursor: pointer" @click="$router.push('/'); clearSelectedMenu();" />
												<div style="font-size: 8px;" class="col col-6 ma-0 pa-0">Brought to you by</div>
												<img :src="baseLocation + '/resource/asset/icon/alm.png'" width="40" height="25" style="cursor: pointer" @click="$router.push('/'); clearSelectedMenu();" />
											</v-col>
											<v-col cols="12" class="justify-start pb-0">
												<div class="text-start">
													<v-btn @click="$router.push('/contact')" plain width="150" :right="true" class="orange-hover text-body-2 text-sm-body1 text-md-body-1 text-capitalize" style="border: 1px solid black; border-radius: 0px!important;">
														Need Help?
													</v-btn>
												</div>
											</v-col>
											<v-col class="d-flex justify-start align-center">
												<div>
													<a href="https://www.facebook.com/almonpremise/" style="text-decoration: none;" target="_blank" class="pr-2">
														<v-icon color="black">mdi-facebook</v-icon>
													</a>
													<a href="https://www.instagram.com/almonpremise/" style="text-decoration: none;" target="_blank" >
														<v-icon color="black">mdi-instagram</v-icon>
													</a>
												</div>
											</v-col>
										</v-row>
									</v-container>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-container>
			</v-footer>
			<v-footer style="background-color: #28317E">
				<v-container fluid mx-0 pr-6>
					<div class="d-flex justify-end">
						<a href="https://www.metcash.com/legal/terms-conditions/" style="color: white; text-decoration: none" target="_blank">Terms and Conditions</a>
						<div class="pr-6"></div>
						<a href="https://www.metcash.com/legal/privacy-policy/" style="color: white; text-decoration: none" target="_blank">Privacy and Policy</a>
					</div>
				</v-container>
			</v-footer>
		</v-main>
	</v-app>
</template>

<style scoped>
.border-divider {
  border-top: 1px solid #00000029;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.border-selected-menu {
  border-bottom: 3px solid #F16013;
  width: 100%;
  z-index: 2;
}

.text-menu {
	font-family: 'JosefinSans';
}

</style>

<script>
import axios from "axios";

export default {
	data: function() {
		return {
			baseLocation: axios.defaults.baseURL,
			showChangePassword: false,
			password: null,
			confirmPassword: null,
			keyword: "",
			alert: { show: false, message: "" },
			showDrawer: false,
			isSearching: false,
			menuDataList: [
				{
					name: "OFFERS",
					parent: false,
					linkTo: "offer"
				},
				{
					name: "BRANDS",
					parent: false,
					linkTo: "brand"
				},
				{
					name: "VIDEOS",
					parent: true,
					linkTo: "video",
					childList: [
						{
							name: "Brand Education",
							parent: false,
							linkTo: "brandeducation/list"
						},
						{
							name: "Cocktail Making",
							parent: false,
							linkTo: "cocktailmaking/list"
						},
						{
							name: "Point of Sale",
							parent: false,
							linkTo: "pointofsale/list"
						},
						{
							name: "Training Partners",
							parent: false,
							linkTo: "trainingpartner/list"
						}
					]
				},
				{
					name: "NEWS",
					parent: false,
					linkTo: "news"
				},
				{
					name: "NEW RELEASES",
					parent: false,
					linkTo: "newrelease/list"
				},
				{
					name: "ESSENTIALS",
					parent: true,
					linkTo: "essential",
					childList: [
						{
							name: "Static POS",
							parent: false,
							linkTo: "signage/list"
						},
						{
							name: "Bar Essentials",
							parent: false,
							linkTo: "baressential/list"
						},
						{
							name: "Menu Systems",
							parent: false,
							linkTo: "menusolution/list"
						},
						{
							name: "Tasting Notes",
							parent: false,
							linkTo: "tasting/list"
						}
					]
				},
				{
					name: "EVENTS",
					parent: false,
					linkTo: "event"
				},
				{
					name: "AMBASSADORS",
					parent: false,
					linkTo: "ambassador"
				}
			],
		};
	},
	computed: {
		currentRoute() {
			return this.$route.path;
		}
	},
	methods: {
		navigateTo(linkTo) {
			if (linkTo) {
				let path = "/" + linkTo;

				if (this.$route.path !== path) {
					this.$router.push(path);
				}
			}
		},
		changePassword() {
			axios
				.post("/system/changepassword", {
					password: this.password,
					confirmPassword: this.confirmPassword
				})
				.then(() => {
					[this.alert.type, this.alert.message, this.alert.show] = ["success", "Your password has been changed", true];
					this.$refs.form.reset();
				})
				.catch(() => {
					[this.alert.type, this.alert.message, this.alert.show] = ["error", "Change password failed", true];
				});
		},
		logout() {
			this.$store
				.dispatch("logout")
				.then(() => {
					this.$router.push("/signin");
				})
				.catch(() => {});
		},
		closeNotification() {
			if (this.$store.state.notification.content.noticeList != null && this.$store.state.notification.content.noticeList[0] === "Your session has expired, please sign out and sign in again") {
				this.$store.commit("logout");
				this.$store.commit("hideNotification");
				this.$router.push("/signin");
			} else {
				this.$store.commit("hideNotification");
			}
		},
		clearSelectedMenu() {
			this.menuDataList.forEach((menu) => {
				menu.selected = false;
			});
		},
		search(keyword) {
			this.$router.push('/search/'+keyword);
		}
	}
};
</script>