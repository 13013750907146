<template>
	<div>
		<agora-breadcrumb></agora-breadcrumb>
		<v-container>
			<v-row>
				<v-col class="d-flex justify-space-between align-center">
					<div class="d-flex flex-column" style="position: relative;">
						<h1 class="text-h5 text-sm-h3 my-0 text-uppercase" style="font-family: JosefinSans !important;">Contacts</h1>
						<br />
						<span class="text-uppercase" style="font-family: Source Sans Pro !important; color: black">BUSINESS DEVELOPMENT MANAGERS</span>
						<span style="font-family: Source Sans Pro !important; color: black">PH: 1300 881 458</span>
						<span style="font-family: Source Sans Pro !important; color: black">E: almonpremise@almliquor.com.au</span>
					</div>
				</v-col>
			</v-row>
		</v-container>
		
		<v-container>
			<v-row class="my-2">
				<v-col>
					<v-row>
						<v-col :class="justifyHeaderButton">
							<div class="d-flex fluid col-12 mb-0 pb-0 pl-0" style="border-bottom: 1px solid #9E968D;">
								<v-btn @click="page = 3; filterKey = 'All';" class="text-caption px-2 px-sm-6 text-sm-body-1" :small="smallButton" outlined color="#939393" :style="{fontFamily: $vuetify.breakpoint.mdAndUp ? 'JosefinSans !important':'', borderRadius: 0, border: 0, borderBottom: page == 3 ? '2px solid #F16013':'0px'}">Supplier Contacts</v-btn>
								<v-btn @click="page = 1" class="text-caption px-3 px-sm-6 text-sm-body-1" :small="smallButton" outlined color="#939393" :style="{fontFamily: $vuetify.breakpoint.mdAndUp ? 'JosefinSans !important':'', borderRadius: 0, border: 0, borderBottom: page == 1 ? '2px solid #F16013':'0px'}">ALM Contacts</v-btn>
								<v-btn @click="page = 2" class="text-caption px-2 px-sm-6 text-sm-body-1" :small="smallButton" outlined color="#939393" :style="{fontFamily: $vuetify.breakpoint.mdAndUp ? 'JosefinSans !important':'', borderRadius: 0, border: 0, borderBottom: page == 2 ? '2px solid #F16013':'0px'}">Brand Contacts</v-btn>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<div class="d-flex col col-12 justify-space-between align-center" style="height: 41px; width: 100%; border-bottom: 1px solid #9E968D;">
								<v-icon style="cursor: pointer;">mdi-magnify</v-icon>
								<input v-model="keyword" @blur="search" @keyup.enter="search" type="text" placeholder="Search Term" class="text-caption text-sm-body-1" style="font-family: Source Sans Pro !important; width: 100%; color: #939393; padding: 0 10px; outline: none;" />
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<template v-if="page == 1">
								<div class="hidden-sm-and-down mt-md-6">
									<v-row>
										<v-col>
											<div>
												<h1 class="text-h5 text-sm-h5 my-0 text-uppercase" style="font-family: JosefinSans !important;">Customer Service</h1>
											</div>
										</v-col>
									</v-row>
								</div>
								<v-row>
									<v-col class="col-12 col-md-7">
										<v-simple-table class="no-background">
											<template v-slot:default>
												<tbody>
													<tr v-for="(item, index) in entityList" :key="index">
														<!-- DESKTOP -->
														<template>
															<td class="hidden-sm-and-down">
																<div class="font-weight-black" style="font-family: Source Sans Pro !important;">{{ item.name }}</div>
																<div class="text-caption" style="font-family: Source Sans Pro !important;">{{ item.areaName + ', ' + item.stateName }}</div>
															</td>
															<td class="hidden-sm-and-down">
																<div style="font-family: Source Sans Pro !important;">
																	<v-icon x-small>mdi-email</v-icon>
																	{{ item.email }}
																</div>
																<div style="font-family: Source Sans Pro !important;">
																	<v-icon x-small>mdi-phone</v-icon>
																	{{ item.phone }}
																</div>
															</td>
														</template>
															
														<!-- MOBILE -->
														<div class="hidden-md-and-up">
															<v-col class="mx-0 px-0">
																<div class="font-weight-black">{{ item.name }}</div>
																<div class="text-caption">{{ item.areaName + ', ' + item.stateName }}</div>
																<div class="d-flex justify-space-between">
																	<div>
																		{{ item.email }}
																	</div>
																	<div>
																		{{ item.phone }}
																	</div>
																</div>
																<v-divider></v-divider>
															</v-col>
														</div>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-col>
									<v-col class="hidden-sm-and-down col-12 col-md-5" style="position: relative">
										<radio-svg-map v-model="selectedLocation" @change="filter" @mouseover="pointLocation" @mouseout="unpointLocation" @mousemove="moveOnLocation" :map="Australia" />
										<div :style="tooltipStyle">
											<div class="rounded-pill px-2" style="background-color:#f47a20">
												<v-icon color="agora_primary" small>mdi-map-marker</v-icon>
												<span style="color:white">{{ warehouses.state }}</span>
											</div>
											<div class="text-caption my-2" v-for="(item, index) in warehouses.list" :key="index">
												<span v-if="item.name" class="text-h6 font-weight-bold">{{ item.name }}</span>
												<br v-if="item.name" />
												<span class="text-body-1 font-weight-black">{{ item.address }}</span>
												<br />
												<span v-if="item.customerService" class="font-weight-bold">Customer Service:</span>
												{{ item.customerService }}
												<br v-if="item.customerService" />
												<span v-if="item.email && !['NT', 'SA'].includes(warehouses.state)" class="font-weight-bold">Email:</span>
												{{ !["NT", "SA"].includes(warehouses.state) ? item.email : ""
												}}
												<br v-if="item.email && !['NT', 'SA'].includes(warehouses.state)" />
												<span v-if="item.almOrderEmail" class="font-weight-bold">ALM Orders Email:</span>
												{{ item.almOrderEmail }}
												<br v-if="item.almOrderEmail" />
												<span v-if="item.customerClaimEmail" class="font-weight-bold">Customer Claims Email:</span>
												{{ item.customerClaimEmail }}
												<br v-if="item.customerClaimEmail" />
												<span v-if="item.accountReceivableEmail" class="font-weight-bold">Accounts Receivable Email:</span>
												{{ item.accountReceivableEmail }}
												<br v-if="item.accountReceivableEmail" />
												<span v-if="item.chargethru" class="font-weight-bold">Chargethru:</span>
												{{ item.chargethru }}
												<br v-if="item.chargethru" />
												<span v-if="item.almAccount" class="font-weight-bold">ALM Accounts:</span>
												{{ item.almAccount }}
												<br v-if="item.almAccount" />
												<span v-if="item.claimQueries" class="font-weight-bold">Claim Queries:</span>
												{{ item.claimQueries }}
												<br v-if="item.claimQueries" />
												<span v-if="item.warehouseName" class="font-weight-bold">{{ item.warehouseName }}</span>
												<br v-if="item.warehouseName" />
												<span v-if="item.warehouseService" class="font-weight-bold">Warehouse Service:</span>
												{{ item.warehouseService }}
												<br v-if="item.warehouseService" />
												<span v-if="item.telp" class="font-weight-bold">Tel.</span>
												{{ item.telp }}
												<br v-if="item.telp" />
												<span v-if="item.email && ['NT', 'SA'].includes(warehouses.state)" class="font-weight-bold">Email.</span>
												{{ ["NT", "SA"].includes(warehouses.state) ? item.email : ""
												}}
												<br v-if="item.email && ['NT', 'SA'].includes(warehouses.state)" />
												<v-divider v-if="warehouses.list.length > 1 && index < warehouses.list.length - 1" class="mt-2"></v-divider>
											</div>
										</div>
									</v-col>
								</v-row>
							</template>
							<template v-if="page == 2">
								<br />
								<v-row>
									<v-col class="col-12">
										<div class="hidden-sm-and-down">
											<v-row>
												<v-col class="d-flex justify-space-between">
													<template v-for="(item, index) in alphabets">
														<v-btn @click="(filterKey = item), filter()" v-ripple="false" plain x-small :key="index">
															<span class="agora-text-3" :style="filterKey == item ? 'color:#F16013 !important;' : 'color:#B1B1B1 !important;'" style="font-family: JosefinSans !important;">{{ item }}</span>
														</v-btn>
													</template>
												</v-col>
											</v-row>
										</div>
										<div class="hidden-md-and-up">
											<v-row class="d-flex justify-end mt-4">
												<v-col cols="5" sm="3" class="py-0">
													<v-select v-model="filterKey" :items="alphabets" @change="filter()" dense solo background-color="#f47a20" style="border-radius: 0;">
														<template v-slot:label>
															<span style="color:white">Filter</span>
														</template>
														<template v-slot:item="{ item }">
															<span style="color:black">{{ item }}</span>
														</template>
														<template v-slot:selection="{ item }">
															<span style="color:white">{{ item }}</span>
														</template>
														<template v-slot:append>
															<v-icon color="white">mdi-chevron-down</v-icon>
														</template>
													</v-select>
												</v-col>
											</v-row>
										</div>
										<v-simple-table class="no-background mt-2">
											<template v-slot:default>
												<thead class="hidden-sm-and-down">
													<tr class="font-weight-black" style="font-family: Source Sans Pro !important;">
														<td>BRAND NAME</td>
														<td>CONTACT NAME</td>
														<td>SERVICE STATE & LOCATION</td>
														<td>SUPPLIER</td>
														<td>CONTACT DETAILS</td>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(item, index) in displayList" :key="index">
														<!-- DESKTOP -->
														<template>
															<td class="hidden-sm-and-down" style="font-family: Source Sans Pro !important;">{{ item.brand }}</td>
															<td class="hidden-sm-and-down" style="font-family: Source Sans Pro !important;">{{ item.name }}</td>
															<td class="hidden-sm-and-down" style="font-family: Source Sans Pro !important;">{{ item.state }} {{ item.area }}</td>
															<td class="hidden-sm-and-down" style="font-family: Source Sans Pro !important;">{{ item.company }}</td>
															<td class="hidden-sm-and-down" style="font-family: Source Sans Pro !important;">
																<v-icon v-if="item.email" x-small>mdi-email</v-icon>
																{{ item.email }}
																<br v-if="item.email && item.phone" />
																<v-icon v-if="item.phone" x-small>mdi-phone</v-icon>
																{{ item.phone }}
															</td>
														</template>

														<!-- MOBILE -->
														<div class="hidden-md-and-up">
															<v-row no-gutters class="mt-4 d-flex justify-space-between">
																<v-col cols="6" class="font-weight-black"><div>{{ item.brand }}</div></v-col>
																<v-col cols="5" class="d-flex justify-end align-center text-right"><div>{{ item.company }}</div></v-col>
															</v-row>
															<v-row no-gutters class="d-flex justify-space-between">
																<v-col cols="6"><div>{{ item.name }}</div></v-col>
																<v-col cols="5" class="d-flex justify-end text-right"><div>{{ item.state }} {{ item.area }}</div></v-col>
															</v-row>
															<v-row no-gutters class="d-flex justify-space-between">
																<v-col cols="6"><div>{{ item.email }}</div></v-col>
																<v-col cols="5" class="d-flex justify-end text-right"><div>{{ item.phone }}</div></v-col>
															</v-row>
															<v-divider></v-divider>
														</div>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-col>
								</v-row>
							</template>
							<template v-if="page == 3">
								<br />
								<v-row>
									<v-col class="col-12">
										<div class="hidden-sm-and-down">
											<v-row>
												<v-col class="d-flex justify-space-between">
													<template v-for="(item, index) in alphabets">
														<v-btn @click="(filterKey = item), filter()" v-ripple="false" plain x-small :key="index">
															<span class="agora-text-3" :style="filterKey == item ? 'color:#F16013 !important;' : 'color:#B1B1B1 !important;'" style="font-family: JosefinSans !important;">{{ item }}</span>
														</v-btn>
													</template>
												</v-col>
											</v-row>
										</div>
										<div class="hidden-md-and-up">
											<v-row class="d-flex justify-end mt-4">
												<v-col cols="5" sm="3" class="py-0">
													<v-select v-model="filterKey" :items="alphabets" @change="filter()" dense solo background-color="#f47a20" style="border-radius: 0;">
														<template v-slot:label>
															<span style="color:white">Filter</span>
														</template>
														<template v-slot:item="{ item }">
															<span style="color:black">{{ item }}</span>
														</template>
														<template v-slot:selection="{ item }">
															<span style="color:white">{{ item }}</span>
														</template>
														<template v-slot:append>
															<v-icon color="white">mdi-chevron-down</v-icon>
														</template>
													</v-select>
												</v-col>
											</v-row>
										</div>
										<v-simple-table class="no-background mt-2">
											<template v-slot:default>
												<thead class="hidden-sm-and-down">
													<tr class="font-weight-black" style="font-family: Source Sans Pro !important;">
														<td>SUPPLIER NAME</td>
														<td>SUPPLIER AREA</td>
														<td>SUPPLIER CONTACT NAME</td>
														<td>SUPPLIER CONTACT DETAILS</td>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(item, index) in displayList" :key="index">
														<!-- DESKTOP -->
														<template>
															<td class="hidden-sm-and-down" style="font-family: Source Sans Pro !important;">{{ item.company }}</td>
															<td class="hidden-sm-and-down">
																<div class="font-weight-black" style="font-family: Source Sans Pro !important;">{{ item.state }}</div>
																<div class="text-caption" style="font-family: Source Sans Pro !important;">{{ item.area }}</div>
															</td>
															<td class="hidden-sm-and-down" style="font-family: Source Sans Pro !important;">{{ item.name }}</td>
															<td class="hidden-sm-and-down" style="font-family: Source Sans Pro !important;">
																<div v-if="item.email">
																	<v-icon x-small>mdi-email</v-icon>
																	{{ item.email }}
																</div>
																<div v-if="item.phone">
																	<v-icon x-small>mdi-phone</v-icon>
																	{{ item.phone }}
																</div>
															</td>
														</template>

														<!-- MOBILE -->
														<div class="hidden-md-and-up">
															<v-col class="mx-0 px-0">
																<div class="font-weight-black">{{ item.name }}</div>
																<div class="text-caption">{{ item.company + ' ' + item.area + ', ' + item.state }}</div>
																<div class="d-flex justify-space-between">
																	<div>
																		{{ item.email }}
																	</div>
																	<div>
																		{{ item.phone }}
																	</div>
																</div>
																<v-divider></v-divider>
															</v-col>
														</div>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-col>
								</v-row>
							</template>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			
			<v-pagination v-if="page != 1" v-model="currentPage" :total-visible="$vuetify.breakpoint.smAndUp ? 7:5" :length="Math.ceil(entityList.length / perPage)" color="agora_secondary"></v-pagination>
		</v-container>
	</div>
</template>

<style src="../../public/css/map/index.css">
</style>

<style scoped>
.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
	min-height: 30px;
}
</style>

<script>
import axios from "axios";
import { RadioSvgMap } from "vue-svg-map";
import Australia from "@svg-maps/australia";
import agoraBreadcrumb from "./agoraBreadcrumb.vue";

export default {
	components: {
		RadioSvgMap,
		agoraBreadcrumb
	},
	data() {
		return {
			page: 3,
			entityList: [],
			almContactList: [],
			brandContactList: [],
			supplierContactList: [],
			alphabets: ["All", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
			Australia,
			selectedLocation: null,
			pointedLocation: null,
			tooltipStyle: { display: "none" },
			keyword: null,
			filterKey: "All",
			baseLocation: axios.defaults.baseURL,
			warehouses: [],
			warehouseList: [
				{
					state: "NSW",
					list: [
						{
							address: "71 Huntingwood Drive, Eastern Creek NSW 2766",
							almOrderEmail: "sales.bris@almliquor.com.au",
							customerClaimEmail: "almclaims@almliquor.com.au",
							accountReceivableEmail: "accounts.east@almliquor.com.au",
							chargethru: "almchargethru@almliquor.com.au",
							almAccount: "1300 518 374"
						}
					]
				},
				{
					state: "VIC",
					list: [
						{
							address: "75 Fitzgerald Road, Laverton VIC 3026",
							almOrderEmail: "sales.bris@almliquor.com.au",
							customerClaimEmail: "almclaims@almliquor.com.au",
							accountReceivableEmail: "accounts.east@almliquor.com.au",
							chargethru: "almchargethru@almliquor.com.au",
							almAccount: "1300 518 374"
						}
					]
				},
				{
					state: "SA",
					list: [
						{
							address: "600 Main North Road, Gepps Cross SA 5094",
							customerService: "08 8152 8738",
							almAccount: "1300 518 374",
							warehouseName: "Warehouse Services Department",
							telp: "1800 575 688",
							email: "almservicessa@almliquor.com.au"
						}
					]
				},
				{
					state: "WA",
					list: [
						{
							address: "218 Bannister Road, Canning Vale WA 6155",
							customerService: "08 9455 9055",
							almAccount: "1300 518 374",
							almOrderEmail: "almorderswa@almliquor.com.au",
							customerClaimEmail: "almclaimswa@almliquor.com.au",
							claimQueries: "08 9455 9022",
							warehouseService: "08 9455 9061"
						}
					]
				},
				{
					state: "ACT",
					list: [
						{
							address: "Cnr Nyrang & Mildura Streets, Fyshwick ACT 2609",
							almOrderEmail: "sales.bris@almliquor.com.au",
							customerClaimEmail: "almclaims@almliquor.com.au",
							accountReceivableEmail: "accounts.east@almliquor.com.au",
							chargethru: "almchargethru@almliquor.com.au",
							almAccount: "1300 518 374"
						}
					]
				},
				{
					state: "QLD",
					list: [
						{
							name: "Brisbane",
							address: "111-137 Magnesium Drive, Crestmead QLD 4132",
							almOrderEmail: "sales.bris@almliquor.com.au",
							customerClaimEmail: "almclaims@almliquor.com.au",
							accountReceivableEmail: "accounts.east@almliquor.com.au",
							chargethru: "almchargethru@almliquor.com.au",
							almAccount: "1300 518 374"
						},
						{
							name: "Townsville",
							address: "14 Keane Street, Currajong QLD 4812",
							email: "alm.tville@almliquor.com.au",
							almOrderEmail: "sales.bris@almliquor.com.au",
							customerClaimEmail: "almclaims@almliquor.com.au",
							accountReceivableEmail: "accounts.east@almliquor.com.au",
							chargethru: "almchargethru@almliquor.com.au",
							almAccount: "1300 518 374"
						},
						{
							name: "Cairns",
							address: "284 Spence Street, Bungalow QLD 4870",
							email: "ALM_Cairns@almliquor.com.au",
							almOrderEmail: "sales.bris@almliquor.com.au",
							customerClaimEmail: "almclaims@almliquor.com.au",
							accountReceivableEmail: "accounts.east@almliquor.com.au",
							chargethru: "almchargethru@almliquor.com.au",
							almAccount: "1300 518 374"
						}
					]
				},
				{
					state: "TAS",
					list: [
						{
							name: "Launceston",
							address: "31 Tasman Highway, Waverly Launceston TAS 7250",
							almOrderEmail: "sales.bris@almliquor.com.au",
							customerClaimEmail: "almclaims@almliquor.com.au",
							accountReceivableEmail: "accounts.east@almliquor.com.au",
							chargethru: "almchargethru@almliquor.com.au",
							almAccount: "1300 518 374"
						},
						{
							name: "Hobart",
							address: "17 Pitcairn Street, Glenorchy TAS 7010",
							almOrderEmail: "sales.bris@almliquor.com.au",
							customerClaimEmail: "almclaims@almliquor.com.au",
							accountReceivableEmail: "accounts.east@almliquor.com.au",
							chargethru: "almchargethru@almliquor.com.au",
							almAccount: "1300 518 374"
						}
					]
				},
				{
					state: "NT",
					list: [
						{
							name: "Alice Springs",
							address: "Lot 5827 Dalgety Road, Alice Springs NT 0870",
							customerService: "08 8922 5450",
							almAccount: "1300 518 374",
							warehouseName: "Warehouse Services Department",
							telp: "1800 575 688",
							email: "almservicessa@almliquor.com.au"
						},
						{
							name: "Darwin",
							address: "Lot 5684-5686 O'Sullivan Circuit, Darwin Business Park, Eastern Arm NT 0828",
							customerService: "08 8922 5450",
							almAccount: "1300 518 374",
							warehouseName: "Warehouse Services Department",
							telp: "1800 575 688",
							email: "almservicessa@almliquor.com.au"
						}
					]
				}
			],
			displayList: [],
			perPage: 20,
			currentPage: 3
		};
	},
	watch: {
		page: function() {
			this.keyword = null;
			this.currentPage = 3;
			this.search();
			this.filterKey = "All";
		},
		//re-render list based on the value of `perPage` which indicates how many to show per page
		currentPage: function() {
			this.renderList(this.currentPage);
		}
	},
	computed: {
		justifyHeaderButton() {
			var width = this.$vuetify.breakpoint.width;
			var negativeMargin = "";
			if (width < 350) {
				negativeMargin = 'px-0 ml-n2';
			}

			return this.$vuetify.breakpoint.smAndUp ? 'd-flex justify-left':('d-flex justify-space-between '+negativeMargin)
		},
		smallButton() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return false
			} else if (width >= 700) {
				return false
			} else {
				return true
			}
		}
	},
	mounted() {
		this.listSupplierContact();
		this.listAlmContact();
		this.listBrandContact();
	},
	methods: {
		listSupplierContact() {
			axios.get("/system/public/supplier/contact/list").then(response => {
				this.supplierContactList = response.data;
				this.search();
			});
		},
		listAlmContact() {
			axios.get("/system/public/alm/contact").then(response => {
				this.almContactList = response.data[0].contactDataList;
			});
		},
		listBrandContact() {
			axios.get("/system/public/brand/contact/list").then(response => {
				this.brandContactList = response.data;
			});
		},
		filter() {
			if (this.page == 1) {
				if (this.selectedLocation) {
					let list = Object.assign([], this.almContactList);
					let state = this.selectedLocation.split("-")[0].toUpperCase();
					let result = list.find(location => location.stateName.includes(state));
					if (result != null) {
						this.entityList = result.areaList;
						this.entityList.map(function(el) {
							var o = Object.assign({}, el);
							o.stateName = result.stateName;
							return o;
						});
					} else {
						this.entityList = [];
					}
				}
			} else if (this.page == 2) {
				if (this.filterKey) {
					let list = Object.assign([], this.brandContactList);
					let result = this.filterKey == "All" ? list : list.filter(contact => String(contact.name).toUpperCase().startsWith(this.filterKey.toUpperCase()));
					if (result != null) {
						this.entityList = result;
					} else {
						this.entityList = [];
					}
				}
				this.renderList();
			} else if (this.page == 3) {
				if (this.filterKey) {
					let list = Object.assign([], this.supplierContactList);
					let result = this.filterKey == "All" ? list : list.filter(contact => String(contact.company).toUpperCase().startsWith(this.filterKey.toUpperCase()));
					if (result != null) {
						this.entityList = result;
					} else {
						this.entityList = [];
					}
				}
				this.renderList();
			}
		},
		search() {
			this.selectedLocation = null;
			this.filterKey = "All";
			let list = [];
			if (this.page == 1) {
				if (this.almContactList) {
					for (let i = 0; i < this.almContactList.length; i++) {
						if (this.almContactList[i].areaList) {
							for (let j = 0; j < this.almContactList[i].areaList.length; j++) {
								this.almContactList[i].areaList[j].stateName = this.almContactList[i].stateName;
								list.push(this.almContactList[i].areaList[j]);
							}
						}
					}
				}
			} else if (this.page == 2) {
				list = Object.assign([], this.brandContactList);
			} else if (this.page == 3) {
				list = Object.assign([], this.supplierContactList);
			}
			if (this.keyword) {
				this.entityList = list.filter(contact =>
					JSON.stringify(contact)
						.toUpperCase()
						.includes(this.keyword.toUpperCase())
				);
			} else {
				this.entityList = list;
			}
			this.renderList(this.currentPage = 1);
		},
		getLocationId(node) {
			return node && node.attributes.id.value;
		},
		getLocationName(node) {
			return node && node.attributes.name.value;
		},
		pointLocation(event) {
			this.warehouses = this.warehouseList.find(x =>
				x.state.includes(
					this.getLocationId(event.target)
						.split("-")[0]
						.toUpperCase()
				)
			);
			this.pointedLocation = this.getLocationName(event.target);
		},
		unpointLocation() {
			this.pointedLocation = null;
			this.tooltipStyle = { display: "none" };
		},
		moveOnLocation(event) {
			this.tooltipStyle = {
				position: "fixed",
				display: "block",
				top: `${event.clientY + 10}px`,
				left: `${event.clientX + 10}px`,
				backgroundColor:"white",
				padding: "10px",
				zIndex: 5
			};
		},
		renderList(pageNumber = 1) {
			this.displayList = [];

			if (this.entityList.length) {
				if (pageNumber == 1) {
					let start = pageNumber - 1;
					let end = pageNumber * this.perPage;
					this.displayList = this.entityList.slice(start, end);
				} else {
					let start = pageNumber * this.perPage - this.perPage;
					let end = pageNumber * this.perPage;
					this.displayList = this.entityList.slice(start, end);
				}
			}
		}
	}
};
</script>