<template>
	<div>
		<agora-breadcrumb></agora-breadcrumb>

		<v-container>
			<h1 class="text-h5 text-sm-h3 my-0" style="font-family: JosefinSans !important;">INDUSTRY EVENTS</h1>
			<br />
			<div class="text-body-1 text-sm-h6 mb-md-12" style="font-family: Source Sans Pro !important;">Interested in masterclasses, tastings or other events held by your favourite brands? Click here to see what's on near you and connect with the suppliers to secure you and your team tickets.</div>
		</v-container>

		<!-- Search -->
		<v-container class="mt-0">
			<div class="d-flex justify-space-between flex-wrap mx-0">
				<div class="d-flex col col-12 justify-start align-center" style="height: 41px; width: 300px; border-bottom: 2px solid black;">
					<v-icon style="cursor: pointer;">mdi-magnify</v-icon>
					<input v-model="keyword" @keyup.enter="getDateEventSearch()" class="custom-placeholder-color-black" type="text" placeholder="Search events" style="font-family: Source Sans Pro !important; width: 98%; font-size: 16px; padding: 0 10px;" />
				</div>
			</div>
		</v-container>

		<v-container>
			<!-- DESKTOP -->
			<div class="hidden-sm-and-down">
				<div class="d-flex justify-space-between">
					<div class="d-flex justify-flex-start" style="width: 100%">
						<div v-if="type == 'day'" class="d-flex justify-space-between align-center">
							<v-btn text fab @click="prevDay">
								<v-icon color="#000000" size="40">mdi-chevron-left</v-icon>
							</v-btn>
							<span class="text-h5 mt-1" style="font-family: JosefinSans !important; text-transform: uppercase;">{{ getOrdinalDate(currentDate) }}</span>
							<v-btn text fab @click="nextDay">
								<v-icon color="#000000" size="40">mdi-chevron-right</v-icon>
							</v-btn>
						</div>

						<div v-if="type == 'week' && currentWeek" class="d-flex justify-space-between align-center">
							<v-btn text fab @click="prevWeek">
								<v-icon color="#000000" size="40">mdi-chevron-left</v-icon>
							</v-btn>
							<span class="text-h5 mt-1" style="font-family: JosefinSans !important; text-transform: uppercase;">{{ currentWeek.range }}</span>
							<v-btn text fab @click="nextWeek">
								<v-icon color="#000000" size="40">mdi-chevron-right</v-icon>
							</v-btn>
						</div>

						<div v-if="type == 'month'" class="d-flex justify-space-between align-center">
							<v-btn text fab @click="prev">
								<v-icon color="#000000" size="40">mdi-chevron-left</v-icon>
							</v-btn>
							<span class="text-h5 mt-1" style="font-family: JosefinSans !important; text-transform: uppercase;" v-if="$refs.calendar">{{ $refs.calendar.title }}</span>
							<v-btn text fab @click="next">
								<v-icon color="#000000" size="40">mdi-chevron-right</v-icon>
							</v-btn>
						</div>
					</div>

					<div class="d-flex align-center">
						<div class="d-flex justify-space-around align-center" style="white-space: nowrap;">
							<div class="d-flex col col-12 justify-end pr-0">
								<table>
									<tr>
										<td style="padding: 0 5px;">
											<v-select v-model="tag" :items="tagList" @change="filterLocation(tag)" label="Sort by tag" outlined dense hide-details="true" background-color="transparent" style="font-family: Source Sans Pro !important; font-size: 15px; letter-spacing: 0px; border-radius: 0;"></v-select>
										</td>
										<td style="padding: 0 5px;">
											<v-select v-model="type" :items="dateType" @change="events = eventsCloneFromApi; changeView(type)" label="Sort by date" class="text-capitalize" outlined dense hide-details="true" background-color="transparent" style="font-family: Source Sans Pro !important; font-size: 15px; letter-spacing: 0px; border-radius: 0;"></v-select>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- MOBILE -->
			<div class="hidden-md-and-up">
				<v-row class="d-flex justify-space-between align-center mt-0 mt-sm-3 mb-3 mb-sm-6">
					<v-col v-if="type == 'day'" cols="12" sm="12">
						<v-row class="d-flex justify-start align-center">
							<div>
								<v-btn small text fab @click="prevDay">
									<v-icon color="#6f7175" size="50">mdi-chevron-left</v-icon>
								</v-btn>
							</div>
							<div>
								<div class="text-center">
									<span v-if="currentDate" class="text-body-1 text-sm-h4 font-weight-black" style="font-family:D-DINCondensed-Bold; text-transform: uppercase;">{{ getOrdinalDate(currentDate) }}</span>
								</div>
							</div>
							<div>
								<v-btn small text fab @click="nextDay">
									<v-icon color="#6f7175" size="50">mdi-chevron-right</v-icon>
								</v-btn>
							</div>
						</v-row>
					</v-col>
				</v-row>
			</div>
			
			<!-- Mobile Day View -->
			<div :class="type == 'day' ? '' : 'd-none'" class="hidden-md-and-up">
				<v-card v-for="(event, index) in events" :key="index" style="margin-top: 20px">
					<v-row @click="navigateToDetail(event.id)" style="cursor: pointer;">
						<v-col cols="4" sm="3" class="ml-2">
							<v-card tile height="100%" width="100%" color="transparent" elevation="0" style="text-align: center">
								<v-img eager aspect-ratio="1" :src="$root.asset(event.imagename, 'medium')"></v-img>
							</v-card>
						</v-col>
						<v-col cols="7" sm="8">
							<v-row class="mt-0 mt-sm-1 d-flex justify-start no-gutter">
								<h1 class="text-h6 text-sm-h4 font-weight-black" style="font-family:D-DINCondensed-Bold; text-transform: uppercase;">{{ event.name }}</h1>
							</v-row>
							<v-row class="mt-2 d-flex justify-start">
								<span class="text-body-2 text-sm-h5" style="color:#6f7175; text-transform: uppercase;">{{ getDayAndMonth(event.start) }} {{ event.time }}</span>
							</v-row>
							<v-row class="mt-2 d-flex justify-start">
								<span class="text-caption text-sm-body-1">{{ event.address }}</span>
							</v-row>
							<v-row class="mt-3 d-flex justify-start">
								<span class="text-caption text-sm-body-1">{{ $root.ellipsify(event.description, ($vuetify.breakpoint.xsOnly ? 37:60)) }}</span>
							</v-row>
						</v-col>
					</v-row>
				</v-card>
			</div>

			<!-- Desktop Day View -->
			<div :class="type == 'day' ? '' : 'd-none'" class="hidden-sm-and-down">
				<v-card max-height="135" class="mb-4" v-for="(event, index) in events" :key="index" @click="navigateToDetail(event.id)" style="cursor: pointer;">
					<div>
						<v-container>
							<v-row>
								<v-col cols="2" align-self="center">
									<span class="text-body-1 text-capitalize" style="font-family: Source Sans Pro !important;">{{ getDayAndMonth(event.start) }} {{ event.time }}</span>
								</v-col>
								<v-col cols="2" align-self="center">
									<v-img eager max-height="105" :src="$root.asset(event.imagename, 'medium')"></v-img>
								</v-col>
								<v-col cols="6" align-self="center">
									<span class="row pl-4 text-h5 text-uppercase" style="font-family: JosefinSans !important;">{{ $root.ellipsify(event.name, 30) }}</span>
									<span class="row pl-4 text-body-1 text-capitalize my-2 mb-1" style="font-family: Source Sans Pro !important;">{{ $root.ellipsify(event.address, 30) }}</span>
									<span class="row pl-4 text-body-1 text-capitalize" style="font-family: Source Sans Pro !important;">{{ $root.ellipsify(event.description, 30) }}</span>
								</v-col>
								<v-col cols="2" align-self="center" justify-end class="text-right">
									<v-btn
										class="orange-hover text-body-1 text-sm-body-1 px-12 py-0 text-capitalize"
										outlined
										background-color="transparent"
										style="color:black; border-radius: 0; border: 1px solid black;"
										@click="navigateToDetail(event.id)"
									>View</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</div>
				</v-card>
			</div>

			<!-- Week View -->
			<div :class="type == 'week' ? '' : 'd-none'">
				<span v-for="date in weekEvents" :key="date.id">
					<v-card max-height="135" class="mb-4" v-for="event in date" :key="event.id" @click="navigateToDetail(event.id)" style="cursor: pointer;">
						<div>
							<v-container>
								<v-row>
									<v-col cols="2" align-self="center">
										<span class="text-body-1 text-capitalize" style="font-family: Source Sans Pro !important;">{{ getDayAndMonth(date[0].start) }}  {{ event.time }}</span>
									</v-col>
									<v-col cols="2" align-self="center">
										<v-img eager max-height="105" :src="$root.asset(event.imagename, 'medium')"></v-img>
									</v-col>
									<v-col cols="6" align-self="center">
										<span class="row pl-4 text-h5 text-uppercase" style="font-family: JosefinSans !important;">{{ $root.ellipsify(event.name, 30) }}</span>
										<span class="row pl-4 text-body-1 text-capitalize my-2 mb-1" style="font-family: Source Sans Pro !important;">{{ $root.ellipsify(event.address, 30) }}</span>
										<span class="row pl-4 mt-n1 text-body-1 text-capitalize" style="font-family: Source Sans Pro !important;">{{ $root.ellipsify(event.description, 30) }}</span>
									</v-col>
									<v-col cols="2" align-self="center" justify-end class="text-right">
										<v-btn
											class="orange-hover text-body-1 text-sm-body-1 px-12 py-0 text-capitalize"
											outlined
											background-color="transparent"
											style="color:black; border-radius: 0; border: 1px solid black;"
											@click="navigateToDetail(event.id)"
										>View</v-btn>
									</v-col>
								</v-row>
							</v-container>
						</div>
					</v-card>
				</span>
			</div>

			<!-- Month view -->
			<div :class="type == 'month' ? '' : 'd-none'">
				<v-row class="fill-height">
					<v-col>
						<v-sheet height="800">
							<v-calendar ref="calendar" v-model="focus" :events="events" event-color="#D8D8DA" :type="type" @mousemove:event="showEvent" @click:more="viewDay" @click:date="viewDay">
								<template v-slot:event="{ event }">
									<div class="d-flex align-center">
										<v-icon size="14" color="#F47A20" class="mr-2 ml-2">mdi-checkbox-blank-circle</v-icon>
										<div class="text-caption" style="font-family: 'D-DIN' !important; color: black"> {{ event.name }} </div>
									</div>
								</template>
							</v-calendar>
							<v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-y>
								<v-card @click="navigateToDetail(selectedEvent.id)" color="#6f7175 lighten-4" max-width="250px" flat>
									<v-card-text>
										<span style="font-family:D-DINCondensed-Bold">{{ getDayAndMonth(selectedEvent.start) }}</span>
										<br />
										<span style="font-family:D-DINCondensed-Bold">{{ selectedEvent.time }}</span>
										<br />
										<span>{{ selectedEvent.address }}</span>
										<br />
										<span style="color:#6c4b9e" v-html="selectedEvent.description"></span>
									</v-card-text>
								</v-card>
							</v-menu>
						</v-sheet>
					</v-col>
				</v-row>
			</div>
			<br />
		</v-container>
	</div>
</template>

<style scoped>
.brand-card {
	margin-bottom: 30px;
	margin-right: 20px;
	width: 150px;
}
</style>

<style>
.v-calendar .v-event {
	border-radius: 10px;
}
div.v-calendar-weekly__head-weekday {
	font-family:"D-DIN";
	font-size:20px;
}
div.v-calendar-weekly__day-label .v-btn__content {
	font-family:"D-DIN";
	font-size:18px;
}
div.v-event {
	font-family:"D-DIn";
	font-size:18px !important;
	margin-top: 5px;
}
.custom-select > .v-text-field--rounded > .v-input__control > .v-input__slot {
    padding-left: 6px;
	padding-right: 1px;
}
</style>

<script>
import axios from "axios";
import agoraBreadcrumb from "./agoraBreadcrumb.vue";

export default {
	components: {
		agoraBreadcrumb
	},
	data: function() {
		return {
			baseLocation: axios.defaults.baseURL,
			selected: [],
			favorite: false,
			itemSort: ["A-Z", "Z-A", "None"],
			itemResult: ["10", "20", "30", "40", "50"],
			type: "month",
			dateType: ['day', 'week', 'month'],
			events: [],
			allEvent: [],
			focus: "",
			selectedEvent: {},
			selectedElement: null,
			selectedOpen: false,
			tag: "NATIONAL",
			tagList: ["NATIONAL", "ACT", "NSW", "QLD", "VIC", "SA", "WA", "TAS", "NT"],
			dateList: [],
			currentDate: null,
			currentWeek: {},
			weeks: [],
			allweeks: [],
			weekEvents: [],
			currentWeekYear: null,
			recentlyAdd: [],
			venueImage: { imageNamesMap:{ heroImage:"" }},
			keyword: "",
			eventsCloneFromApi: [],
		};
	},
	watch: {
		'$vuetify.breakpoint.width': function (){
			this.setDisplay();
		}
	},
	created: function() {
		this.setDisplay();
	},
	mounted: function() {
		this.getDateEvent();
		this.findVenueImage();
	},
	methods: {
		getEventCurrentDate() {
			//Today's event
			let eventList = Object.assign([], this.allEvent);
			var currentDateSplit = this.currentDate.split('-');
			let currentDate = new Date(currentDateSplit[0], currentDateSplit[1] - 1, currentDateSplit[2]);
			let date = currentDate.getDate();
			let month = currentDate.getMonth() + 1;
			if (date < 10) {
				date = "0" + date;
			}
			if (month < 10) {
				month = "0" + month;
			}
			currentDate = currentDate.getFullYear() + "-" + month + "-" + date;
			this.events = eventList.filter(x => x.start == currentDate);
		},
		async getDateEvent() {
			await axios.get("/system/public/event/list").then(response => {
				this.allEvent = response.data;
				this.events = Object.assign([], this.allEvent);
				this.eventsCloneFromApi = Object.assign([], this.allEvent);
				this.recentlyAdd = this.allEvent.slice(0, 4);

				//week List
				this.getWeeksInMonth();
				this.currentWeekYear = new Date().getFullYear();
			});

			this.setDisplay();
		},
		async getDateEventSearch() {
			
			await axios.post("/system/public/event/list", {
				keyword: this.keyword,
			}).then(response => {
				this.allEvent = response.data;
				this.events = Object.assign([], this.allEvent);
				this.recentlyAdd = this.allEvent.slice(0, 4);

				//week List
				this.getWeeksInMonth();
			});

			this.setDisplaySearch();
		},
		async changeView(type) {
			this.type = type;
			this.events = Object.assign([], this.allEvent); //for filtering
			if (this.type == "week") {
				this.getWeekEvent();
			} else if (this.type == "day") {
				var today = new Date();
				var month = today.getMonth() + 1; //because month start from 0
				this.currentDate = today.getFullYear() + "-" + month + "-" + today.getDate();
				this.getEventCurrentDate();
			}
		},
		getWeekEvent() {
			let dateList = [];
			let eventList = Object.assign([], this.allEvent);
			let currentWeekDates = this.currentWeek.dates;
			for (let i = 0; i < currentWeekDates.length; i++) {
				let month = this.currentWeek.month + 1;
				let currentDate = currentWeekDates[i];
				if (currentDate < 10) {
					currentDate = "0" + currentDate;
				}
				let date = this.currentWeek.year + "-0" + month + "-" + currentDate;
				dateList.push(date);
			}
			this.events = eventList.filter(x => dateList.includes(x.start));
			this.weekEvents = this.events.reduce(function(r, a) {
				r[a.start] = r[a.start] || [];
				r[a.start].push(a);
				return r;
			}, Object.create(null));
		},
		prevDay() {
			//let prevIndex = this.dateList.indexOf(this.currentDate) - 1;
			//this.currentDate = this.dateList[prevIndex];
			var currentDateSplit = this.currentDate.split('-');
			let current = new Date(currentDateSplit[0], currentDateSplit[1] - 1, currentDateSplit[2]);
			var yesterday = new Date(current.getTime());
			yesterday.setDate(current.getDate() - 1);
			var monthBefore = yesterday.getMonth() + 1;
			this.currentDate = yesterday.getFullYear() + "-" + monthBefore + "-" + yesterday.getDate();
			this.getEventCurrentDate();
		},
		nextDay() {
			//let nextIndex = this.dateList.indexOf(this.currentDate) + 1;
			//this.currentDate = this.dateList[nextIndex];
			var currentDateSplit = this.currentDate.split('-');
			let current = new Date(currentDateSplit[0], currentDateSplit[1] - 1, currentDateSplit[2]);
			var yesterday = new Date(current.getTime());
			yesterday.setDate(current.getDate() + 1);
			var monthBefore = yesterday.getMonth() + 1;
			this.currentDate = yesterday.getFullYear() + "-" + monthBefore + "-" + yesterday.getDate();
			this.getEventCurrentDate();
		},
		prevWeek() {
			try {
				let prevIndex = this.weeks.indexOf(this.currentWeek) - 1;
				this.currentWeek = this.weeks[prevIndex];
				this.getWeekEvent();
			} catch(e) {
				this.currentWeekYear = this.currentWeekYear-1;
				// to the previous year
				this.getWeeksInMonth(new Date(this.currentWeekYear, 11, 31));
				let prevIndex = this.weeks.indexOf(this.currentWeek) - 1;
				this.currentWeek = this.weeks[prevIndex];
				this.getWeekEvent();
			}
		},
		nextWeek() {
			try {
				let nextIndex = this.weeks.indexOf(this.currentWeek) + 1;
				this.currentWeek = this.weeks[nextIndex];
				this.getWeekEvent();
			} catch (e) {
				this.currentWeekYear = this.currentWeekYear+1;
				// to the next year
				this.getWeeksInMonth(new Date(this.currentWeekYear, 0, 1));
				let nextIndex = this.weeks.indexOf(this.currentWeek) + 1;
				this.currentWeek = this.weeks[nextIndex];
				this.getWeekEvent();
			}
		},
		viewDay({ date }) {
			this.focus = date;
			this.type = "day";
			this.currentDate = date;
			this.getEventCurrentDate();
		},
		setToday() {
			this.focus = "";
		},
		prev() {
			this.$refs.calendar.prev();
		},
		next() {
			this.$refs.calendar.next();
		},
		showEvent({ nativeEvent, event }) {
			const open = () => {
				this.selectedEvent = event;
				this.selectedElement = nativeEvent.target;
				setTimeout(() => {
					this.selectedOpen = true;
				}, 10);
			};

			if (this.selectedOpen) {
				this.selectedOpen = false;
				setTimeout(open, 10);
			} else {
				open();
			}

			nativeEvent.stopPropagation();
		},
		navigateToDetail(eventId) {
			const path = "/event/detail/" + eventId;

			if (this.$route.path !== path) {
				this.$router.push(path);
			}
		},
		//note: month is 0 based, just like Dates in js
		getWeeksInMonth(date) {
			var today = new Date();
			try {

				if (date && date.getFullYear()) {
					today = date;
				}
			} catch (e) {
				// 
				console.log("Errorrr")
			}
			//current week
			var currentMonth = today.getMonth();
			var currentYear = today.getFullYear();
			var currentDate = today.getDate();

			for (let month = 0; month <= 11; month++) {
				let weeks = [],
					firstDate = new Date(currentYear, month, 1),
					lastDate = new Date(currentYear, month + 1, 0),
					numDays = lastDate.getDate();

				let dayOfWeekCounter = firstDate.getDay();

				for (let date = 1; date <= numDays; date++) {
					if (dayOfWeekCounter === 0 || weeks.length === 0) {
						weeks.push([]);
					}
					weeks[weeks.length - 1].push(date);
					dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
				}

				var monthName = this.getMonthName(month);

				weeks = weeks
					.filter(w => !!w.length)
					.map(w => ({
						start: w[0],
						end: w[w.length - 1],
						dates: w,
						range: w[0] + "-" + w[w.length - 1] + " " + monthName + " " + currentYear,
						year: currentYear,
						month: month
					}));
				for (let i = 0; i < weeks.length; i++) {
					this.weeks.push(weeks[i]);
				}
			}
			let currentWeek = this.weeks.filter(x => x.month == currentMonth && x.year == currentYear && x.dates.includes(currentDate));
			if (currentWeek) {
				this.currentWeek = currentWeek[0];
			}
		},
		getMonthName(month) {
			var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			return monthNames[month];
		},
		getDayOfWeek(dayOfWeek) {
			return isNaN(dayOfWeek) ? null : ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][dayOfWeek];
		},
		getDayAndMonth(start) {
			var startDate = new Date(start);
			var day = this.getDayOfWeek(startDate.getDay());
			var date = startDate.getDate();
			var monthName = this.getMonthName(startDate.getMonth());
			return day + " " + date + " " + monthName;
		},
		getOrdinalDate(currentDate) {
			var currentDateSplit = currentDate.split('-');
			var startDate = new Date(currentDateSplit[0], currentDateSplit[1] - 1, currentDateSplit[2]);
			var day = this.getDayOfWeek(startDate.getDay());
			var date = startDate.getDate();
			var monthName = this.getMonthName(startDate.getMonth());
			var ordinal;
			switch (date % 10) {
				case 1:
					ordinal = "st";
					break;
				case 2:
					ordinal = "nd";
					break;
				case 3:
					ordinal = "rd";
					break;
				default:
					ordinal = "th";
			}
			return day + " " + date + ordinal + " " + monthName;
		},
		filterLocation(addr) {
			let eventList = Object.assign([], this.allEvent);
			if (addr === "NATIONAL") {
				this.events = eventList;
			} else {
				this.events = eventList.filter(x => x.address.split(" ").find(y => y.toUpperCase() === addr));
			}
		},
		setDisplay() {
			var width = this.$vuetify.breakpoint.width;
			if (width > 960) {
				this.changeView('month');
			} else {
				this.changeView('day');
				this.filterLocation("NATIONAL");
			}
		},
		setDisplaySearch() {
			this.type = "day";
			this.tag = "NATIONAL";
			this.changeView(this.type);
			this.filterLocation(this.tag);
		},
		findVenueImage() {
			let vm = this;
			axios.get("/system/public/venueImage?page=Events")
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.venueImage = response.data;
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>


