<template>
	<div>
		<v-carousel :height="heightCarousel" style="position: relative;" :hide-delimiters="$vuetify.breakpoint.smAndDown" hide-delimiter-background light :show-arrows="false" cycle>
			<v-carousel-item v-for="(item, index) in advertisings" :key="index">
				<a @click="sendBannerAnalytic(item)">
					<video v-if="$root.isVideo(item.imageName)" :height="heightCarousel" :src="$root.asset(item.imageName, null)" preload="auto" autoplay="autoplay" muted playsinline disablepictureinpicture></video>
					<img v-else :height="heightCarousel" :src="$root.asset(item.imageName, null)" style='width: 100%; will-change: transform; image-rendering: -webkit-optimize-contrast;' />
				</a>
			</v-carousel-item>
		</v-carousel>
		
		<br />

		<template>
			<!-- MOBILE -->
			<v-container class="hidden-md-and-up d-flex d-sm-none">
				<v-row>
					<v-col cols="12">
						<agora-lead-tile
							:image="$root.asset(venueImage.memberOffers, null)"
							title="MEMBER OFFERS"
							description="As an ALM On Premise customer, you have access to these exclusive members offers. Click here to sign in and view all offers, then build your shopping cart which syncs to the customer ordering portal."
							link="/offer"
						></agora-lead-tile>
					</v-col>
				</v-row>
			</v-container>
			<v-container class="hidden-md-and-up d-none d-sm-flex d-md-none">
				<v-row>
					<v-col cols="6">
						<agora-lead-tile
							:image="$root.asset(venueImage.memberOffers, null)"
							title="MEMBER OFFERS"
							description="As an ALM On Premise customer, you have access to these exclusive members offers. Click here to sign in and view all offers, then build your shopping cart which syncs to the customer ordering portal."
							link="/offer"
						></agora-lead-tile>
					</v-col>
					<v-col cols="6">
						<agora-lead-tile
							:image="$root.asset(venueImage.newReleases, null)"
							title="NEW RELEASES"
							description="Stay up to date with new products launching in Australia."
							link="/newrelease/list"
						></agora-lead-tile>
					</v-col>
				</v-row>
			</v-container>
			<!-- DESKTOP -->
			<v-container class="hidden-sm-and-down">
				<v-row>
					<v-col cols="12" md="6">
						<agora-lead-tile
							:image="$root.asset(venueImage.memberOffers, null)"
							title="MEMBER OFFERS"
							description="As an ALM On Premise customer, you have access to these exclusive members offers. Click here to sign in and view all offers, then build your shopping cart which syncs to the customer ordering portal."
							link="/offer"
						></agora-lead-tile>
					</v-col>
					<v-col cols="12" md="6">
						<agora-lead-tile
							:image="$root.asset(venueImage.newReleases, null)"
							title="NEW RELEASES"
							description="Stay up to date with new products launching in Australia."
							link="/newrelease/list"
						></agora-lead-tile>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="6">
						<agora-lead-tile
							:image="$root.asset(venueImage.barEssentials, null)"
							title="BAR ESSENTIALS"
							description="View all branded merchandise available for your venue."
							link="/essential"
						></agora-lead-tile>
					</v-col>
					<v-col cols="12" md="6">
						<agora-lead-tile
							:image="$root.asset(venueImage.supplierContacts, null)"
							title="SUPPLIER CONTACTS"
							description="As an ALM On Premise customer, you have access to these exclusive members offers. Click here to sign in and view all offers, then build your shopping cart which syncs to the customer ordering portal."
							link="/contact"
						></agora-lead-tile>
					</v-col>
				</v-row>
			</v-container>
			<!-- DESKTOP -->
			<v-container class="hidden-sm-and-down">
				<v-row class="mb-6 mb-md-12">
					<v-col cols="12" md="3">
						<agora-secondary-tile
							:image="$root.asset(venueImage.news, null)"
							title="LATEST NEWS"
							description="Stay up to date with new products launching in Australia."
							link="/news"
							size="2"
						></agora-secondary-tile>
					</v-col>
					<v-col cols="12" md="3">
						<agora-secondary-tile
							:image="$root.asset(venueImage.video, null)"
							title="TRAINING VIDEOS"
							description="View all branded merchandise available for your venue."
							link="/video"
							size="2">
						</agora-secondary-tile>
					</v-col>
					<v-col cols="12" md="3">
						<agora-secondary-tile
							:image="$root.asset(venueImage.events, null)"
							title="INDUSTRY EVENTS"
							description="Check out industry events located near you."
							link="/event"
						></agora-secondary-tile>
					</v-col>
					<v-col cols="12" md="3">
						<agora-secondary-tile
							:image="$root.asset(venueImage.brandAmbassadors, null)"
							title="BRAND AMBASSADORS"
							description="Connect with ambassadors from leading brands."
							link="/ambassador"
						></agora-secondary-tile>
					</v-col>
				</v-row>
			</v-container>

			<v-container>
				<v-row>
					<v-col cols="12" md="6" class="text-left">
						<div class="text-h5 text-sm-h5 text-md-h4 text-capitalize" style="border-radius:0px!important;" >
							Explore our brands
						</div>
					</v-col>
					<v-col cols="6" class="text-right hidden-sm-and-down">
						<v-btn @click="$router.push('/brand')" plain :right="true" class="orange-hover text-body-2 text-sm-body1 text-md-body-1 text-capitalize" style="border: 1px solid black; border-radius:0px!important;" >
							Explore all brands
						</v-btn>
					</v-col>
				</v-row>
				<div class="my-2 my-md-6" style="border-bottom: 3px solid #F16013;"></div>
				<v-col cols="6" class="hidden-md-and-up pl-0">
					<v-btn @click="$router.push('/brand')" plain :right="true" class="orange-hover text-body-2 text-sm-body1 text-md-body-1 text-capitalize" style="border: 1px solid black; border-radius:0px!important;" >
						Explore all brands
					</v-btn>
				</v-col>
				<v-carousel class="mb-0 mb-sm-6 mb-md-6" :height="heightExploreBrandCarousel" hide-delimiter-background light :show-arrows="false" hide-delimiters id="brand">
					<v-carousel-item>
						<v-row class="mx-0 px-0">
							<v-col cols="4" md="3" :class="$vuetify.breakpoint.mdAndUp ? 'px-3':'px-1'" v-for="e in displayList" :key="e.id" >
								<v-card @click="$router.push('/brand/detail/' + e.id)" :height="heightBrandCard" outlined>
									<v-img :src="$root.asset(e.imageName, 'medium')" contain eager style='width: 100%; height: 100%; will-change: transform; image-rendering: -webkit-optimize-contrast;'></v-img>
								</v-card>
							</v-col>
						</v-row>
						<v-pagination color="agora_secondary" style="margin: 10px 0 20px 0" v-model="pagination.page" :length="pagination.total" :total-visible="pagination.visible" @input="onPageChange()"></v-pagination>
					</v-carousel-item>
				</v-carousel>
			</v-container>
		</template>

		<!-- show go to profile-->
		<v-dialog v-model="showDialog" v-if="showDialog" max-width="413px" max-height="370px" tile>
			<v-card color="black">
				<v-card-title>
					<v-spacer></v-spacer>
					<v-icon @click="showDialog = false" color="white">mdi-close</v-icon>
				</v-card-title>

				<v-card-text style="font-size: 19px; text-align: center; color: white !important">
					<p style="font-family: D-DINCondensed-Bold">Welcome to</p>
					<img src="logo.png" width="168px" height="66px" />
					<p>Please remember to update your profile and ensure your contact email is correct.</p>
					<p>Your email address is extremely important as it is where your password will be sent in the event of the existing on being forgotten.</p>
					<v-btn rounded width="347px" height="40px" color="#6f7175" dense class="white--text" style="font-size: 30px">GO TO PROFILE</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<style>
.center {
	display: block;
	margin: 60px auto 30px;
}
#brand .v-carousel__controls {
	align-items: center;
	bottom: 0;
	display: flex;
	height: 50px;
	justify-content: flex-end;
	list-style-type: none;
	position: absolute;
	width: 100%;
	z-index: 1;
}
.v-carousel__controls__item {
	color: #f47a20 !important;
}
.text-overlay {
  position: absolute;
  top: 32%;
  left: 8%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem; /* Adjust padding as needed */
  color: white; /* Text color */
}
.overlay-heading {
  font-size: 3.5rem;
  font-weight: bold;
}
</style>

<script>
import axios from "axios";
import agoraLeadTile from "./agoraLeadTile";
import agoraSecondaryTile from "./agoraSecondaryTile";

export default {
	components: {
		agoraLeadTile,
		agoraSecondaryTile
	},
	computed: {
		heightCarousel() {
			var width = this.$vuetify.breakpoint.width
			if (width > 700) {
				return 'auto'
			} else {
				return '205'
			}
		},
		heightBrandCard() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '210'
			} else if (width > 700) {
				return '185'
			} else {
				return '100'
			}
		},
		heightExploreBrandCarousel() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '550'
			} else if (width >= 700) {
				return '450'
			} else {
				return '300'
			}
		},
	},
	data: function() {
		return {
			showDialog: false,
			baseLocation: axios.defaults.baseURL,
			advertisings: [],
			brands: [],
			displayList: [],
			pagination: {
				page: 1,
				total: 0,
				perPage: this.perPage(),
				visible: 5
			},
			pageList: [],
			venueImage: { imageNamesMap:{ memberOffers:"", video: "", news: "" }},
			company: {}
		};
	},
	mounted: async function() {
		this.listBrand();
		this.listAdvertising();
		if (this.$store.state.principal["companyId"]) {
			this.findMeCompany();
		}
	},
	created: async function() {
		await this.findVenueImageHomePage();
	},
	methods: {
		listAdvertising() {
			axios.get("/system/public/banner/list").then(response => {
				this.advertisings = response.data;
			});
		},
		listBrand() {
			axios
				.get("/system/public/brand/list")
				.then(response => {
					this.brands = response.data;
					this.displayList = this.brands;
					this.onPageChange();
				})
				.catch(() => {});
		},
		navigateTo(linkTo) {
			const path = "/" + linkTo;

			if (this.$route.path !== path) {
				this.$router.push(path);
			}
		},
		onPageChange() {
			this.pagination.total = Math.ceil(this.brands.length / this.pagination.perPage);
			let startIndex = (this.pagination.page - 1) * this.pagination.perPage;
			let endIndex = startIndex + this.pagination.perPage;
			this.displayList = this.brands.slice(startIndex, endIndex);
		},
		sendBannerAnalytic(item) {
			// offer click by non login user is not sent to GA
			if (!this.$root.isBlank(item["link"]) && item["link"].includes("/offer") && !this.$store.getters.isAuthenticated) {
				location.href = item["link"];
				return;
			}

			try {
				let userId = this.$root.isBlank(this.$store.state.principal["id"]) == false ? this.$store.state.principal["id"] : "";
				let userName = this.$root.isBlank(this.$store.state.principal["name"]) == false ? this.$store.state.principal["name"] : "";
				let email = this.$root.isBlank(this.$store.state.principal["email"]) == false ? this.$store.state.principal["email"] : "";
				
				let companyId = this.$root.isBlank(this.$store.state.principal['companyId']) == false ? this.$store.state.principal['companyId'] : "";
				let companyName = this.$root.isBlank(this.$store.state.principal["companyName"]) == false ? this.$store.state.principal["companyName"] : "";
				let companyType = this.$root.isBlank(this.$store.state.principal["companyType"]) == false ? this.$store.state.principal["companyType"] : "";
				//not sent account number if have empty company id
				let accountId = this.$root.isBlank(companyId) ? "" : 
									this.$root.isBlank(this.company["accountId"]) == false ? this.company["accountId"] : "";
				let bannerId = this.$root.isBlank(item["id"]) == false ? item["id"] : "";
				let bannerName = this.$root.isBlank(item["name"]) == false ? item["name"] : "";

				let userInformation = 
					userId + "," +
					userName + "," +
					email + "," +
					accountId + "," +
					companyId + "," +
					companyName + "," +
					companyType
				let bannerInformation = 
					bannerId + "," +
					bannerName
				// console.log(bannerInformation);
				if (this.$root.isBlank(item["link"]) == false) {
					this.$gtag.event('click', {
						event_category: 'banner',
						action: 'click',
						event_label: item["link"],
						dimension1: userInformation,
						dimension2: bannerInformation
					});
				}
			} catch(error) {
				console.log(error);
			}
			
			location.href = item["link"];
		},
		async findVenueImageHomePage() {
			let vm = this;
			const [request1, request2, request3, request4, request5, request6] = await Promise.all([
				axios.get("/system/public/venueImage?page=Homepage"),
				axios.get("/system/public/venueImage?page=NewReleases"),
				axios.get("/system/public/venueImage?page=BarEssentials"),
				axios.get("/system/public/venueImage?page=Events"),
				axios.get("/system/public/venueImage?page=BrandAmbassadors"),
				axios.get("/system/public/venueImage?page=SupplierContacts")
			]);

			vm.venueImage = request1.data;
			vm.venueImage.memberOffers = request1.data.imageNamesMap.memberOffers;
			vm.venueImage.video = request1.data.imageNamesMap.video;
			vm.venueImage.news = request1.data.imageNamesMap.news;
			vm.venueImage.newReleases = request2.data.imageNamesMap.heroImage;
			vm.venueImage.barEssentials = request3.data.imageNamesMap.heroImage;
			vm.venueImage.events = request4.data.imageNamesMap.heroImage;
			vm.venueImage.brandAmbassadors = request5.data.imageNamesMap.heroImage;
			vm.venueImage.supplierContacts = request6.data.imageNamesMap.heroImage;
		},
		findMeCompany() {
			axios
				.get("/system/company/profile")
				.then(response => {
					this.company = response.data;
				})
				.catch(() => {});
		},
		perPage() {
			if (this.$vuetify.breakpoint.mdAndUp) {
				return 8;
			} else {
				return 6;
			}
		}
	}
};
</script>