<template>
	<div>
		<div v-if="$window.location.origin.includes('test')" class="banner" style="background-color:#03B3B2;color:white;">TEST</div>
		<div v-if="$window.location.origin.includes('localhost')" class="banner" style="background-color:#82BA00;color:white;">DEVELOPMENT</div>
		<router-view></router-view>
	</div>
</template>

<style>
@font-face {
	font-family: "D-DIN";
	src: url("https://alm-agora.s3-ap-southeast-2.amazonaws.com/resource/font/D-DIN.otf");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "D-DINCondensed";
	src: url("https://alm-agora.s3-ap-southeast-2.amazonaws.com/resource/font/D-DINCondensed.otf");
}
@font-face {
	font-family: "D-DINCondensed-Bold";
	src: url("https://alm-agora.s3-ap-southeast-2.amazonaws.com/resource/font/D-DINCondensed-Bold.otf");
}
@font-face {
	font-family: "JosefinSans";
	src: url('/font/JosefinSans.ttf');
}
body {
	font-family: "D-DIN";
}
.pointer {
	cursor: pointer;
}
.loader {
	position: fixed;
	z-index: 888;
	top: 30%;
	left: 50%;
	transform: translateX(-50%);
}
div.application {
	font-family: "Open Sans", Helvetica, Arial, monospace, sans-serif;
	margin: 0px;
}
div.banner {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	z-index: 9;
	padding: 5px 10px;
	border-radius: 0 0 5px 5px;
	font-family: "Open Sans", sans-serif;
}
.ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.menu1 {
	margin-right: 3%;
	font-size: 12px;
	color: white;
}
.center {
	display: block;
	margin: 30px auto;
}
.custom-placeholder-color {
	color: white !important;
}
.custom-placeholder-color::placeholder {
	color: white !important;
	opacity: 1;
}
.custom-placeholder-color:focus {
	outline: none;
}
.custom-placeholder-color-black {
	color: black !important;
}
.custom-placeholder-color-black::placeholder {
	color: black !important;
	opacity: 1;
}
.custom-placeholder-color-black:focus {
	outline: none;
}
.agora-divider {
	color: white;
	border-color: rgba(255, 255, 255, 1) !important;
}
.agora-circle {
	position: absolute;
	border-radius: 50%;
	display: inline-block;
}
.agora-text-link {
	color: white !important;
	text-decoration: none;
}
.agora-text-link:hover,
.agora-text-link.router-link-active {
	color: #f47a20 !important;
}
.agora-text-title {
	font-size: 64px !important;
}
.agora-text-1 {
	font-size: 36px !important;
}
.agora-text-2 {
	font-size: 19px !important;
}
.agora-text-3 {
	font-size: 17px !important;
}
.menu-text {
	font-size: 17px !important;
}
.submenu-text {
	font-size: 15px !important;
	letter-spacing: 1px;
	font-family: "D-DINCondensed";
}
.container {
	padding-right: 40px;
	padding-left: 40px;
}
.chip-brand {
	font-size: 21px !important;
	background: #f47a20 !important;
	color: white !important;
	margin: 0 10px 10px 0;
}
.chip-category {
	font-size: 21px !important;
	background-color: #6c4b9e !important;
	color: white !important;
	margin: 0 10px 10px 0;
}
.grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(423px, 1fr));
	grid-gap: 2rem;
}
.orange-hover:hover,
.orange-hover:focus  {
	background-color: #F16013 !important;
	color: white !important;
	border: 0 !important;
}
</style>

<script>
	export default {
		mounted() {
			if (!this.$store.getters.isAuthenticated) {
				let token = document.cookie.match(new RegExp('(^| )' + 'agora-jwt' + '=([^;]+)'));
				if (token) {
					token = token[2];
					let base64Url = token.split('.')[1]; // token you get
					let base64 = base64Url.replace('-', '+').replace('_', '/');
					let decodedData = JSON.parse(Buffer.from(base64, 'base64').toString('binary'));
					let principal = JSON.parse(decodedData.principal);
					principal.token = token;
					this.$store.commit("login", principal);
				}
			}
		}
	}
</script>