<template>
	<div>
		<agora-breadcrumb></agora-breadcrumb>
		<!-- DESKTOP -->
		<v-container class="hidden-sm-and-down">
			<v-row align="center">
				<h1 class="text-h5 text-sm-h3 my-0" style="font-family: JosefinSans !important;">CART</h1>
				<v-col align-self="center">
					<h3 class="text-body-1 text-sm-h6 ml-12 menu-text agora-text-3" style="color:black; font-family:D-DINCondensed-Bold; ">
						Please read the important information. <a class="text-body-1 text-sm-h6 menu-text agora-text-3" :href="baseLocation + '/resource/asset/document/New-Homepage-Customer-Letter-28042022.pdf'" style="color:black; " target="_blank">Please read</a>
					</h3>
					<h3 class="text-body-1 text-sm-h6 ml-12 menu-text agora-text-3" style="color:black; font-family:D-DINCondensed-Bold; ">
						Please login from the main menu on the new ALM home page in order to see the products in your cart
					</h3>
				</v-col>
			</v-row>

			<br />
			<br />
			
			<v-row class="d-flex flex-row">
				<v-col cols="6" class="mx-0 my-4" v-if="entity.length == 0">Empty list</v-col>
				<v-col v-else cols="6">
					<v-card v-for="(offer, index) in entity" :key="index" class="mb-6" elevation="0" flat style="border-radius: 0px;">
						<v-row>
							<v-col cols="10" class="pl-10">
								<v-row no-gutters>
									<v-col>
										<h1 style="font-family: Source Sans Pro !important;font-size:20px">{{ offer.name }}</h1>
									</v-col>
								</v-row>
								<v-row	no-gutters>
									<v-col cols="12" sm="4" md="4" v-for="variant in offer.variantDataList" :key="variant.code">
										<v-checkbox @change="changeSelectedVariant(offer)" v-model="offer.dataMap.variantList" :label="variant.name" :value="variant.code" color="#F16013"></v-checkbox>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="2" class="text-right pr-6">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-icon color="#F16013" dark v-bind="attrs" v-on="on" @click="remove(offer.id)">mdi-delete</v-icon>
									</template>
									<span>Remove</span>
								</v-tooltip>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
				
				<v-col cols="5" v-if="entity.length > 0" class="ml-2 mb-4 pt-0">
					<h1 style="font-family: Source Sans Pro !important;" class="text-body-1 text-sm-h6 font-weight-black">Order Summary</h1>
					<br />
					<div class="d-flex justify-space-between">
						<span class="mb-2 text-body-1" style="font-family: Source Sans Pro !important; color: #6F7175">Total Offers</span>
						<span class="font-weight-black" style="font-family: Source Sans Pro !important;">{{entity.length}}</span>
					</div>
					<v-divider style="background-color: #AFB0B3;"></v-divider>
					<br />
					<v-row class="d-flex justify-end">
						<v-col cols="5">
							<v-btn @click="$router.push('/offer')" outlined class="mr-2 text-body-1 text-capitalize orange-hover" width="100%" style="font-family: Source Sans Pro !important; border-radius: 0px;">Continue Shopping</v-btn>
						</v-col>
						<v-col cols="4">
							<v-btn @click="dialogCheckout = true;" outlined class="mr-2 text-body-1 text-capitalize" width="100%" style="font-family: Source Sans Pro !important; border-radius: 0px; background-color: #F16013; color: white;">Checkout</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<br />
		</v-container>

		<!-- MOBILE -->
		<v-container class="hidden-md-and-up">
			<v-row align="center">
				<h1 class="text-h5 text-sm-h4 font-weight-black mb-4" style="font-family: Source Sans Pro !important; ">CART</h1>
				<h3 class="text-body-1 text-sm-h5 mb-2 mb-sm-2 ml-sm-4 agora-text-3" style="color:black; font-family:D-DINCondensed-Bold;">
					Please read the important information. <a class="text-body-1 text-sm-h5 agora-text-3" :href="baseLocation + '/resource/asset/document/New-Homepage-Customer-Letter-28042022.pdf'" style="color:black;" target="_blank">Please read</a>
				</h3>
				<h3 class=" text-body-1 text-sm-h5 mb-8 mb-sm-4 ml-sm-4 agora-text-3" style="color:black; font-family:D-DINCondensed-Bold; ">
					Please login from the main menu on the new ALM home page in order to see the products in your cart
				</h3>
			</v-row>

			<v-card outlined color="#f4f4f5">
				<v-row class="d-flex flex-row">
					<v-col cols="12" class="mx-0 my-4" v-if="entity.length == 0">Empty list</v-col>
					<v-col v-else cols="12" class="ma-0 pa-0 pt-6">
						<v-card v-for="(offer, index) in entity" :key="index" class="mb-6" style="border-radius: 0px;">
							<v-row class="mb-2">
								<v-col cols="10" class="pl-9">
									<div class="mb-6">
										<h1 class="text-h6 text-sm-h4 font-weight-black" style="font-family: Source Sans Pro !important;  font-size:20px">{{ offer.name }}</h1>
									</div>
									<v-row>
										<v-col cols="6" sm="4" md="4" v-for="variant in offer.variantDataList" :key="variant.code">
											<v-checkbox color="#F16013" @change="changeSelectedVariant(offer)" v-model="offer.dataMap.variantList" :label="variant.name" :value="variant.code" style="margin-top:-10px"></v-checkbox>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="2" class="d-flex justify-end align-start pr-6">
									<v-tooltip bottom >
										<template v-slot:activator="{ on, attrs }">
											<v-icon color="#F16013" dark v-bind="attrs" v-on="on" @click="remove(offer.id)">mdi-delete</v-icon>
										</template>
										<span>Remove</span>
									</v-tooltip>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
				</v-row>
				<v-divider></v-divider>
				<v-row v-if="entity.length > 0">
					<v-col cols="12">
						<h1 class="text-h5 text-sm-h4 font-weight-black text-capitalize" style="font-family: Source Sans Pro !important; text-align: left">Order Summary</h1>
						<div class="d-flex justify-space-between">
							<span class="mb-3 text-body-1 text-sm-h5 font-weight-thin" style="font-family: Source Sans Pro !important;">Total Offers</span>
							<span class="text-body-2 text-sm-h6 font-weight-black" style="font-family: Source Sans Pro !important;">{{entity.length}}</span>
						</div>
						<v-divider></v-divider>
						<br />
						<v-row>
							<v-col cols="6">
								<v-btn @click="$router.push('/offer')" outlined class="text-body-2 text-sm-h5 text-capitalize" width="100%" style="font-family: Source Sans Pro !important; border-radius: 0px;">Continue Shopping</v-btn>
							</v-col>
							<v-col cols="6">
								<v-btn @click="dialogCheckout = true;" outlined class="text-body-2 text-sm-h5 text-capitalize" width="100%" style="font-family: Source Sans Pro !important; border-radius: 0px; background-color: #F16013; color: white;">Checkout</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card>
		</v-container>

		<!-- Checkout dialog -->
		<v-dialog v-model="dialogCheckout" max-width="665px">
			<v-card :height="$vuetify.breakpoint.mdAndUp ? '300': '470'" class="rounded-lg" style="overflow: hidden; background: #F2F2F2 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000029; opacity: 1;">
				<v-card-title>
					<v-row>
						<v-col cols="12" class="d-flex justify-center align-center">
							<img :src="baseLocation + '/resource/asset/icon/agora.svg'" class="mr-4 mt-2 ml-2" width="80" height="100" style="cursor: pointer" />
							<div style="font-size: 8px;" class="ma-0 pa-0 mt-2">Brought to you by</div>
							<img :src="baseLocation + '/resource/asset/icon/alm.png'" width="40" height="30" style="cursor: pointer" />
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col class="col-12 justify-center align-center">
							<div class="text-body-1 text-sm-body-1 font-weight-black" style="font-family: Source Sans Pro !important;">
								<center>
									To complete your order you will be transferred to the ALM Ordering Portal.
									<br />
									Do you want to clear your cart on Agora or keep the items there for next time? 
								</center>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-row class="mb-1">
						<!-- DESKTOP -->
						<v-col class="hidden-sm-and-down justify-center align-center" :class="$vuetify.breakpoint.mdAndUp ? 'd-flex':''">
							<v-btn :small="smallButton" outlined class="mr-4 orange-hover" @click="dialogCheckout = false;" style="font-family: Source Sans Pro !important; border-radius: 0;">Cancel</v-btn>
							<v-btn :small="smallButton" outlined class="text-body-1 text-capitalize mr-4" style="font-family: Source Sans Pro !important; border-radius: 0; background-color: #F16013; color: white;" @click="checkout(false)">Checkout and keep cart</v-btn>
							<v-btn :small="smallButton" outlined class="text-body-1 text-capitalize mr-4" style="font-family: Source Sans Pro !important; border-radius: 0; background-color: #F16013; color: white;" @click="checkout(true)">Checkout and clear cart</v-btn>
						</v-col>
						<!-- MOBILE -->
						<v-col class="hidden-md-and-up justify-center align-center">
							<v-row class="d-flex" no-gutters>
								<v-col cols="12" class="mb-4 text-center">
									<v-btn :small="smallButton" outlined class="mr-2 orange-hover" @click="dialogCheckout = false;" style="font-family: Source Sans Pro !important; border-radius: 0;">Cancel</v-btn>
								</v-col>
								<v-col cols="12" class="mb-4 text-center">
									<v-btn :small="smallButton" outlined class="text-body-1 text-capitalize mr-4" style="font-family: Source Sans Pro !important; border-radius: 0; background-color: #F16013; color: white;" @click="checkout(false)">Checkout and keep cart</v-btn>
								</v-col>
								<v-col cols="12" class="text-center">
									<v-btn :small="smallButton" outlined class="text-body-1 text-capitalize mr-4" style="font-family: Source Sans Pro !important; border-radius: 0; background-color: #F16013; color: white;" @click="checkout(true)">Checkout and clear cart</v-btn>
								</v-col>	
							</v-row>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<style scoped>
.fb-btn.v-btn--outlined {
	border: thin solid black;
}
.menu-text {
	font-size: 20px !important;
}
</style>

<script>
import axios from "axios";
import agoraBreadcrumb from "./agoraBreadcrumb.vue";
export default {
	components: {
		agoraBreadcrumb
	},
	data: function() {
		return {
			entity: [],
			selectedVariant: [],
			productCodeList: null,
			baseLocation: axios.defaults.baseURL,
			dialogCheckout: false
		};
	},
	computed: {
		heightImage() {
			var width = this.$vuetify.breakpoint.width
			if (width > 650) {
				return '175'
			} else {
				return '140'
			}
		},
	},
	mounted() {
		this.find();
	},
	methods: {
		find() {
			if (!this.$store.getters.isAuthenticated) {
				this.$router.push("/signin/cart");
			} else {
				axios.get("/system/cart/product/list?action=list&clearCart=false").then(response => {
					this.entity = response.data;
					this.$store.commit("getTotalCart");
					this.listSelectedVariant();
				});
			}
		},
		changeSelectedVariant(product) {
			axios
				.post("/system/cart/edit/variant", {
					productId: product.id,
					variant: product.dataMap.variantList
				})
				.then(() => {
					this.listSelectedVariant();
				});
		},
		listSelectedVariant() {
			//Collect all selectedvariant from all products
			this.selectedVariant = [];
			for (let entity of this.entity) {
				for (let variant of entity.dataMap.variantList) {
					this.selectedVariant.push(variant);
				}
			}
		},
		checkout(clear) {
			axios
				.post("/system/cart/product/list", {
					action: "checkout",
					clearCart: clear
				})
				.then(response => {
					this.entity = response.data;
					this.find();
					this.listSelectedVariant();
					this.dialogCheckout = false;

					//get productCode List in string
					this.productCodeList = "";
					let separator = "+";
					for (var i = 0; i < this.selectedVariant.length; i++) {
						if (i == this.selectedVariant.length - 1) {
							separator = "";
						}
						this.productCodeList += this.selectedVariant[i] + separator;
					}

					var url =
						(this.$window.location.origin.includes("stage") || this.$window.location.origin.includes("localhost")
							? "https://uat.almliquor.com.au/search/?isGridView=false&text="
							: "https://almliquor.com.au/search/?isGridView=false&text=") + this.productCodeList;
					window.open(url, "_blank");
				});
		},
		remove(id) {
			axios
				.post("/system/cart/product/remove", {
					productId: id
				})
				.then(() => {
					this.find();
				});
		}
	}
};
</script>