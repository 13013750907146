<template>
	<v-container class="pt-4 pb-0">
		<div class="d-flex justify-start align-center" style="flex-wrap: wrap;">
			<v-btn @click="$router.back()" class="pl-0 text-capitalize" color="#F16013" plain elevation="0" style="font-family: Source Sans Pro !important;">
				<v-icon small>mdi-arrow-left</v-icon>Back
			</v-btn>
			<div>|</div>
			<template v-for="(item, index) in breadcrumbList">
				<v-btn v-if="item.link" :key="index" :to="item.link" plain elevation="0" class="button px-2 text-capitalize justify-center" style="font-family: Source Sans Pro !important; cursor: pointer;">{{getItemName(item.name)}}</v-btn>
				<div v-else :key="index" class="pr-2 px-2 text-capitalize" style="font-family: Source Sans Pro !important; font-size: 14px; color: #717171; cursor: pointer;">{{getItemName(item.name)}}</div>
				<div v-if="(index < breadcrumbList.length-1)" :key="item.name">|</div>
			</template>
		</div>
	</v-container>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			breadcrumbList: []
		};
	},
	props: ["title"],
	methods: {
		getItemName(name) {
			if ((name.includes("Detail") || name.includes("Details")) && this.title) {
				if (this.$vuetify.breakpoint.mdAndUp) {
					return this.title;
				} else {
					return (this.title.length > 35 ? this.title.slice(0, 35) + '...' : this.title)
				}
			} else if (name === "Videos") {
				return "Video Content";
			} else if (name.includes("Bar Essential")) {
				return "Essentials";
			} else if (name === "Contact") {
				return "Contacts";
			} 
			return name;
		}
	},
	watch: {
		"$route.params.type": {
			handler: function(value) {
				if (this.$route.path.includes("detail")) {
					this.breadcrumbList = this.$root.breadcrumb(value, true);
					if (value == "brands") {
						axios
							.post("/system/public/find/brand", {
								id: this.$route.params.id
							})
							.then(response => {
								this.brandId = response.data.brandId;
								this.breadcrumbList[this.breadcrumbList.length - 1].link = "/" + this.brandId + "/product";
								this.breadcrumbList.push({
									name: "Detail"
								});
							});
					} else {
						this.breadcrumbList.push({
							name: "Detail"
						});
					}
				} else {
					this.breadcrumbList = this.$root.breadcrumb(value, false);
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		if (this.$route.meta.breadcrumb) {
			this.breadcrumbList = this.$route.meta.breadcrumb;
		}
	},
	method: {}
};
</script>