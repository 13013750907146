<template>
	<div>
		<agora-breadcrumb title="Search"></agora-breadcrumb>

		<v-container>
			<h1 class="text-h5 text-sm-h3 my-0" style="font-family: JosefinSans !important;">SEARCH RESULTS</h1>
			<br class="hidden-sm-and-down" />
		</v-container>

		<!-- Search -->
		<v-container>
			<div class="d-flex justify-space-between flex-wrap mx-0">
				<div class="d-flex col col-12 justify-start align-center" style="height: 41px; width: 300px; border-bottom: 2px solid black;">
					<v-icon style="cursor: pointer;">mdi-magnify</v-icon>
					<input v-model="keywordTerm" @blur="searchTerm()" @keyup.enter="searchTerm()" class="custom-placeholder-color-black" type="text" placeholder="Search Term" style="font-family: Source Sans Pro !important; width: 98%; font-size: 16px; font-family: 'D-DIN'; padding: 0 10px;" />
				</div>
			</div>
		</v-container>

		<v-container>
			<v-row class="my-4">
				<v-col cols="6" md="3" v-for="brand in brandList" :key="brand.id">
					<v-card @click="$router.push('/brand/detail/' + brand.id)" tile outlined>
						<v-card-text>
							<center>
								<v-img :src="$root.asset(brand.imageName, 'medium')" contain :height="heightImage"></v-img>
							</center>
						</v-card-text>
					</v-card>
					<v-card width="auto" color="#F2F2F2" tile elevation="0">
						<v-card-text>
							<h2 class="text-body-1 text-sm-h7 text-md-h7 font-weight-black" style="font-family: Source Sans Pro !important;">{{ $root.ellipsify(brand.name, ellipsifyLimit) }}</h2>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="6" md="3" v-for="ambassador in ambassadorList" :key="ambassador.id">
					<v-card @click="$router.push('/ambassador/detail/' + ambassador.id)" tile outlined>
						<v-card-text>
							<center>
								<v-img :src="$root.asset(ambassador.imageName, 'medium')" contain :height="heightImage"></v-img>
							</center>
						</v-card-text>
					</v-card>
					<v-card width="auto" color="#F2F2F2" tile elevation="0">
						<v-card-text>
							<h2 class="text-body-1 text-sm-h7 text-md-h7 font-weight-black" style="font-family: Source Sans Pro !important;">{{ $root.ellipsify(ambassador.name, ellipsifyLimit) }}</h2>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="6" md="3" v-for="product in productList" :key="product.id">
					<v-card @click="$router.push('/' + product.type.toLowerCase() + '/detail/' + product.id)" tile outlined>
						<v-card-text>
							<center>
								<v-img :src="$root.asset(product.imageName, 'medium')" contain :height="heightImage"></v-img>
							</center>
						</v-card-text>
					</v-card>
					<v-card width="auto" color="#F2F2F2" tile elevation="0">
						<v-card-text>
							<h2 class="text-body-1 text-sm-h7 text-md-h7 font-weight-black" style="font-family: Source Sans Pro !important;">{{ $root.ellipsify(product.name, ellipsifyLimit) }}</h2>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
import agoraBreadcrumb from "./agoraBreadcrumb.vue";

export default {
	props: ["keyword"],
	components: {
		agoraBreadcrumb
	},
	data() {
		return {
			ambassadorList: [],
			brandList: [],
			productList: [],
			keywordTerm: ""
		};
	},
	computed: {
		heightImage() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '300'
			} else if (width > 700) {
				return '200'
			} else {
				return '100'
			}
		},
		ellipsifyLimit() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '18'
			} else if (width >= 700) {
				return '28'
			} else {
				return '8'
			}
		}
	},
	mounted: function() {
		this.search();
	},
	watch: {
		keyword: function() {
			this.search();
		}
	},
	methods: {
		search() {
			this.keyword = this.keyword.replace(/%20/g, " ");
			axios
				.post("/system/public/search", {
					keyword: this.keyword
				})
				.then(response => {
					let searchResult = response.data;
					this.brandList = searchResult.brandList;
					this.ambassadorList = searchResult.ambassadorList;
					this.productList = searchResult.productList;
					this.keywordTerm = this.keyword;
				});
		},
		searchTerm() {
			this.keywordTerm = this.keywordTerm.replace(/%20/g, " ");
			axios
				.post("/system/public/search", {
					keyword: this.keywordTerm
				})
				.then(response => {
					let searchResult = response.data;
					this.brandList = searchResult.brandList;
					this.ambassadorList = searchResult.ambassadorList;
					this.productList = searchResult.productList;
				});
		}
	}
};
</script>