<template>
	<div>
		<agora-breadcrumb></agora-breadcrumb>

		<v-container>
			<h1 class="text-h5 text-sm-h3 my-0" style="font-family: JosefinSans !important;">ESSENTIALS</h1>
			<br />
			<div class="text-body-1 text-sm-h6 mb-4" style="font-family: Source Sans Pro !important;">Browse point of sale, tasting notes, menus which you can download and print for your venue.</div>
			<br />
			<v-row>
				<v-col cols="12" md="6">
					<agora-lead-tile 
						:image="$root.asset(venueImage.imageNamesMap.staticPos, null)" 
						title="STATIC POS" 
						description="To download signage to use in your venue and on your website, click here to see all that is available for you." 
						link="/signage/list"
					></agora-lead-tile>
				</v-col>
				<v-col cols="12" md="6">
					<agora-lead-tile
						:image="$root.asset(venueImage.imageNamesMap.barEssentials, null)"
						title="BAR ESSENTIALS"
						description="Wine buckets, umbrellas, branded merchandise. Find all plus more here. When you find what you like, reach out to the supplier to find out how you can get access to  their bar essentials."
						link="/baressential/list"
					></agora-lead-tile>
				</v-col>
				<v-col cols="12" md="6">	
					<agora-lead-tile
						:image="$root.asset(venueImage.imageNamesMap.menuSystems, null)"
						title="MENU SYSTEMS"
						description="Do you need help with your wine or cocktail menus? Click here to view examples and connect with suppliers to assist you in building these for your venue."
						link="/menusolution/list"
					></agora-lead-tile>
				</v-col>
				<v-col cols="12" md="6">	
					<agora-lead-tile
						:image="$root.asset(venueImage.imageNamesMap.tastingNotes, null)"
						title="TASTING NOTES"
						description="Want to know more about a product, find all tasting notes housed here. From wines to spirits – all in the one place."
						link="/tasting/list"
					></agora-lead-tile>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import axios from "axios";
import agoraBreadcrumb from "./agoraBreadcrumb.vue";
import agoraLeadTile from "./agoraLeadTile";

export default {
	components: {
		agoraLeadTile,
		agoraBreadcrumb
	},
	data() {
		return {
			baseLocation: axios.defaults.baseURL,
			venueImage: { imageNamesMap:{ heroImage:"" }}
		};
	},
	mounted: function() {
		this.findVenueImage();
	},
	methods: {
		findVenueImage() {
			let vm = this;
			axios.get("/system/public/venueImage?page=Essential")
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.venueImage = response.data;
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		}
	}
};
</script>