<template>
	<div>
		<agora-breadcrumb></agora-breadcrumb>

		<v-container>
			<h1 class="text-h5 text-sm-h3 my-0" style="font-family: JosefinSans !important;">BRANDS - {{brandName}}</h1>
			<br />
		</v-container>

		<!-- Filter -->
		<v-hover v-slot:default="{ hover }">
			<v-card elevation="0" style="position: absolute; z-index: 2; opacity: 0.9" :height="$vuetify.breakpoint.mdAndUp ? '50%': 'auto'">
				<v-navigation-drawer permanent temporary expand-on-hover hide-overlay color="#dadadc">
					<v-list nav dense class="ma-0 pa-0">
						<v-list-item>
							<v-list-item-content>
								<div class="d-flex justify-space-between mt-2">
									<p v-if="hover" class="mt-1" style="font-family:'D-DINCondensed-Bold'; font-size: 18px; margin-bottom: 30px">
										<span class="hidden-sm-and-down">Filter Result</span>
										<span class="hidden-md-and-up" :class="!hover ? 'pl-1':'pl-0'">Filter</span>
									</p>
									<v-btn v-if="hover" class="orange-hover" outlined color="black" small dense @click="filterClear" style="border-radius: 0">Clear Filters</v-btn>
								</div>
								<v-list-item-title v-if="!hover">
									<v-img width="40" height="30" contain :src="baseLocation + '/resource/asset/icon/drinkcategory.png'"></v-img>
									<div class="mt-1 text-center hidden-sm-and-down">Category</div>
								</v-list-item-title>
								<div v-else>
									<v-row @click="expandCategory = !expandCategory" no-gutters class="d-flex pb-2 mb-6" style="border-bottom: 1px solid black">
										<v-col cols="8" class="d-flex justify-start align-center">
											<div>
												<h3>Category</h3>
											</div>
										</v-col>
										<v-col cols="4" class="d-flex justify-end align-center">
											<v-icon small>{{ expandCategory ? 'mdi-chevron-up':'mdi-chevron-down' }}</v-icon>
										</v-col>
									</v-row>
									<v-template v-if="expandCategory">
										<div v-for="category in categoryList" :key="category.id" class="d-flex justify-space-between align-center" style="margin-top: -25px;">
											<v-checkbox color="agora_secondary" v-model="selectedCategoryList" @change="filter" :label="category.name" :value="category.id" dense style="margin-left: 5px;"></v-checkbox>
											<div class="total">{{ category.total }}</div>
										</div>
									</v-template>
								</div>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title v-if="!hover">
									<v-img width="40" height="30" contain :src="baseLocation + '/resource/asset/icon/supplier.png'"></v-img>
									<div class="mt-1 text-center hidden-sm-and-down">Supplier</div>
								</v-list-item-title>
								<div v-else>
									<v-row @click="expandSupplier = !expandSupplier" no-gutters class="d-flex pb-2 mb-6" style="border-bottom: 1px solid black">
										<v-col cols="8" class="d-flex justify-start align-center">
											<div>
												<h3>Supplier</h3>
											</div>
										</v-col>
										<v-col cols="4" class="d-flex justify-end align-center">
											<v-icon small>{{ expandSupplier ? 'mdi-chevron-up':'mdi-chevron-down' }}</v-icon>
										</v-col>
									</v-row>
									<v-template v-if="expandSupplier">
										<div v-for="supplier in supplierList" :key="supplier.id" class="d-flex justify-space-between align-center" style="margin-top: -25px;">
											<v-checkbox color="agora_secondary" v-model="selectedSupplierList" @change="filter" :label="supplier.name" :value="supplier.id" dense style="margin-left: 5px;"></v-checkbox>
											<div class="total">{{ supplier.total }}</div>
										</div>
									</v-template>
								</div>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-navigation-drawer>
			</v-card>
		</v-hover>

		<!-- Search -->
		<v-container class="hidden-sm-and-down">
			<div class="d-flex justify-space-between flex-wrap mx-0">
				<div class="d-flex col col-12 justify-start align-center" style="height: 41px; width: 300px; border-bottom: 2px solid black;">
					<v-icon style="cursor: pointer;">mdi-magnify</v-icon>
					<input v-model="keyword" @blur="filter" @keyup.enter="filter" class="custom-placeholder-color-black" type="text" placeholder="Search Brands" style="font-family: Source Sans Pro !important; width: 98%; font-size: 16px; font-family: 'D-DIN'; padding: 0 10px;" />
				</div>
				<div class="d-flex col col-12 justify-end pr-0">
					<table>
						<tr>
							<td style="padding: 0 5px;">
								<v-select v-model="pagination.direction" :items="['A-Z', 'Z-A']" @change="paginate" label="Sort by" outlined dense hide-details="true" background-color="transparent" style="font-family: 'D-DIN'; font-size: 15px; letter-spacing: 0px; border-radius: 0;"></v-select>
							</td>
							<td style="padding: 0 5px;">
								<v-select
									v-model="pagination.pageSize"
									:items="[
										{ value: 18, text: '18' },
										{ value: 24, text: '24' },
										{ value: 48, text: '48' },
										{ value: 96, text: '96' }
									]"
									item-text="text"
									item-value="value"
									@change="pageSet(1)"
									label="Results per page"
									outlined
									dense
									hide-details="true"
									background-color="transparent"
									style="font-family: 'D-DIN'; font-size: 15px; letter-spacing: 0px; border-radius: 0;"
								></v-select>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</v-container>

		<v-container>
			<div class="mx-0 mx-md-12">
				<v-row>
					<v-col class="col-6 col-sm-6 col-md-3" v-for="product in displayList" :key="product.id">
						<v-card @click="navigateToDetail(product.id)" tile outlined>
							<v-card-text>
								<center>
									<v-img :src="$root.asset(product.imageName, 'medium')" contain :height="heightImage"></v-img>
								</center>
							</v-card-text>
						</v-card>
						<v-card width="auto" color="#F2F2F2" tile elevation="0">
							<v-card-text>
								<h2 class="text-body-1 text-sm-h7 text-md-h7 font-weight-black" style="font-family: Source Sans Pro !important;">{{ $root.ellipsify(brandName + " - " + supplierName + " - " + product.name + (product.code ? " - " + product.code : ""), 100) }}</h2>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<div v-if="loaded && displayList.length == 0">Empty list</div>
				<br />
			</div>
		</v-container>

		<v-container v-if="pageList.length > 0">
			<v-row>
				<v-col>
					<v-pagination v-model="pagination.page" :length="pageList.length" :total-visible="$vuetify.breakpoint.smAndUp ? 7:5" class="plain" color="agora_secondary" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right" :previous="pagePrevious" :next="pageNext" @input="pageSet(pagination.page)"></v-pagination>
				</v-col>
			</v-row>
		</v-container>

		<v-dialog v-model="dialogAdd" fullscreen>
			<v-overlay>
				<div class="d-flex justify-center">
					<v-card color="black" elevation="0" width="400" style="padding-bottom: 20px">
						<v-card-title>
							<v-spacer></v-spacer>
							<v-btn color="white" icon @click="dialogAdd = false">
								<v-icon>mdi-close-thick</v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text style="font-size:14px; font-family:'D-DIN'; text-align: center; color:#6f7175 !important; margin-top: -25px">
							<h4 style="font-size:18px; color:white; margin-bottom: 5px">Register Your Request</h4>
							<p>By registering a request, a notification will be sent to the supplier</p>
							<v-form ref="form" @submit.prevent="addInterest">
								<v-text-field v-model="entityAdd.dataMap.name" label="Name" solo rounded background-color="white" light></v-text-field>
								<v-text-field v-model="entityAdd.dataMap.email" label="Email" solo rounded background-color="white" light style="margin-top: -20px"></v-text-field>
								<v-select v-model="entityAdd.dataMap.jobRole" label="Job Role" :items="jobRoleList" item-text="text" item-value="value" solo rounded background-color="white" light style="margin-top: -20px"></v-select>
								<v-text-field v-model="entityAdd.dataMap.companyName" label="Company Name" solo rounded background-color="white" light style="margin-top: -20px"></v-text-field>
								<v-textarea v-model="entityAdd.comment" label="Comment" solo rounded background-color="white" light style="margin-top: -20px"></v-textarea>
								<p style="color:#6f7175; text-align: left; margin-top: -30px">i.e. Best way to contact, colour and size options</p>
								<v-btn type="submit" rounded color="#6f7175" width="100%" style="color:white; font-family:'D-DINCondensed'; font-size:20px">REQUEST</v-btn>
							</v-form>
						</v-card-text>
					</v-card>
				</div>
			</v-overlay>
		</v-dialog>

		<v-dialog v-model="dialogNotification" fullscreen>
			<v-overlay>
				<div class="d-flex justify-center">
					<v-card color="black" elevation="0" width="400" style="padding-bottom: 20px">
						<v-card-title class="justify-center">
							<h4 style="font-size:18px; color:white">Request has been sent</h4>
						</v-card-title>
						<v-card-text style="font-size:14px; font-family:'D-DIN'; text-align: center; color:#6f7175 !important"></v-card-text>
						<v-card-actions>
							<v-btn rounded color="#6f7175" width="100%" style="color:white; font-family:'D-DINCondensed'; font-size:20px" @click="dialogNotification = false">OK</v-btn>
						</v-card-actions>
					</v-card>
				</div>
			</v-overlay>
		</v-dialog>
	</div>
</template>

<style scoped>
div.total {
	display: inline-block;
	background-color:#f4f4f5;
	color:#6c4b9e;
	border-radius: 17px;
	padding: 3px 10px;
}
</style>

<script>
import axios from "axios";
import agoraBreadcrumb from "./agoraBreadcrumb.vue";
export default {
	props: ["brandId"],
	components: {
		agoraBreadcrumb
	},
	data: function() {
		return {
			loaded: false,
			keyword: "",
			categoryList: [],
			selectedCategoryList: [],
			supplierList: [],
			selectedSupplierList: [],
			brandList: [],
			selectedBrandList: [],
			productList: [],
			filterList: [],
			displayList: [],
			pagination: { page: 1, pageSize: 18, pageLast: 1, direction: null },
			pageList: [],
			baseLocation: axios.defaults.baseURL,
			brandName: null,
			entity: {},
			person: {},
			supplierName: "",
			dialogAdd: false,
			dialogNotification: false,
			jobRoleList: [],
			entityAdd: {
				comment: "",
				dataMap: {
					name: "",
					email: "",
					jobRole: "",
					companyName: ""
				}
			},
			expandCategory: true,
			expandSupplier: true
		};
	},
	computed: {
		heightImage() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '300'
			} else if (width > 700) {
				return '200'
			} else {
				return '100'
			}
		}
	},
	mounted: function() {
		this.listFilter();
		this.getBrandName();
		this.list();
		this.listJobRole();
	},
	created: function() {},
	methods: {
		listJobRole() {
			axios
				.get("/system/enumeration/jobrole/list")
				.then(response => {
					this.jobRoleList = response.data;
				})
				.catch(() => {});
		},
		getBrandName() {
			axios
				.post("system/public/brand", {
					id: this.brandId
				})
				.then(response => {
					this.brandName = response.data.name;
					this.supplierName = response.data.dataMap.companyName;
				});
		},
		listFilter() {
			axios
				.get("/system/public/filter/list/brandProduct?brandId=" + this.brandId)
				.then(response => {
					let map = response.data;
					this.supplierList = map.supplierList;
					this.categoryList = map.categoryList;
				})
				.catch(() => {});
		},
		list() {
			axios
				.get("/system/public/brand/product/list?brandId=" + this.brandId)
				.then(response => {
					this.productList = response.data;
					this.filter();
					this.loaded = true;
				})
				.catch(() => {});
		},
		filter() {
			this.filterList.length = 0;

			if (this.selectedSupplierList.length == 0 && this.selectedCategoryList.length == 0 && this.selectedBrandList.length == 0) {
				for (let product of this.productList) {
					this.filterList.push(product);
				}
			} else {
				let set = new Set();
				for (let product of this.productList) {
					if (this.selectedSupplierList.includes(product.companyId)) {
						if (!set.has(product.id)) {
							set.add(product.id);
							this.filterList.push(product);
						}
					}

					if (product.dataMap.categoryIdSet) {
						for (let categoryId of product.dataMap.categoryIdSet) {
							if (this.selectedCategoryList.includes(categoryId)) {
								if (!set.has(product.id)) {
									set.add(product.id);
									this.filterList.push(product);
								}
							}
						}
					}
				}
			}

			if (this.keyword) {
				this.keyword = this.keyword.toLowerCase();
				let transitList = [];
				for (let product of this.filterList) {
					if (
						(product.name && product.name.toLowerCase().includes(this.keyword)) || //
						(product.code && product.code.toLowerCase().includes(this.keyword)) || //
						(product.description && product.description.toLowerCase().includes(this.keyword)) || //
						(product.summary && product.summary.toLowerCase().includes(this.keyword)) //
					) {
						transitList.push(product);
					}
				}
				this.filterList.length = 0;
				for (let product of transitList) {
					this.filterList.push(product);
				}
			}
			this.pageSet(1);
		},
		filterClear() {
			this.selectedSupplierList = [];
			this.selectedCategoryList = [];
			this.selectedBrandList = [];
			this.filter();
		},
		pagePrevious() {
			this.pageSet(this.pagination.page - 1);
		},
		pageNext() {
			this.pageSet(this.pagination.page + 1);
		},
		pageSet(page) {
			this.pagination.pageLast = Math.ceil(this.filterList.length / this.pagination.pageSize);

			this.pageList.length = 0;
			for (let i = 1; i <= this.pagination.pageLast; i++) {
				this.pageList.push(i);
			}

			if (this.pageList.length == 0 || (page >= 1 && page <= this.pagination.pageLast)) {
				this.pagination.page = page;
				this.paginate();
			}
		},
		paginate() {
			let start = (this.pagination.page - 1) * this.pagination.pageSize;
			let end = start + this.pagination.pageSize;

			if (this.pagination.direction) {
				this.filterList.sort(function(a, b) {
					return a.name.localeCompare(b.name);
				});

				if (this.pagination.direction == "Z-A") {
					this.filterList.reverse();
				}
			}

			this.displayList = this.filterList.slice(start, end);
		},
		navigateToDetail(productId) {
			const path = "/product/detail/" + productId;

			if (this.$route.path !== path) {
				this.$router.push(path);
			}
		},
		addInterest() {
			this.dialogAdd = false;
			this.entityAdd.productId = this.entity.id;
			axios
				.post("/system/interest/add", {
					entity: this.entityAdd
				})
				.then(() => {
					this.dialogNotification = true;
					this.entityAdd = {
						comment: "",
						dataMap: {
							name: "",
							email: "",
							jobRole: "",
							companyName: ""
						}
					};
				})
				.catch(() => {});
		}
	}
};
</script>


