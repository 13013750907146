<template>
	<div>
		<agora-breadcrumb :title="entity.name"></agora-breadcrumb>
		<v-container>
			<template v-if="['TrainingPartner'].includes(entity.type)">
				<h1 class="text-h5 text-sm-h3 my-0 text-uppercase" style="font-family: JosefinSans !important;">{{ entity.type.replace(/([A-Z])/g, " $1").trim() }}</h1>
				<v-row class="my-4">
					<v-col cols="12" md="6" style="margin: -10px -10px 10px -10px;">
						<v-card height="100%" width="100%" class="ml-3" color="transparent" elevation="0" outlined>
							<v-img style="text-align: center;" eager contain :src="$root.asset(entity.imageName, 'medium')"></v-img>
						</v-card>
					</v-col>
					<v-col cols="12" md="6">
						<div class="ml-md-6">
							<div class="text-h5 text-sm-h4 mt-0 mt-md-6 text-capitalize" style="font-family: JosefinSans !important;">{{ entity.name }}</div>
							<div class="text-h6 text-sm-h5 mb-n2" style="font-family: Source Sans Pro !important;">{{ entity.summary }}</div>
							<v-col cols="11" class="ml-md-0 pt-0 mb-4" style="border-bottom: 3px solid black; border-padding: 10px;"></v-col>
							<div v-html="entity.description" style="font-family: Source Sans Pro !important;" ></div>
							<v-btn class="orange-hover mt-6 text-body-1 text-sm-h6" large color="black" outlined style="border-radius: 0px;" :href="entity.dataMap.link" target="_blank">Get Started</v-btn>
						</div>
					</v-col>
				</v-row>
			</template>
			<template v-else>
				<h1 class="text-h5 text-sm-h3 my-0 text-uppercase" style="font-family: JosefinSans !important;">
					<template v-if="entity.type == 'Signage'">Static POS</template>
					<template v-else-if="entity.type == 'MenuSolution'">Menu System</template>
					<template v-else-if="entity.type == 'Event'">Event Details</template>
					<template v-else-if="entity.type == 'Tasting'">Tasting Note</template>
					<template v-else-if="entity.type">{{ entity.type.replace(/([A-Z])/g, " $1").trim() }}</template>
				</h1>
				<br />
				<v-row>
					<v-col cols="12" md="6" style="margin: -10px -10px 10px -10px;">
						<media :name="entity.imageName" />
					</v-col>
					<v-col cols="12" md="6">
						<div class="text-h5 text-sm-h4 mt-0 mt-md-6 mb-n2" style="font-family: JosefinSans !important;">{{ entity.name }}</div>
						<div class="text-h6 text-sm-h5 mb-n2" style="font-family: Source Sans Pro !important;" v-if="entity.summary">{{ entity.summary }}</div>
						<v-col cols="11" class="ml-md-0 pt-0 mb-4" style="border-bottom: 3px solid black; border-padding: 10px;"></v-col>
						<div class="text-body-1 text-sm-h5" style="font-family: Source Sans Pro !important;" v-if="entity.begin || entity.end">{{ entity.dataMap.beginText }} - {{ entity.dataMap.endText }}</div>
						<div class="text-body-1 text-sm-h6" style="font-family: Source Sans Pro !important;" v-if="entity.type == 'Event'">{{ entity.location }}</div>

						<template v-if="entity.type != 'Event'">
							<v-chip background-color="#F2F2F2" text-color="#B1B1B1" class="text-body-2" style="font-family: Source Sans Pro !important;" v-for="item in entity.brandList" :key="item.id">{{ item.name }}</v-chip>
							<br />
							<v-chip background-color="#F2F2F2" text-color="#B1B1B1" class="text-body-2 my-3" style="font-family: Source Sans Pro !important;" v-for="item in entity.categoryList" :key="item.id">{{ item.name }}</v-chip>
							<div class="text-body-1 text-sm-h6" style="font-family: Source Sans Pro !important;">Supplier: {{ entity.dataMap.companyName }}</div>
							<div class="text-body-1 text-sm-h6" style="font-family: Source Sans Pro !important;">ALM Product Code: {{ entity.code }}</div>
						</template>

						<div v-html="entity.description" class="text-body-1 text-sm-h6" style="font-family: Source Sans Pro !important;" ></div>

						<div style="margin-top: 15px">
							
							<v-btn
								v-if="entity.name && entity.productType != 'News' && entity.type != 'Event' && entity.dataMap.isDownload"
								class="orange-hover text-body-1 text-sm-h6"
								outlined
								background-color="transparent"
								style="font-size:20px; padding: 20px 30px; margin:0 10px 10px 0; letter-spacing: 1px; color:black; border-radius: 0; border: 1px solid black;"
								@click="$root.downloadAsset(entity.imageName)"
							>Download</v-btn>

							<v-btn
								v-if="entity.code != null && entity.code != '' && entity.type != 'Event' && entity.type == 'NewRelease'"
								class="orange-hover text-body-1 text-sm-h6"
								@click="addToCart()"
								outlined
								background-color="transparent"
								style="letter-spacing: 1px; color:black; padding: 20px 30px; margin:0 10px 10px 0; font-family:D-DINCondensed-Bold; border-radius: 0; border: 1px solid black;"
							>
								<span>Add to cart</span>
							</v-btn>

							<v-btn
								v-if="entity.name && entity.productType != 'News' && entity.type != 'Event' && entity.dataMap.isInterest"
								class="orange-hover text-body-1 text-sm-h6"
								outlined
								background-color="transparent"
								style="font-size:20px; padding: 20px 30px; margin:0 10px 10px 0; letter-spacing: 1px; color:black; border-radius: 0; border: 1px solid black;"
								@click="dialogAdd = true"
							>Expression of interest</v-btn>

							<v-btn
								v-if="entity.name && entity.productType != 'News' && entity.type != 'Event' && !isFavourite"
								class="text-body-1 text-sm-h6"
								background-color="transparent"
								v-ripple="false"
								plain
								style=" letter-spacing: 1px; padding: 20px 30px; margin:0 10px 10px 0"
								@click="addFavourite()"
							>
								<v-icon left dark color="#28317E" size="25">mdi-heart-outline</v-icon>
							</v-btn>

							<v-btn
								v-if="entity.name && entity.productType != 'News' && entity.type != 'Event' && isFavourite"
								class="text-body-1 text-sm-h6"
								v-ripple="false"
								plain
								background-color="transparent"
								style="letter-spacing: 1px; padding: 20px 30px; margin:0 10px 10px 0"
								@click="removeFavourite()"
							>
								<v-icon right dark color="#F16013" size="25">mdi-heart</v-icon>
							</v-btn>

							<v-btn v-if="entity.type == 'Event'" 
								@click="dialogAdd = true" 
								class="orange-hover text-body-1 text-sm-h6"
								outlined
								background-color="transparent"
								style="font-size:20px; padding: 20px 30px; margin:0 10px 10px 0; letter-spacing: 1px; color:black; border-radius: 0; border: 1px solid black;"
							>Request</v-btn>
							
							<v-btn v-if="entity.type == 'Event'" 
								@click="dialogAddToCalendar = true" 
								class="orange-hover text-body-1 text-sm-h6"
								outlined
								background-color="transparent"
								style="font-size:20px; padding: 20px 30px; margin:0 10px 10px 0; letter-spacing: 1px; color:black; border-radius: 0; border: 1px solid black;"
							>Add to calendar</v-btn>

							<div style="clear: both"></div>
						</div>
					</v-col>
				</v-row>
			</template>
		</v-container>

		<v-container v-if="entity.imageDataList && entity.imageDataList.length > 0">
			<div v-for="item in entity.imageDataList" :key="item">
				<div class="elevation-10 rounded-lg" style="margin-bottom: 10px; position: relative">
					<media :name="item" />
					<v-btn outlined color="black" class="orange-hover text-body-1 text-sm-h6 px-6 py-2" style="background-color:white; letter-spacing: 1px; position: absolute; right: 15px; bottom: 15px; border-radius: 0; border: 1px solid black;" @click="$root.downloadAsset(item)">Download</v-btn>
				</div>
			</div>
		</v-container>

		<v-dialog v-model="dialogAdd" width="400">
			<v-card color="black" elevation="0" style="padding-bottom: 20px">
				<v-card-title>
					<v-spacer></v-spacer>
					<v-btn color="white" icon @click="dialogAdd = false">
						<v-icon>mdi-close-thick</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text style="font-size:14px; font-family:'D-DIN'; text-align: center; color:#6f7175 !important; margin-top: -25px">
					<h4 style="font-size:18px; color:white; margin-bottom: 5px">Register Your Request</h4>
					<p>By registering a request, a notification will be sent to the supplier</p>
					<v-form ref="form" @submit.prevent="addInterest">
						<v-text-field v-model="entityAdd.dataMap.name" label="Name" dense solo background-color="white" style="border-radius: 0; border: 1px solid black;" light></v-text-field>
						<v-text-field v-model="entityAdd.dataMap.email" label="Email" dense solo background-color="white" light style="border-radius: 0; border: 1px solid black; margin-top: -20px"></v-text-field>
						<v-select v-model="entityAdd.dataMap.jobRole" label="Job Role" :items="jobRoleList" item-text="text" item-value="value" dense solo background-color="white" light style="border-radius: 0; border: 1px solid black; margin-top: -20px"></v-select>
						<v-text-field v-model="entityAdd.dataMap.companyName" label="Company Name" dense solo rounded background-color="white" light style="border-radius: 0; border: 1px solid black; margin-top: -20px"></v-text-field>
						<v-textarea v-model="entityAdd.comment" label="Comment" dense solo rounded background-color="white" light style="border-radius: 0; border: 1px solid black; margin-top: -20px"></v-textarea>
						<p style="color:#6f7175; text-align: left; margin-top: -20px">i.e. Best way to contact, colour and size options</p>
						<v-btn type="submit" color="#6f7175" class="orange-hover" width="100%" style="border-radius: 0; border: 1px solid black; color:white; font-family:'D-DINCondensed'; font-size:20px">REQUEST</v-btn>
					</v-form>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogNotification" width="400">
			<v-card color="black" elevation="0" style="padding-bottom: 20px">
				<v-card-title class="justify-center">
					<h4 style="font-size:18px; color:white">{{ notificationContent }}</h4>
				</v-card-title>
				<v-card-text style="font-size:14px; font-family:'D-DIN'; text-align: center; color:#6f7175 !important"></v-card-text>
				<v-card-actions>
					<v-btn rounded color="#6f7175" width="100%" style="color:white; font-family:'D-DINCondensed'; font-size:20px" @click="dialogNotification = false">OK</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogAddToCalendar" v-if="dialogAddToCalendar" width="400">
			<v-card dark color="black" elevation="0" style="padding-bottom: 20px">
				<v-card-title>
					Add Event to calendar
					<v-spacer></v-spacer>
					<v-icon @click="dialogAddToCalendar = false">mdi-close</v-icon>
				</v-card-title>
				<v-card-text>
					<v-btn
						class="mb-2"
						block
						tile
						target="_blank"
						:href="
							'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=' +
							makeTime(entity.begin).replace(/[-:]|\.\d{3}/g, '') + '%2F' + makeTime(entity.end).replace(/[-:]|\.\d{3}/g, '') +
							'&location=' +
							entity.location +
							'&text=' +
							entity.name +
							'&details=' +
							entity.description
						"
					>
						<v-icon left>mdi-google</v-icon>Google
						<v-spacer></v-spacer>
						<v-icon left>mdi-chevron-right</v-icon>
					</v-btn>
					<v-btn
						class="mb-2"
						block
						tile
						target="_blank"
						:href="( ($vuetify.breakpoint.mdAndUp ? 'https://outlook.live.com/owa/?rru=addevent&startdt=' : 'https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&startdt=') +
							makeTime(entity.begin) +
							'&enddt=' + 
							makeTime(entity.end) +
							'&location=' +
							entity.location +
							'&subject=' +
							entity.name +
							'&body=' +
							entity.description).replaceAll(' ', '%E2%80%89').replaceAll(undefined, '%E2%80%89')"
					>
						<v-icon left>mdi-microsoft-outlook</v-icon>Outlook
						<v-spacer></v-spacer>
						<v-icon left>mdi-chevron-right</v-icon>
					</v-btn>
					<v-btn
						class="mb-2"
						block
						tile
						target="_blank"
						:href="
							($vuetify.breakpoint.mdAndUp ? 'https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addevent&startdt=' : 'https://outlook.office.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&startdt=') +
							makeTime(entity.begin) +
							'&enddt=' + 
							makeTime(entity.end) +
							'&location=' +
							entity.location +
							'&subject=' +
							entity.name +
							'&body=' +
							entity.description
						"
					>
						<v-icon left>mdi-microsoft-office</v-icon>Office 365
						<v-spacer></v-spacer>
						<v-icon left>mdi-chevron-right</v-icon>
					</v-btn>
					<v-btn @click="$ics.download(entity.name)" class="mb-2" block tile download>
						<v-icon left>mdi-apple</v-icon>Apple
						<v-spacer></v-spacer>
						<v-icon left>mdi-chevron-right</v-icon>
					</v-btn>
					<v-btn
						class="mb-2"
						block
						tile
						target="_blank"
						:href="
							'https://calendar.yahoo.com/?v=60' +
							'&TITLE=' +
							entity.name +
							'&ST=' +
							makeTime(entity.begin).replace(/[-:]|\.\d{3}/g, '') +
							'&ET=' + makeTime(entity.end).replace(/[-:]|\.\d{3}/g, '') +
							'&DESC=' +
							entity.description +
							'&in_loc=' +
							entity.location
						"
					>
						<v-icon left>mdi-yahoo</v-icon>Yahoo
						<v-spacer></v-spacer>
						<v-icon left>mdi-chevron-right</v-icon>
					</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogAddFavourite" width="400" max-height="320">
			<v-overlay class="px-12 px-md-0">
				<v-card color="white" style="border-radius: 20px">
					<v-card-title class="pb-0">
						<v-spacer></v-spacer>
						<v-icon @click="dialogAddFavourite = false" color="agora_primary">mdi-close</v-icon>
					</v-card-title>

					<v-card-text>
						<v-row style>
							<v-col cols="12" md="6">
								<v-card tile height="100%" width="100%" color="transparent" elevation="0">
									<v-img class="rounded-lg" :height="heightImageFavourite" contain :src="$root.asset(entity.imageName, 'medium')"></v-img>
								</v-card>
							</v-col>
							<v-col cols="12" md="6" class="mt-0 mt-md-6">
								<span class="text-h6 text-sm-h5 text-md-h4 font-weight-bold" style="font-family:D-DINCondensed-Bold; color: black; letter-spacing: 0px;">ADDED TO FAVOURITES</span>
								
								<div class="text-body-1 text-sm-h6 text-md-h5 mt-2" style="color:#6f7175; letter-spacing: 1.7px; line-height: normal;">
									<span>{{ entity.name }}</span>
									<br />
									<span>{{ entity.summary }}</span>
								</div>
								<!-- <span style="font-size:19px; color:black; letter-spacing: 0px" v-html="entity.description"></span> -->
								
								<v-btn to="/favourite" width="150" height="38" class="orange-hover mt-6 mt-md-8"  small outlined color="black" :block="$vuetify.breakpoint.smAndDown" style="text-transform: none; font-size:16px; border-radius: 0px; border: 1px solid black;">View Favourites</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-overlay>
		</v-dialog>
	</div>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
import media from "./media.vue";
import agoraBreadcrumb from "./agoraBreadcrumb";

export default {
	props: ["id", "type"],
	components: {
		media,
		agoraBreadcrumb
	},
	data() {
		return {
			baseLocation: axios.defaults.baseURL,
			entity: {
				begin: {
					date: {},
					time: {}
				},
				end: {
					date: {},
					time: {}
				},
				type: "",
				variantDataList: [],
				imageDataList: [],
				brandList: [],
				categoryList: [],
				dataMap: {}
			},
			dialogAdd: false,
			dialogNotification: false,
			entityAdd: {
				comment: "",
				dataMap: {
					name: "",
					email: "",
					jobRole: "",
					companyName: ""
				}
			},
			brandList: [],
			categoryList: [],
			jobRoleList: [],
			dialogAddFavourite: false,
			brandName: null,
			person: {},
			isFavourite: false,
			favouriteList: [],
			entityCart: {},
			notificationContent: "",
			dialogAddToCalendar: false,
			company: {}
		};
	},
	computed: {
		heightImageFavourite() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '400'
			} else if (width >= 700) {
				return '300'
			} else {
				return '200'
			}
		}
	},
	mounted: function() {
		this.$ics.removeAllEvents();
		this.find();
		this.listJobRole();
		this.metricViewAdd();
	},
	methods: {
		find() {
			if (this.$store.getters.isAuthenticated) {
				this.findMePerson();
				this.findMeCompany();
			}
			axios
				.get("/system/public/product?id=" + this.id)
				.then(response => {
					// if (response.data.type === "NewRelease" && !this.$store.getters.isAuthenticated) {
					// 	this.$router.push("/signin/new releases");
					// }
					this.entity = response.data;
					this.entity.productType = //
						this.entity.type == "NewRelease"
							? "New Release" //
							: this.entity.type == "Event"
							? "Event" //
							: this.type.toUpperCase();
					if (this.entity.type == "Event") {
						this.$ics.addEvent(
							"en-us", // Language in format en-us (default)
							this.entity.name, // Subject/Title of event
							this.entity.description, // Description of event
							this.entity.location, // Location of event
							this.makeTime(this.entity.begin), // Beginning date of event
							this.makeTime(this.entity.end), // Ending date of event
							null, // URL (optional)
							null, // Organizer
							null // Reccurence rule (optional)
						);
					}

					if (this.type == "product") {
						this.getBrandName();
					}
					this.listFavourite();
				})
				.catch(() => {});
		},
		getBrandName() {
			axios.get("/system/public/find/brand/name?productId=" + this.id).then(response => {
				this.brandName = response.data;
			});
		},
		findMePerson() {
			axios
				.get("/system/person/me")
				.then(response => {
					let person = response.data;
					this.entityAdd.dataMap.name = person.name;
					this.entityAdd.dataMap.email = person.email;
					this.entityAdd.dataMap.jobRole = person.jobRole;
				})
				.catch(() => {});
		},
		findMeCompany() {
			axios
				.get("/system/company/profile")
				.then(response => {
					this.company = response.data;
					this.entityAdd.dataMap.companyName = this.company.name;
				})
				.catch(() => {});
		},
		listJobRole() {
			axios
				.get("/system/enumeration/jobrole/list")
				.then(response => {
					this.jobRoleList = response.data;
				})
				.catch(() => {});
		},
		addInterest() {
			this.dialogAdd = false;
			this.entityAdd.productId = this.id;
			axios
				.post("/system/interest/add", {
					entity: this.entityAdd
				})
				.then(() => {
					this.dialogNotification = true;
					this.notificationContent = "Request has been sent";
					this.entityAdd = {
						comment: "",
						dataMap: {
							name: "",
							email: "",
							jobRole: "",
							companyName: ""
						}
					};
				})
				.catch(() => {});
		},
		addFavourite() {
			if (!this.$store.getters.isAuthenticated) {
				this.$router.push("/signin/product details");
			} else {
				axios
					.post("/system/favourite/add", {
						productId: this.id
					})
					.then(() => {
						this.dialogAddFavourite = true;
						this.isFavourite = true;
						this.listFavourite();
					});
			}
		},
		removeFavourite() {
			axios
				.post("/system/favourite/remove", {
					productId: this.id
				})
				.then(() => {
					this.listFavourite();
					this.isFavourite = false;
				});
		},
		listFavourite() {
			if (this.$store.getters.isAuthenticated) {
				axios.get("/system/favourite/list").then(response => {
					this.favouriteList = response.data;
					if (this.favouriteList && this.favouriteList.length) {
						let favourite = this.favouriteList.filter(fav => fav.productId == this.id);
						if (favourite.length > 0) {
							this.isFavourite = true;
						}
					}
				});
			}
		},
		addToCart() {
			if (!this.$store.getters.isAuthenticated && this.entity.type == 'NewRelease') {
				this.$router.push("/signin/product details");
			} 
			else if (this.entity.code != null && this.entity.code != "") {
				this.entityCart.productId = this.entity.id;
				axios
					.post("/system/cart/add", {
						entity: this.entityCart
					})
					.then(response => {
						this.$store.commit("getTotalCart");
						this.dialogNotification = true;
						this.notificationContent = "Item added";
						if (response.data["productId"]) {
							this.sendAddToCartAnalytic();
						}
					});
			}
		},
		metricViewAdd() {
			if (this.type) {
				fetch('https://api.ipify.org?format=json')
				.then(response => response.json())
				.then(response => {
					if (this.$store.getters.isAuthenticated) {
						axios.get("/system/metric/view/add?productId=" + this.id + "&ip=" + response.ip);
					} else {
						axios.get("/system/public/metric/view/add?productId=" + this.id + "&ip=" + response.ip);
					}
				});
			}
		},
		makeTime(obj) {
			let result = "";
			if (obj && obj.date.year) {
				result = obj.date.year + "-" + this.lpad(obj.date.month) + "-" + this.lpad(obj.date.day) + "T" + this.lpad(obj.time.hour) + ":" + this.lpad(obj.time.minute) + ":" + this.lpad(obj.time.second);
			}
			return result;
		},
		lpad(obj) {
			if (obj < 10) {
				obj = "0" + obj;
			}
			return obj;
		},
		sendAddToCartAnalytic() {
			// variant code & varian name kalau banyak blm ke handle
			try {
				let userId = this.$root.isBlank(this.$store.state.principal["id"]) == false ? this.$store.state.principal["id"] : "";
				let userName = this.$root.isBlank(this.$store.state.principal["name"]) == false ? this.$store.state.principal["name"] : "";
				let email = this.$root.isBlank(this.$store.state.principal["email"]) == false ? this.$store.state.principal["email"] : "";
				let accountId = this.$root.isBlank(this.company["accountId"]) == false ? this.company["accountId"] : "";
				let companyId = this.$root.isBlank(this.$store.state.principal['companyId']) == false ? this.$store.state.principal['companyId'] : "";
				let companyName = this.$root.isBlank(this.$store.state.principal["companyName"]) == false ? this.$store.state.principal["companyName"] : "";
				let companyType = this.$root.isBlank(this.$store.state.principal["companyType"]) == false ? this.$store.state.principal["companyType"] : "";

				let productId = this.$root.isBlank(this.id) == false ? this.id : "";
				let productName = this.$root.isBlank(this.entity.name) == false ? this.entity.name : "";
				let productType = this.$root.isBlank(this.entity.productType) == false ? this.entity.productType : "";
				let variantCode = "";
				let variantName =  "";

				let userInformation = 
					userId + "|" +
					userName + "|" +
					email + "|" +
					accountId + "|" +
					companyId + "|" +
					companyName + "|" +
					companyType
				
				let productInformation = 
					productId + "|" +
					productName + "|" +
					productType + "|" +
					variantCode + "|" +
					variantName

				if (this.$root.isBlank(window.location) == false) {
					this.$gtag.event('click_add_to_cart', {
						event_category: 'cart',
						action: 'click',
						event_label: window.location,
						dimension4: userInformation,
						dimension3: productInformation
					});
				}
			} catch(error) {
				console.log(error);
			}
		}
	}
};
</script>