<template>
	<v-card :to="link" width="100%" flat>
		<!-- MOBILE -->
		<div class="hidden-md-and-up wrap" @mouseenter="showDescription" @mouseleave="hideDescription">
			<img :src="image" class="image" :height="description.length > 100 ? 280 : 240">
			<div style="position: absolute; left: 5px; width: 80%; height: auto;" :style="{top: '0', }">
				<div class="text-h6 text-sm-h6 mt-8 mb-2 mx-4 pb-2" style="color: white; border-bottom: 3px solid white" v-html="title"></div>
				<div class="text-caption text-sm-body-2 text-md-h6 ml-5" style="font-weight: normal; color: white; letter-spacing: 0px; line-spacing: 33">{{ description }}</div>
				<br />
				<v-btn @click="$router.push(link)" plain color="white" class="orange-hover text-body-2 text-sm-body1 text-md-body-1 text-capitalize mx-4" style="border: 1px solid white; border-radius:0px!important;" >
					Find out more
				</v-btn>
			</div>
		</div>
		<!-- DESKTOP -->
		<div class="hidden-sm-and-down wrap" @mouseenter="showDescription" @mouseleave="hideDescription">
			<v-img v-bind:src="image" class="image" height="370"></v-img>
			<div style="position: absolute; left: 35px; width: 85%; height: 100%;" :style="{top: isHovered ? (description.length > 100 ? '14%': '35%'):'45%', }">
				<div class="text-h3 mt-8 mb-2 mx-4 pb-2" style="color: white; border-bottom: 3px solid white" v-html="title"></div>
				<div v-if="isHovered" class="text-caption text-sm-body-1 text-md-h6 ml-4 image-description" style="font-family: Source Sans Pro !important; font-weight: normal; color: white; letter-spacing: 0px; line-spacing: 33">{{ description }}</div>
				<div class="pt-4"></div>
				<v-btn @click="$router.push(link)" plain color="white" class="orange-hover text-body-2 text-sm-body1 text-md-body-1 text-capitalize mx-4" style="font-family: Source Sans Pro !important; border: 1px solid white; border-radius:0px!important;" >
					Find out more
				</v-btn>
			</div>
		</div>
	</v-card>
</template>

<script>
export default {
	props: ["image", "title", "description", "link", "type"],
	data() {
		return {
			isHovered: false
		};
	},
	computed: {
		tileHeightMobile () {
			var width = this.$vuetify.breakpoint.width
			if (width > 700) {
				return '275'
			} else {
				return '135'
			}
		},
	},
	methods: {
		showDescription() {
			this.isHovered = true;
		},
		hideDescription() {
			this.isHovered = false;
		}
	},
};
</script>

<style scoped>
.wrap{
	overflow: hidden !important;
}

.image{
	max-height: 100%; 
	max-width: 100%;
	background-size: contain, cover; 
	will-change: transform; 
	image-rendering: -webkit-optimize-contrast; 
	position: relative;
	transition: all .3s;
}

.wrap:hover .image, .tile.hover .image {
    transform: scale(1.5);
}

.image::before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(194, 85, 26, 0.599);;
}

.wrap:hover .image:before,
.wrap:focus .image:before {
  display: block;
}

.image-description {
	display: none;
}

.wrap:hover div.image-description {
  display: block;
}
</style>