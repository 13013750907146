<template>
	<div>
		<agora-breadcrumb></agora-breadcrumb>

		<v-container>
			<h1 class="text-h5 text-sm-h3 my-0" style="font-family: JosefinSans !important;">NEWS AND INDUSTRY TRENDS</h1>
			<br />
			<div class="text-body-1 text-sm-h6 mb-4" style="font-family: Source Sans Pro !important;">Stay up to day with the latest news and information within the liquor industry.</div>
			<br />
		</v-container>

		<v-container>
			<v-row v-if="entity && entity.id" @click="$router.push('/news/detail/' + entity.id)" style="cursor: pointer" no-gutters>
				<v-col cols="6" sm="8">
					<v-img :src="$root.asset(entity.imageName, '')" style="border-radius: 0; height: auto; max-height: 500px;"></v-img>
				</v-col>
				<v-col cols="6" sm="4">
					<v-card height="100%" color="#F2F2F2" flat style="border-radius: 0;" class="d-flex align-center justify-start pl-md-4">
						<v-card-text>
							<div class="text-body-1 text-md-h4" style="font-family: JosefinSans !important; border-bottom: 2px solid black;">{{ entity.name }}</div>
							<div class="mt-1 mt-md-5 mb-1 mb-md-2 text-caption text-md-h6" style="font-family: Source Sans Pro !important;" v-html="$root.ellipsify(entity.description, 100)"></div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row no-gutters>
				<v-col cols="6" sm="4" v-for="entity in displayList" :key="entity.id" @click="$router.push('/news/detail/' + entity.id)" style="cursor: pointer">
					<v-img :src="$root.asset(selectThumbnailImage(entity), 'large')" style="border-radius: 0px;" eager :height="heightImage"></v-img>
					<v-card :height="($vuetify.breakpoint.mdAndUp ? 125:100)" color="#F2F2F2" flat style="border-radius: 0;" class="d-flex align-center" >
						<v-card-text>
							<div class="text-body-1 text-md-h6" style="font-family: JosefinSans !important; border-bottom: 2px solid black;">{{ $root.ellipsify(entity.name, ($vuetify.breakpoint.mdAndUp ? 45:18)) }}</div>
							<div class="mt-1 text-caption text-md-body-1" style="font-family: Source Sans Pro !important;" v-html="$root.ellipsify(entity.description, ($vuetify.breakpoint.mdAndUp ? 60:18))"></div>
						</v-card-text>
					</v-card>
					<!-- <h3 class="ml-1">{{ entity.name }}</h3>
					<div class="ml-1">{{ $root.ellipsify(entity.description, 100) }}</div> -->
				</v-col>
			</v-row>

			<div class="my-4" v-if="loaded && displayList.length == 0">Empty list</div>
		</v-container>

		<v-container v-if="pageList.length > 0">
			<v-row>
				<v-col>
					<v-pagination v-model="pagination.page" :length="pageList.length" :total-visible="$vuetify.breakpoint.smAndUp ? 7:5" class="plain" color="agora_secondary" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right" :previous="pagePrevious" :next="pageNext" @input="pageSet(pagination.page)"></v-pagination>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style scoped>
</style>

<script>
import axios from "axios";
import agoraBreadcrumb from "./agoraBreadcrumb.vue";
export default {
	components: {
		agoraBreadcrumb
	},
	data: function() {
		return {
			entity: {},
			loaded: false,
			keyword: "",
			filterList: [],
			displayList: [],
			pagination: { page: 1, pageSize: 9, pageLast: 1, direction: null },
			pageList: [],
			baseLocation: axios.defaults.baseURL,
			venueImage:{imageNamesMap:{heroImage:""}}
		};
	},
	computed: {
		heightImage() {
			var width = this.$vuetify.breakpoint.width
			if (width > 960) {
				return '300'
			} else if (width > 700) {
				return '250'
			} else {
				return '150'
			}
		}
	},
	mounted: function() {
		this.findFeature();
		this.list();
		this.findVenueImage();
	},
	methods: {
		findFeature() {
			axios
				.get("/system/public/news/feature")
				.then(response => {
					this.entity = response.data;
				})
				.catch(() => {});
		},
		list() {
			axios
				.get("/system/public/product/list?type=News")
				.then(response => {
					this.filterList = response.data;
					this.pageSet(1);
					this.loaded = true;
				})
				.catch(() => {});
		},
		pagePrevious() {
			this.pageSet(this.pagination.page - 1);
		},
		pageNext() {
			this.pageSet(this.pagination.page + 1);
		},
		pageSet(page) {
			this.pagination.pageLast = Math.ceil(this.filterList.length / this.pagination.pageSize);

			this.pageList.length = 0;
			for (let i = 1; i <= this.pagination.pageLast; i++) {
				this.pageList.push(i);
			}

			if (this.pageList.length == 0 || (page >= 1 && page <= this.pagination.pageLast)) {
				this.pagination.page = page;
				this.paginate();
			}
		},
		paginate() {
			let start = (this.pagination.page - 1) * this.pagination.pageSize;
			let end = start + this.pagination.pageSize;

			if (this.pagination.direction) {
				this.filterList.sort(function(a, b) {
					return a.name.localeCompare(b.name);
				});

				if (this.pagination.direction == "Z-A") {
					this.filterList.reverse();
				}
			}
			this.displayList = this.filterList.slice(start, end);
		},
		findVenueImage() {
			let vm = this;
			axios.get("/system/public/venueImage?page=News")
				.then((response) => {
					vm.$root.logSuccess(response);
					vm.venueImage = response.data;
				})
				.catch((error) => {
					vm.$root.logError(error);
					vm.$root.showNotification(vm.notification, error.response);
				});
		},
		selectThumbnailImage(item) {
			if (this.$root.isBlank(item.thumbnailName)) {
				return item.imageName
			} else {
				return item.thumbnailName
			}
		}
	}
};
</script>


