<template>
	<div style="background-color:#f2f3f3">
		<agora-header title="FAQ" :image="baseLocation + '/resource/asset/contact/header.png'"></agora-header>
		<v-container>
			<span class="agora-text-1 text-h6 text-sm-h4 font-weight-black" style="color:#f47a20">Top Questions</span>
			<v-expansion-panels class="mb-4">
				<v-expansion-panel v-for="(item, i) in topQuestionList" :key="i">
					<v-expansion-panel-header class="text-body-2 text-sm-body-1 font-weight-black">{{ item.question }}</v-expansion-panel-header>
					<v-expansion-panel-content>
						<span class="text-body-2 text-sm-body-1" v-html="item.answer"></span>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<span class="agora-text-1 text-h6 text-sm-h4 font-weight-black" style="color:#f47a20">Manage your account</span>
			<v-expansion-panels class="mb-4">
				<v-expansion-panel v-for="(item, i) in manageAccountList" :key="i">
					<v-expansion-panel-header class="text-body-2 text-sm-body-1 font-weight-black">{{ item.question }}</v-expansion-panel-header>
					<v-expansion-panel-content>
						<span class="text-body-2 text-sm-body-1" v-html="item.answer"></span>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<span class="agora-text-1 text-h6 text-sm-h4 font-weight-black" style="color:#f47a20">Account access and safety</span>
			<v-expansion-panels class="mb-4">
				<v-expansion-panel v-for="(item, i) in accountAccessList" :key="i">
					<v-expansion-panel-header class="text-body-2 text-sm-body-1 font-weight-black">{{ item.question }}</v-expansion-panel-header>
					<v-expansion-panel-content>
						<span class="text-body-2 text-sm-body-1" v-html="item.answer"></span>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<span class="agora-text-1 text-h6 text-sm-h4 font-weight-black" style="color:#f47a20">Fix a problem</span>
			<v-expansion-panels class="mb-4">
				<v-expansion-panel v-for="(item, i) in fixProblemList" :key="i">
					<v-expansion-panel-header class="text-body-2 text-sm-body-1 font-weight-black">{{ item.question }}</v-expansion-panel-header>
					<v-expansion-panel-content>
						<span class="text-body-2 text-sm-body-1" v-html="item.answer"></span>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-container>
	</div>
</template>

<script>
import axios from "axios";
import agoraHeader from "./agoraHeader";

export default {
	components: {
		agoraHeader
	},
	data() {
		return {
			baseLocation: axios.defaults.baseURL,
			topQuestionList: [
				{
					question: "Can I use images, logos etc on my website/social media etc? Copyright?",
					answer: "Yes, you can! The content on this site is for you to use to build your business. Should you require more content than what is available, reach out to a supplier via an expression of interest."
				},
				{
					question: "How can I add a staff member?",
					answer: "Your staff member now has access to the site without the need to login. Only managers or those that do any ordering through the ALM portal should have member’s access."
				},
				{
					question: "How do I download an image?",
					answer: "Any piece of content within Agora that can be downloaded, you should see the “download” button. This then downloads automatically into your downloads folder in your computer. It’s as easy as that."
				},
				{
					question: "How can I add an event to my calendar?",
					answer:
						"You can add an event to your calendar by clicking “add to calendar” - but this does not mean it is an RSVP to an event. Please ensure you reach out to the supplier who will confirm your attendance at the event, should it be required."
				},
				{
					question: "A supplier hasn’t responded to my request, what do I do now?",
					answer: "Hmmm, that’s annoying. Please reach out to your BDM or <a href='mailto:almonpremise@almliquor.com.au'>almonpremise@almliquor.com.au</a> if they have not responded to you and we will try to sort it out."
				}
			],
			manageAccountList: [
				{
					question: "How do I log in and out of Agora?",
					answer:
						"You will be asked to log in in certain areas such as “members offers”, “suppliers contacts” and also when sending through an expression of interest to a supplier. Once you are signed in, click “remember me” and you shouldn’t have to sign in again. <br /><br />You can otherwise click “sign in” in the top right corner of the web browser to log in and out."
				},
				{
					question: "How do I change my email?",
					answer: "Your first login in with prompt you to go to “profile” to update your details such as your email address. You can also do this at any time by clicking “Profile” in the top right of the page in the black banner."
				},
				{
					question: "How do I close or deactivate my account?",
					answer: "Reach out to us at <a href='mailto:almonpremise@almliquor.com.au'>almonpremise@almliquor.com.au</a> to deactivate your account or contact your ALM BDM and they can assist."
				},
				{
					question: "How do I reactivate my account?",
					answer: "You can contact your ALM BDM for support here or contact <a href='mailto:almonpremise@almliquor.com.au'>almonpremise@almliquor.com.au</a> and we can assist."
				},
				{
					question: "How do I edit my profile?",
					answer: "You can edit your profile in the “Profile” section in the top right of the page. Only ALM On-Premise customers will have access to the “Profile” section."
				}
			],
			accountAccessList: [
				{
					question: "How do I reset my password?",
					answer:
						"Ensuring your “Profile” section is up to date with your correct email address, if you ever have forgotten your password, you can reset your password on the sign in page. This will then send a forgotten password email to your inbox for you to reset."
				},
				{
					question: "How come I can’t log into Agora?",
					answer:
						"All ALM On Premise customers have access to the members section of Agora. Your first contact should be with your ALM BDM to discuss your login options. If you do not know who your BDM contact is, reach out to <a href='mailto:almonpremise@almliquor.com.au'>almonpremise@almliquor.com.au</a> and we will troubleshoot this with you."
				},
				{
					question: "How do I prevent certain users not gaining access back to web portal or certain sections within Agora?",
					answer: "This can be done by not sharing the password and username details with anyone. Like you would your ALM customer ordering portal. Keep it safe, keep it secure!"
				}
			],
			fixProblemList: [
				{
					question: "Missing links or images",
					answer: "If there is a missing link or image does not download, you can either contact the supplier of the assets or please send an e-mail to <a href='mailto:assist@alm.pontoonx.io'>assist@alm.pontoonx.io</a>"
				},
				{
					question: "Troubleshoot problems playing videos.",
					answer:
						"If the video link is broken you can either contact the supplier of the assets or please send an e-mail to <a href='mailto:assist@alm.pontoonx.io'>assist@alm.pontoonx.io</a>. If you download the assets, you'll need to ensure the screen you are trying to view the video's on has the capability to view the files types."
				},
				{
					question: "Someone else is signed in with my email.",
					answer: "Please change your password immediately and ensure it is secure."
				},
				{
					question: "I can’t find a product or supplier?",
					answer:
						"That’s ok – they might not be on here. There are currently 50 suppliers with over 250 brands on Agora. Our plan is to continue building the suppliers we have on here and even though your brand might not be here, a similar product just might."
				}
			]
		};
	}
};
</script>